import React from 'react';
import Button from "src/components/Shared/Buttons/Button";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";

const SettingContent = ({ onClick = () => {}, }) => {
  return (
    <>
      <div className="py-5 space-y-5">
        <div className="flex items-center justify-between gap-4">
          <div className="w-full">
            <div className="text-xl font-semibold">Privacy & Security 
            <span className="ml-3 h-5 text-sm font-medium px-1 text-highlightColor border border-highlightColor rounded">Beta</span>
            </div>
            <div className="text-gray-500">Set the user Deactivation and Deletion</div>
          </div>
          <div className="flex justify-end w-28 min-w-[112px]">
            <Button
              className=""
              onClick={() => {onClick()}}
            >Save Change</Button>
          </div>
        </div>
        <div className="relative border border-gray-200 rounded-xl px-4 bg-white">
          <div className="flex items-center gap-5 py-4">
            <div className="w-10 min-w-[40px]">
              <Toggle checked={true} />
            </div>
            <div className="w-full font-semibold text-gray-800">Automatic User Deactivation <span className="text-sm text-gray-400 ml-2 font-normal">(Exclusive admins)</span></div>
          </div>
          <div className="border-t border-gray-200 py-4">
            <div className="text-gray-500 flex items-center gap-2">
              <span className="font-semibold text-sm text-gray-700 uppercase">Deactive</span> user after
              <span className="w-20">
                <Input
                name={"days"}
                type={"number"}
                placeholder={""}
                value={"10"}
                onChange={() => {}}
                />
              </span>
              days without login.
            </div>
          </div>
        </div>
        <div className="relative border border-gray-200 rounded-xl px-4 bg-white">
          <div className="flex items-center gap-5 py-4">
            <div className="w-10 min-w-[40px]">
              <Toggle checked={false} />
            </div>
            <div className="w-full font-semibold text-gray-800">Automatic User Deletion <span className="text-sm text-gray-400 ml-2 font-normal">(Exclusive admins)</span></div>
          </div>
          <div className="border-t border-gray-200 py-4">
            <div className="text-gray-500 flex items-center gap-2">
              Permanently <span className="font-semibold text-sm text-gray-700 uppercase">Deactive</span> user after
              <span className="w-20">
                <Input
                name={"days"}
                type={"number"}
                placeholder={""}
                value={"60"}
                onChange={() => {}}
                />
              </span>
              days without login.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingContent;