import { useEffect, useState } from "react";
import { noProfile } from "src/config/host";
import { classNames } from "src/helpers/classNames";
import { formatName } from "src/helpers/formatName";
import { getS3ImageDetails } from "src/helpers/image";

export default function UserProfile({ user, className = "", size = "md" }) {
  const [s3Image, setS3Image] = useState({
    loading: false,
    data: null,
  });

  const [invalidImage, setInvalidImage] = useState(false)

  useEffect(() => {
    if (user?.image && !user.image.includes("data:image/")) {
      const loadS3Image = async () => {
        setS3Image((data) => ({ ...data, loading: true }));
        const res = await getS3ImageDetails(user.image);
        if (res.status === 200) {
          setS3Image({ loading: false, data: res.data });
        } else {
          setS3Image((data) => ({ ...data, loading: false }));
        }
      };

      loadS3Image();
    }
  }, [user?.image]);


  return (
    <>
      {invalidImage || (!s3Image.loading && !s3Image.data) ? (
        <div className={`flex justify-center items-center w-full h-full rounded-full text-gray-900 text-${size} p-3 uppercase bg-gray-200 ` + className}>{formatName(user?.name, user?.email)}</div>
      ) : (
        <div className={"relative h-full w-full " + className}>
          <img
            className={classNames("h-full w-full object-cover", user?.image && s3Image.data ? "opacity-100 transition-opacity duration-150" : "opacity-40")}
            src={s3Image.loading ? noProfile : s3Image.data}
            alt="no-profile"
            onError={() => setInvalidImage(true)}
          />
        </div>
      )}
    </>
  );
}
