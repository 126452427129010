import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { classNames } from "src/helpers/classNames";

const NavItem = ({ item, activeItem = "", onClick = () => {}, site }) => {
  const [isActiveItem, setIsActiveItem] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (item.key === activeItem) {
      setIsActiveItem(true);
    } else if (window.location.pathname.includes(item.path)) {
      setIsActiveItem(true);
    } else {
      setIsActiveItem(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname, activeItem]);

  return (
    <div
     className="flex items-center"
      key={item.name}
      onClick={() => {
        onClick(item);
      }}>
      {item?.isExternal ? (
        <a href={item.path} target={item?.page_link_newtab ? "_blank" : "_self"} rel="noreferrer" className="w-full">
        <div
          className={classNames(
            "group flex items-center rounded-md px-2 py-2 font-medium transition-all duration-300",
            // isActiveItem ? "bg-leftNavActiveColor text-leftNavActiveTextColor text-opacity-80" : "text-leftNavTextColor hover:bg-leftNavActiveColor hover:bg-opacity-80 hover:text-leftNavActiveTextColor"
            item?.path === location?.pathname ? "bg-leftNavActiveColor text-leftNavActiveTextColor text-opacity-80" : "text-leftNavTextColor hover:bg-leftNavActiveColor hover:bg-opacity-80 hover:text-leftNavActiveTextColor"
          )}>
          {item.icon && (
            <div
              className="nav-icon mr-4 h-6 w-6 flex-shrink-0 hover:bg-leftNavActiveColor"
              dangerouslySetInnerHTML={{
                __html: `<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
            ${item?.icon}
          </svg>`,
              }}
            />
          )}
          {item.name}
        </div>
      </a>
      ) : (
        <Link to={item.path} className="w-full">
          <div
            className={classNames(
              "group flex items-center rounded-md px-2 py-2 font-medium text-leftNavTextColor transition-all duration-300 hover:text-leftNavActiveTextColor",
              isActiveItem ? "bg-leftNavActiveColor text-leftNavActiveTextColor text-opacity-80" : "text-leftNavTextColor hover:bg-leftNavActiveColor hover:bg-opacity-80 hover:text-leftNavActiveTextColor"
            )}>
            {item.icon && (
              <div
                className="nav-icon mr-4 h-6 w-6 flex-shrink-0 hover:bg-leftNavActiveColor"
                dangerouslySetInnerHTML={{
                  __html: `<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
              ${item?.icon}
            </svg>`,
                }}
              />
            )}
            {item.name}
          </div>
        </Link>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, null)(NavItem);
