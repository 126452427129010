import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { PlusIcon } from "@heroicons/react/24/solid";
import { useCallback, useContext, useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { getPreviousPageDetails, managePageData, removePage, setPageData, updateDomoPage } from "src/actions/page";
import { apiRequest } from "src/async/apiUtils";
import { PageListContext, PageListGlobalContext } from "src/context/PageListContext";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import useFetch from "src/hooks/useFetch";
import LandingPage from "src/pages/portal/landing-page";
import { validate as uuidValidate } from "uuid";
import AddPageDropdown from "src/components/Shared/Buttons/AddPageDropdown";
import Button from "src/components/Shared/Buttons/Button";
import Checkbox from "src/components/Shared/Forms/Checkbox";
import Input from "src/components/Shared/Forms/Inputs/Input";
import TextArea from "src/components/Shared/Forms/Inputs/TextArea";
import MultiSelectObject from "src/components/Shared/Forms/Selects/MultiSelectObject";
import NoData from "src/components/Shared/LoadingAnimations/NoData";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import Modal from "src/components/Shared/Modal";
import PaginationFooter from "src/components/Shared/PaginationFooter";
import Search from "src/components/Shared/Search";
import TRHeader from "src/components/Shared/Table/TRHeader";
import Table from "src/components/Shared/Table/Table";
import TableOptions from "src/components/Shared/TableOptions";
import UserProfile from "src/components/Users/UserProfile";
import WorkspaceImportUserModal from "src/components/Workspaces/Workspace/WorkspaceImportUserModal";
import PageEntry from "./PageEntry";
import PageListItem from "./PageListItem";
import CreateDomoPageContent from "./PageModelContent/CreateDomoPageContent";
import CreateLandingPageContent from "./PageModelContent/LandingPages/CreateLandingPageContent";

const PageContainer = ({ isGlobal = false, workspaceId = "", ...props }) => {
  const [createModal, setCreateModal] = useState(false);
  const [prevModal, setPrevModal] = useState(false);
  const [userAssignModal, setUserAssignModal] = useState(false);
  const [groupAssignModal, setGroupAssignModal] = useState(false);
  const [advancedFeatures, setAdvancedFeatures] = useState(false);
  const [successLoaderStart, setSuccessLoaderStart] = useState(false);
  const [startIndex, setStartIndex] = useState(-1);
  const [deleteId, setDeleteId] = useState(null);
  const [selectedWorkspace, setSelectedWorkspace] = useState([]);
  const [isDeleteDisable, serIsDeleteDisable] = useState(false);

  const [srcDoc, setSrcDoc] = useState("");
  const [noPage, setNoPage] = useState(true);
  const [loadingPage, setLoadingPage] = useState(true);
  const [navigationBlocks, setNavigationBlocks] = useState([]);

  const [groupImportUserModalOpen, setGroupImportUserModalOpen] = useState(false);
  const [sendWelcomeEmail, setSendWelcomeEmail] = useState(true);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const jsonArr = {
    _id: "",
    name: "",
    page_type: "DOMO",
    embed_type: "EMBED_PAGE",
    ignore_global_filters: false,
    credential_id: null,
    embed_id: "",
    custom_html: "",
    page_link: "",
    page_link_newtab: false,
    workspace_id: workspaceId || null,
    is_global: isGlobal,
    navigation_blocks: [
      {
        title: "",
        image: "",
        height: "",
        width: "",
        links: [
          {
            link_text: "",
            page_id: null,
          },
        ],
      },
    ],
  };

  const [newPage, setNewPage] = useState(jsonArr);

  const [pageCredentialOptions, setPageCredentialOptions] = useState([]);
  const [navigationPageList, setNavigationPageList] = useState([]);
  const [editPageCredentialOptions, setEditPageCredentialOptions] = useState([]);

  // Users and groups
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [allPages, setAllPages] = useState([]);

  const [domoPageId, setDomoPageId] = useState([]);
  const [domoUserIds, setDomoUserIds] = useState([]);
  const [domoGroupIds, setDomoGroupIds] = useState([]);
  const [domoPageIdForRefresh, setDomoPageIdForRefresh] = useState([]);
  const [pageSelectedId, setPageSelectedId] = useState([]);

  const {
    keyword = "",
    setKeyword = () => { },
    meta = null,
    // Pagination
    limit = 10,
    setLimit = () => { },
    offset = 0,
    setOffset = () => { },
    //
    pagesLoaded = false,
    pageType = null,
    pages = [],
    resetPagination = () => { },
    refreshPages = () => { },
  } = useContext(!isGlobal ? PageListContext : PageListGlobalContext);

  const handlePaginationChange = ({ limit, offset }) => {
    setLimit(limit);
    setOffset(offset);
  };

  const handleSelectedWorkspaces = (workspacesArray) => {
    let updatedSelectedWorkspaces = workspacesArray.reduce((workspaces, workspace) => (workspace.selected ? [...workspaces, workspace.key] : workspaces), []);
    setSelectedWorkspace(updatedSelectedWorkspaces);
  };

  const {
    response: { data: workspaces },
    status: { done: workspacesLoaded },
  } = useFetch("/workspaces/list", { method: "post" });

  const loadUserGroupData = useCallback(async () => {
    const res = await apiRequest("post", `/workspaces/${props?.workspaceDetails?._id}/user-groups`, {
      body: {
        authToken: props?.workspaceDetails?.auth_token,
        workspace_id: props?.workspaceDetails?._id,
      },
    });

    if (res.data.status === 200) {
      setUsers(res.data.data.users || []);
      setGroups(res.data.data.groups || []);
    }
  }, [props?.workspaceDetails]);

  const allCredentials = useCallback(async () => {
    const res = await apiRequest("get", `/credentials/all`);

    if (res.data.status === 200) {
      setEditPageCredentialOptions(res.data.data || []);
    }
  }, []);

  const workspaceCredentials = useCallback(async () => {
    const res = await apiRequest("get", `/workspaces/${workspaceId}/credentials`);

    if (res.data.status === 200) {
      setEditPageCredentialOptions(res.data.data || []);
    }
  }, [workspaceId]);

  const fetchAllPages = useCallback(async () => {
    const res = await apiRequest("post", `/pages/list`, {
      body: { isGlobal, workspace_id: workspaceId },
    });

    if (res.data.status === 200) {
      setAllPages(res.data.data || []);
    }
  }, [isGlobal, workspaceId]);

  useEffect(() => {
    if (!workspaceId) {
      fetchAllPages();
    } else {
      if (props?.workspaceDetails?.workspace_type === "IFRAME_EMBED") {
        fetchAllPages();
      }
    }
  }, [fetchAllPages, isGlobal, props?.workspaceDetails, workspaceId]);

  useEffect(() => {
    if (!workspaceId || props?.workspaceDetails?.workspace_type === "IFRAME_EMBED") {
      if (props?.user?.type === "admin") {
        allCredentials();
      } else if (workspaceId) {
        workspaceCredentials();
      }
    }
  }, [allCredentials, workspaceCredentials, workspaceId, props?.user, props?.workspaceDetails]);

  useEffect(() => {
    if (props?.workspaceDetails?.workspace_type === "JWT_FULL_EMBED") {
      loadUserGroupData();
    }
  }, [loadUserGroupData, props?.workspaceDetails]);

  const createNewPage = async () => {
    try {
      setSuccessLoaderStart(true);
      let page = {
        is_global: newPage.is_global,
        workspace_id: newPage.workspace_id ? [newPage.workspace_id] : selectedWorkspace,
        credential_id: newPage.credential_id,
        page_type: newPage.page_type,
        custom_html: newPage.custom_html,
        page_link: newPage.page_link,
        page_link_newtab: newPage.page_link_newtab,
        created_at: "",
        created_by: props.user._id,
        deleted_at: null,
        deleted_by: null,
        embed_id: newPage.embed_id,
        embed_type: newPage.embed_type,
        ignore_global_filters: newPage.ignore_global_filters,
        name: newPage.name,
        navigation_blocks: newPage.navigation_blocks,
        updated_at: "",
        updated_by: props.user._id,
        _id: newPage._id || null,
      };

      const { message } = await props.managePageData({
        pages: [page],
      });
      refreshPages();
      setCreateModal(false);
      setAdvancedFeatures(false);
      setTimeout(() => {
        setSuccessLoaderStart(false);
        toast.success(message);
      }, 200);
      setNewPage({
        _id: "",
        name: "",
        page_type: "DOMO",
        embed_type: "EMBED_PAGE",
        ignore_global_filters: false,
        credential_id: null,
        embed_id: "",
        custom_html: "",
        workspace_id: workspaceId || null,
        navigation_blocks: [
          {
            title: "",
            image: "",
            height: "",
            width: "",
            links: [
              {
                link_text: "",
                page_id: null,
              },
            ],
          },
        ],
      });
    } catch (error) {
      setTimeout(() => {
        setSuccessLoaderStart(false);
        toast.error(error.message);
      }, 300)

    }
  };

  const deletePage = async () => {
    if (deleteId && !uuidValidate(deleteId)) {
      try {
        serIsDeleteDisable(true);
        await props.removePage(deleteId);
        setDeleteId(null);
        refreshPages();
      } catch (error) {
        // console.dir(error);
      } finally{
        serIsDeleteDisable(false);
      }
    }
  };

  const editPage = async (page) => {
    if (page.workspace_id.length > 0) {
      let workspace_id = [];
      for (const workspaceid of page.workspace_id) {
        workspace_id.push(workspaceid._id);
      }
      setSelectedWorkspace(workspace_id);
      setNewPage({ ...page, workspace_id: "", is_global: true });
    } else {
      setSelectedWorkspace([]);
      setNewPage({ ...page, workspace_id: page.workspace_id?._id, is_global: false });
    }
    setCreateModal(true);
  };

  const addPage = async (pageType) => {
    setSelectedWorkspace([]);
    setNewPage({ ...jsonArr, page_type: pageType });
    setCreateModal(true);
  };

  useEffect(() => {
    if (newPage && newPage.page_type === "LANDING_PAGE") {
      let navigationPage = [];
      if (newPage.is_global) {
        // eslint-disable-next-line array-callback-return
        allPages.map((allp) => {
          if (allp._id !== newPage._id && allp.workspace_id.length > 0) {
            navigationPage.push({ value: allp._id, key: allp.name });
          }
        });
      } else {
        // eslint-disable-next-line array-callback-return
        allPages.map((allp) => {
          if (allp._id !== newPage._id && allp.workspace_id?._id === newPage.workspace_id) {
            navigationPage.push({ value: allp._id, key: allp.name });
          }
        });
      }
      setNavigationPageList(navigationPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPage?._id, newPage?.page_type, allPages]);

  useEffect(() => {
    if (newPage && newPage.page_type === "LANDING_PAGE") {
      let navigationPage = [];
      if (newPage.is_global) {
        // eslint-disable-next-line array-callback-return
        allPages.map((allp) => {
          if (allp._id !== newPage._id && allp.workspace_id.length > 0) {
            navigationPage.push({ value: allp._id, key: allp.name });
          }
        });
      } else {
        // eslint-disable-next-line array-callback-return
        allPages.map((allp) => {
          if (allp._id !== newPage._id && allp.workspace_id?._id === newPage.workspace_id) {
            navigationPage.push({ value: allp._id, key: allp.name });
          }
        });
      }
      setNavigationPageList(navigationPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPage?._id, newPage?.page_type, allPages]);

  const pagePrev = async (page) => {
    if (page?.page_type === "EXTERNAL_LINK") {
      if (page?.page_link_newtab) {
        window.open(page?.page_link);
      } else {
        window.open(page?.page_link, "_self");
      }
    } else {
      setLoadingPage(true);
      setNoPage(true);
      setSrcDoc("");
      setNavigationBlocks([]);
      setPrevModal(true);
      getPage(page._id);
    }
  };

  const getPage = async (pageId) => {
    let res = await getPreviousPageDetails({ user_id: props?.user?._id, id: pageId });
    setLoadingPage(false);
    if (res.status === 400) {
      setNoPage(true);
      return;
    }
    setNoPage(false);
    if (res.page_type === "CUSTOM_HTML") {
      setSrcDoc(res.custom_html);
      setNavigationBlocks([]);
    } else if (res.page_type === "LANDING_PAGE") {
      setNavigationBlocks(res.navigation_blocks);
      setSrcDoc("");
    } else {
      let { embedToken, embedUrl } = res;

      let htmlIframe = `<iframe id="iframe" title="page-embed" src="" width="100%" marginHeight="0" marginWidth="0" frameBorder="0" srcDoc='<html>
      <body>
        <form id="form" action="${embedUrl}" method="post">
          <input type="hidden" name="embedToken" value="${embedToken}" />
        </form>
      </body>
      <script>document.getElementById("form").submit();</script>
    </html>'></iframe>`;

      setSrcDoc(htmlIframe);
      setNavigationBlocks([]);
    }
  };

  useEffect(() => {
    let creds = [];
    // eslint-disable-next-line array-callback-return
    editPageCredentialOptions?.map((cred) => {
      let insert = false;
      if (cred.workspace_id && newPage?.workspace_id === cred.workspace_id) {
        insert = true;
      }
      if (props.user?.type === "admin" && cred.is_global) {
        insert = true;
      }
      if (insert === true && creds.findIndex((cr) => cr.value === cred._id) === -1) {
        creds.push({ value: cred._id, key: cred.name });
      }
    });
    setPageCredentialOptions(creds);
  }, [editPageCredentialOptions, newPage?.workspace_id, props.user]);

  useEffect(() => {
    setStartIndex(pageCredentialOptions.findIndex((pageCredentialOption) => pageCredentialOption.value === newPage.credential_id));
  }, [pageCredentialOptions, newPage]);

  useEffect(() => {
    let index = pageCredentialOptions.findIndex((pageCredentialOption) => pageCredentialOption.value === newPage.credential_id);
    if (index === -1) {
      setNewPage({ ...newPage, credential_id: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCredentialOptions]);

  const activeUserAssignModal = async (pageId, userIds = []) => {
    setDomoPageId(pageId);
    let userWithSelected = [];
    for (const user of users) {
      userWithSelected.push({ ...user, checked: userIds.findIndex((usr) => usr === user.id) === -1 ? false : true });
    }
    setDomoUserIds(userWithSelected);
    setUserAssignModal(true);
  };

  const activeGroupAssignModal = async (pageId, groupIds = []) => {
    setDomoPageId(pageId);
    let groupWithSelected = [];
    for (const group of groups) {
      groupWithSelected.push({ ...group, checked: groupIds.findIndex((grp) => grp === group.id) === -1 ? false : true });
    }
    setDomoGroupIds(groupWithSelected);
    setGroupAssignModal(true);
  };

  const handleUserGroupAssign = async (type, importStatus = undefined) => {
    try {
      // setSuccessLoaderStart(true);
      let ids;
      if (type === "user") {
        let filterArr = domoUserIds.filter((dt) => dt.checked);
        ids = filterArr.map((dt) => dt.id);
      } else {
        let filterArr = domoGroupIds.filter((dt) => dt.checked);
        ids = filterArr.map((dt) => dt.id);
      }

      if (typeof importStatus !== "boolean" && ids.length && props.workspaceDetails?.workspace_type === "JWT_FULL_EMBED") {
        if (users.filter((user) => ids.includes(user.id || user._id) && !user.portalUser).length) {
          setUserAssignModal(false);
          setGroupImportUserModalOpen(true);
          return;
        }
      }

      setSuccessLoaderStart(true);

      for (const pageId of domoPageId) {
        await updateDomoPage({
          page_id: pageId,
          workspace_id: workspaceId,
          userIds: type === "user" ? ids : undefined,
          groupIds: type === "group" ? ids : undefined,
          auth_token: props?.workspaceDetails?.auth_token,
          importStatus,
          password,
          confirmPassword,
          sendWelcomeEmail,
        });
      }
      setDomoPageIdForRefresh(domoPageId);
      setSuccessLoaderStart(false);
      setUserAssignModal(false);
      setGroupAssignModal(false);
      setGroupImportUserModalOpen(false);
      loadUserGroupData();
    } catch (error) {
      setSuccessLoaderStart(false);
      toast.error(error.message);
    }
  };

  const pageSelect = async (e, pageId) => {
    if (e.target.checked) {
      setPageSelectedId([...pageSelectedId, pageId]);
    } else {
      setPageSelectedId(pageSelectedId.filter((p) => p !== pageId));
    }
  };

  return (
    <>
      <TableOptions
        leftContent={
          <Search
            keyword={keyword}
            setKeyword={(val) => {
              resetPagination();
              setKeyword(val);
            }}
          />
        }
        rightContent={
          (workspaceId && props.workspaceDetails?._id && props.workspaceDetails?.workspace_type !== "JWT_FULL_EMBED" && authorizeUserComponentAccess(props.user, workspaceId, "page", ["create"])) || (props.user?.type === "admin" && isGlobal) ? (
            <div className="flex items-center justify-between relative z-10">
              <div className="relative flex min-w-[140px] items-center justify-end gap-x-1 ml-auto">
                <AddPageDropdown addPage={addPage} />
              </div>
            </div>
          ) : (
            workspaceId &&
            props.workspaceDetails?._id &&
            props.workspaceDetails?.workspace_type === "JWT_FULL_EMBED" &&
            authorizeUserComponentAccess(props.user, workspaceId, "page", ["update"]) && (
              <div className="flex items-center justify-between relative z-10">
                <div className="relative flex min-w-[140px] items-center justify-end gap-x-1 ml-auto">
                  <Button
                    styleType="primary"
                    className="!px-2 !h-8"
                    disabled={pageSelectedId.length === 0}
                    onClick={() => {
                      activeUserAssignModal(pageSelectedId);
                    }}>
                    <PlusIcon className="w-5 h-5" /> User
                  </Button>
                  <Button
                    styleType="primary"
                    className="!px-2 !h-8"
                    disabled={pageSelectedId.length === 0}
                    onClick={() => {
                      activeGroupAssignModal(pageSelectedId);
                    }}>
                    <PlusIcon className="w-5 h-5" /> Group
                  </Button>
                </div>
              </div>
            )
          )
        }></TableOptions>
      <Table
        tableHeader={
          <TRHeader>
            <th
              scope="col"
              className="rounded-l-xl overflow-hidden py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
              Name
            </th>
            <th
              scope="col"
              className="hidden sm:table-cell overflow-hidden pl-4 pr-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6">
              {pageType === "IFRAME_EMBED" && "Type"}
              {pageType === "JWT_FULL_EMBED" && "Users"}
            </th>
            {pageType && pageType === "JWT_FULL_EMBED" && (
              <th
                scope="col"
                className="hidden sm:table-cell overflow-hidden pl-4 pr-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                Groups
              </th>
            )}
            {pageType && pageType === "IFRAME_EMBED" && (
              <th
                scope="col"
                className="rounded-r-xl overflow-hidden  hidden lg:table-cell pl-4 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6"></th>
            )}
          </TRHeader>
        }
        colSpan="5"
        loaded={pagesLoaded}
        dataExists={pagesLoaded && pages?.length > 0}>
        {pages.map((page, i) => {
          return (
            <PageListItem
              key={`${page._id}-${i}`}
              page={page}
              pages={pages}
              i={i}
              workspaceId={workspaceId}>
              <PageEntry
                workspaceId={page.workspace_id?._id || page.workspace_id || workspaceId}
                key={page._id || page.id}
                page={page}
                setDeleteId={setDeleteId}
                editPage={editPage}
                pagePrev={pagePrev}
                pageType={pageType}
                is_global={isGlobal}
                users={users}
                groups={groups}
                workspaceDetails={props?.workspaceDetails}
                depthLevel={0}
                activeUserAssignModal={activeUserAssignModal}
                activeGroupAssignModal={activeGroupAssignModal}
                domoPageIdForRefresh={domoPageIdForRefresh}
                setDomoPageIdForRefresh={setDomoPageIdForRefresh}
                pageSelect={pageSelect}
              />
            </PageListItem>
          );
        })}
      </Table>
      {pageType === "IFRAME_EMBED" && (
        <PaginationFooter
          itemName="Page"
          limit={limit}
          offset={offset}
          count={meta?.count}
          onChange={handlePaginationChange}
        />
      )}
      {/* { Page Delete} */}
      <Modal
        title="Page"
        secondaryTitle="Delete"
        isOpen={!!deleteId}
        onCancel={() => setDeleteId(null)}
        onSuccess={deletePage}
        isLoading={isDeleteDisable}
        defaultOptions={{
          onSuccessButtonText: "Delete",
        }}>
        <div className="grid gap-y-8 whitespace-nowrap text-sm text-gray-500">Are you sure you want to delete {Array.isArray(pages) && pages?.find((item) => item._id === deleteId)?.name}?</div>
      </Modal>

      {/* { Page Create/Update} */}
      <Modal
        title="Page"
        secondaryTitle="Create"
        isOverflow={false}
        isOpen={createModal}
        onCancel={() => setCreateModal(false)}
        onSuccess={createNewPage}
        defaultOptions={{
          onSuccessLoaderVisible: true,
          onSuccessLoaderStart: successLoaderStart,
        }}
        size={newPage.page_type === "LANDING_PAGE" ? "xl" : "md"}>
        <div className="grid gap-y-8 whitespace-nowrap px-2 text-sm text-gray-500">
          <div className="mb-2 grid gap-y-3 px-4">
            <div className="my-5 grid space-y-2">
              <Input
                type="text"
                name="page-name"
                label="Name"
                value={newPage.name}
                onChange={(e) => setNewPage({ ...newPage, name: e.target.value })}
              />
              {(newPage?.workspace_id?.page?.is_global_template || isGlobal) && (
                <MultiSelectObject
                  defaultOptions={
                    workspacesLoaded &&
                    workspaces
                      .filter((item) => item.workspace_type !== "JWT_FULL_EMBED")
                      .map((wrk) => {
                        return { key: wrk._id, value: wrk.name, selected: selectedWorkspace.includes(wrk._id), object: wrk };
                      })
                  }
                  searchableFields={["name"]}
                  title="Assign workspaces"
                  onChange={handleSelectedWorkspaces}
                />
              )}
            </div>
            <div className="relative">
              {newPage.page_type === "CUSTOM_HTML" && (
                <TextArea
                  label="Custom HTML"
                  value={newPage.custom_html}
                  onChange={(e) => setNewPage({ ...newPage, custom_html: e.target.value })}
                  placeholder="<iframe> ... </iframe>"
                  textType="code"
                />
              )}

              {newPage.page_type === "EXTERNAL_LINK" && (
                <>
                  <Input
                    type="text"
                    name="link-url"
                    label="Link URL"
                    value={newPage.page_link}
                    onChange={(e) => setNewPage({ ...newPage, page_link: e.target.value })}
                  />

                  <div className="mb-4 flex w-full mt-4">
                    <input
                      id="card-embed"
                      checked={newPage.page_link_newtab}
                      onChange={(e) => {
                        setNewPage({
                          ...newPage,
                          page_link_newtab: e.target.checked,
                        });
                      }}
                      aria-describedby="card-embed"
                      name="card-embed"
                      type="checkbox"
                      className="h-5 w-5 rounded border-gray-300 text-highlightColor focus:ring-highlightColor"
                    />
                    <div className="ml-3 max-w-[400px]">
                      <label
                        htmlFor="card-embed"
                        className="text-sm text-gray-500">
                        Open link in new tab
                      </label>
                    </div>
                  </div>
                </>
              )}

              {newPage.page_type === "DOMO" && (
                <CreateDomoPageContent
                  newPage={newPage}
                  setNewPage={setNewPage}
                  startIndex={startIndex}
                  pageCredentialOptions={pageCredentialOptions}
                  advancedFeatures={advancedFeatures}
                  setAdvancedFeatures={setAdvancedFeatures}
                />
              )}

              {newPage.page_type === "LANDING_PAGE" && (
                <CreateLandingPageContent
                  navigationPageList={navigationPageList}
                  newPage={newPage}
                  setNewPage={setNewPage}
                />
              )}
            </div>
          </div>
        </div>
      </Modal>

      {/* { Page Preview} */}
      <Modal
        title="Page"
        secondaryTitle="Preview"
        isOpen={prevModal}
        onCancel={() => setPrevModal(false)}
        size="xxxxxl"
        defaultOptions={{
          onCancelButtonVisible: false,
          onSuccessButtonVisible: false,
        }}>
        <div className="grid gap-y-8 whitespace-nowrap px-2 text-sm text-gray-500">
          <div className="mb-6 grid gap-y-3 px-4">
            <div className="h-full w-full flex min-h-[calc(100vh-144px)]">
              {!loadingPage && !noPage && (srcDoc || navigationBlocks.length > 0) ? (
                srcDoc ? (
                  ReactHtmlParser(srcDoc)
                ) : (
                  <LandingPage
                    from={"previous"}
                    navigationBlocks={navigationBlocks}
                  />
                )
              ) : (
                <div className="w-full h-full flex justify-center mt-20">{loadingPage ? <Preloader /> : !loadingPage && noPage && <NoData />}</div>
              )}
            </div>
          </div>
        </div>
      </Modal>

      {/* User Assign/Unassign to page Modal */}
      <Modal
        title="Users"
        size="sm"
        isOpen={userAssignModal}
        onCancel={() => setUserAssignModal(false)}
        onSuccess={() => handleUserGroupAssign("user")}
        defaultOptions={{
          onCancelButtonVisible: true,
          onSuccessButtonVisible: true,
          onSuccessLoaderVisible: true,
          onSuccessLoaderStart: successLoaderStart,
        }}>
        <div className="w-full divide-y divide-slate-200">
          {domoUserIds.map((user, i) => (
            <div className="flex w-full items-center py-2 space-x-3">
              <Checkbox
                isChecked={user.checked || false}
                onChange={(e) => {
                  setDomoUserIds(
                    domoUserIds.map((usr, index) => {
                      if (i === index) {
                        return { ...usr, checked: e.target.checked };
                      } else {
                        return usr;
                      }
                    })
                  );
                }}
              />
              {/* <UserProfile
                key={i}
                user={user}
                className={"!w-10 !h-10 rounded-full overflow-hidden"}
              /> */}
              <div className="flex relative items-start gap-x-2">
                <div className="flex flex-col gap-x-2">
                  <div>
                    <span className="relative">
                      {user.name}
                      {typeof user?.portalUser === "boolean" && !user?.portalUser && (
                        <div
                          data-tooltip-id={`${user.email}-not_portal_user_search`}
                          className="absolute -right-12 -top-1 flex gap-x-1 text-amber-400 bg-amber-50 rounded-full font-light px-2 py-1">
                          <ExclamationTriangleIcon className="stroke-[2px] h-5 w-5" />
                        </div>
                      )}
                    </span>
                  </div>
                  <div className="text-sm text-gray-300">{user.email}</div>
                </div>

                <ReactTooltip
                  id={`${user.email}-not_portal_user_search`}
                  delayShow={200}
                  positionStrategy="fixed"
                  className="z-[200] opacity-100 bg-gray-700 rounded px-2 py-2">
                  <div className="font-normal">
                    <div>This user was created by a Domo admin, but has not yet been give an account to access the application.</div>
                    <div>If you add this user a welcome email will be issued.</div>
                  </div>
                </ReactTooltip>
              </div>
              {/* <div>
                {user.name} {typeof user?.portalUser === "boolean" && !user?.portalUser && "(Not a Portal User) -portal01"}
              </div> */}
            </div>
          ))}
        </div>
      </Modal>

      <WorkspaceImportUserModal
        isImportModalOpen={groupImportUserModalOpen}
        setIsImportModalOpen={setGroupImportUserModalOpen}
        password={password}
        setPassword={setPassword}
        confirmPassword={confirmPassword}
        setConfirmPassword={setConfirmPassword}
        sendWelcomeEmail={sendWelcomeEmail}
        setSendWelcomeEmail={setSendWelcomeEmail}
        selectedUsers={domoUserIds.filter((dt) => dt.checked).map((item) => item.id)}
        workspaceUsers={users}
        onSuccess={(users, status) => handleUserGroupAssign("user", status)}
        workspaceDetailsData={props?.workspaceDetails}
        hideCrossIcon={false}
        disableStatus={successLoaderStart}
      />

      {/* Group Assign/Unassign to page Modal */}
      <Modal
        title="Groups"
        size={"sm"}
        isOpen={groupAssignModal}
        onCancel={() => setGroupAssignModal(false)}
        onSuccess={() => handleUserGroupAssign("group")}
        defaultOptions={{
          onCancelButtonVisible: true,
          onSuccessButtonVisible: true,
          onSuccessLoaderVisible: true,
          onSuccessLoaderStart: successLoaderStart,
        }}
        defaultStyles={{
          overFlowYVisible: true,
        }}>
        <div className="w-full divide-y divide-slate-200">
          {domoGroupIds.map((group, i) => (
            <div className="flex w-full items-center py-2 space-x-3">
              <Checkbox
                isChecked={group.checked || false}
                onChange={(e) => {
                  setDomoGroupIds(
                    domoGroupIds.map((grp, index) => {
                      if (i === index) {
                        return { ...grp, checked: e.target.checked };
                      } else {
                        return grp;
                      }
                    })
                  );
                }}
              />
              <UserProfile
                key={i}
                user={group}
                className={"!w-10 !h-10 rounded-full overflow-hidden"}
              />
              <div>{group.name}</div>
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, {
  managePageData,
  removePage,
  setPageData,
})(PageContainer);
