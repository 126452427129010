import { Switch } from "@headlessui/react";
import { classNames } from "src/helpers/classNames";

export default function Toggle({ checked, onChange, disabled = false }) {
  return (
    <div className={classNames(disabled ? "cursor-not-allowed" : "")}>
      <Switch.Group
        as="div"
        className="flex items-center">
        <Switch
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          className={classNames(
            disabled ? "cursor-not-allowed" : "cursor-pointer",
            disabled && checked ? "bg-gray-300" : checked ? "bg-highlightColor" : "bg-gray-200",
            "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full transition-all ease-in-out duration-200 focus:outline-none"
          )}>
          <span
            aria-hidden="true"
            className={classNames("translate-x-0", disabled ? "cursor-not-allowed" : "pointer-events-none", checked ? "translate-x-5" : "", "inline-block h-5 w-5 rounded-full bg-white shadow-md transform ring-0 transition-all ease-in-out duration-200")}
          />
        </Switch>
      </Switch.Group>
    </div>
  );
}
