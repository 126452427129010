import { H1 } from "src/components/Shared/Text/Headers";
import UsersList from "src/components/Users/UsersList";

const Admins = () => {
  return (
    <>
      <H1>Admins</H1>
      <UsersList
        title="Admin"
        filterUserType={["admin"]}
      />
    </>
  );
};

export default Admins;
