import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import Button from "src/components/Shared/Buttons/Button";
import StylesPreview from "src/components/Shared/Styles/StylesPreview";
import { noFavicon, noLogo } from "src/config/host";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";

const StylesBuilder = ({ data = {}, layoutType, handleBrowserTabText = null, onSubmit = () => { }, title = "", secondaryTitle = "", headerContent = null, setWorkspace = () => { }, propsButtonOption = {}, setButtonIsDisabled = () => {}, ...props }) => {
  const { id } = useParams();

  const getSiteData = () => {
    return {
      _id: data._id,
      style_key: "workspace_id",
      name: data.name,
      image_logo: data.image_logo || noLogo,
      image_favicon: data.image_favicon || noFavicon,
      top_bar_color: data?.top_bar_color || "#FFFFFF",
      top_bar_text_color: data?.top_bar_text_color || "#FFFFFF",
      side_bar_color: data?.side_bar_color || "#FFFFFF",
      side_bar_text_color: data?.side_bar_text_color || "#FFFFFF",
      side_bar_shade_color: data?.side_bar_shade_color || "#FFFFFF",
      left_nav_active_text_color: data?.left_nav_active_text_color || "#FFFFFF",
      highlight_color: data?.highlight_color || "#FFFFFF",
      background_color: data?.background_color || "#FFFFFF",
      sso_button_color: data.sso_button_color,
      sso_button_text_color: data.sso_button_text_color,
    };
  };

  const [updatedSite, setUpdatedSite] = useState({});
  // const [updatedSite, setUpdatedSite] = useState(getSiteData());

  const handleStylesDataChange = (keyValue = {}) => {
    setButtonIsDisabled(false)
    setUpdatedSite(
      updatedSite => ({ ...updatedSite, ...keyValue })
    );
  };

  useEffect(() => {
    if (data?._id) {
      setUpdatedSite(getSiteData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data._id]);

  const onClear = () => {
    setButtonIsDisabled(true);
    setUpdatedSite(getSiteData());
  };

  return (
    <div className="max-w-7xl grid space-y-8">
      <StylesPreview
        layoutType={layoutType}
        handleBrowserTabText={handleBrowserTabText}
        menu={["Home", "About Us", "Feedback"]}
        styles={updatedSite}
        user={props.user}
        setWorkspace={setWorkspace}
        onChange={handleStylesDataChange}
        workspaceDetails={data}
      />
      <div className="border-t border-gray-200 mt-5 pt-3 w-full flex justify-end gap-x-4">
        <Button
          version="gray"
          disabled={propsButtonOption?.buttonLoader}
          onClick={onClear}>
          Undo changes
        </Button>
        {(!propsButtonOption?.buttonLoader) ? authorizeUserComponentAccess(props.user, id, "layout", ["update"]) && <Button disabled={propsButtonOption?.buttonIsDisabled} onClick={() => onSubmit(updatedSite)}>Update
        </Button> : (
          <Button disabled={propsButtonOption?.buttonLoader}>
            Update<ArrowPathIcon className="h-5 w-5 ml-1 animate-spin" />
          </Button>
        )}
      </div>

    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, {})(StylesBuilder);
