import { apiUrl } from "src/config/host";
import { fetchData } from "src/async/fetch";
import { GET_OPERATOR_DATA } from "src/actions/type";

export const getOperators =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      const res = await fetchData("POST", `${apiUrl}/operators/list`, body, signal);
      const data = await res.json();

      if (data.status === 200) {
        dispatch({ type: GET_OPERATOR_DATA, payload: data.data });
        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };
