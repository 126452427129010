import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import React, { useState } from "react";
import Pagination from "src/components/Shared/MultiColumnLayout/MultiColumnLayoutComponents/Pagination";
import TopNavigationButton from "src/components/Shared/MultiColumnLayout/MultiColumnLayoutComponents/TopNavigationButton";
import { classNames } from "src/helpers/classNames";
import Tabs from "src/components/Shared/Tabs/Tabs";
import Search from "src/components/Shared/Search";
import { ArrowSmallLeftIcon } from "@heroicons/react/24/outline";
import FullScreenButton from "src/components/Shared/MultiColumnLayout/MultiColumnLayoutComponents/FullScreenButton";
import SlideOver from "src/components/Shared/SlideOver";

export default function MultiColumnLayout({ selectedItem = {}, headerSearch = {}, headerFilter = null, headerContent = null, bodyContentHeader = null, tabs = null, mainContent = null, itemEntry = null, aside = null }) {
  const [fullScreen, setFullScreen] = useState(false);

  return (
    <div className="flex h-[calc(100vh-170px)] flex-col gap-y-3">
      <header className="h-16 border-b border-gray-100 pb-4 hidden lg:flex lg:min-w-0 lg:items-center space-x-4 w-full">
        {headerSearch && (
          <div className="w-96">
            {headerSearch.type === "text" && (
              <Search
                keyword={headerSearch.searchText}
                setKeyword={headerSearch.onSearchTextChange}
              />
            )}
          </div>
        )}
        {headerFilter()}
      </header>

      {/* Bottom section */}
      <div className="flex h-full min-h-0 flex-1">
        {/* ITEM LIST*/}
        <div className={classNames("max-h-[2000px] h-full hidden w-72 lg:w-80 xl:w-96 sm:block transition-all duration-300 ease-in-out overflow-hidden", fullScreen ? "sm:max-w-[0px]" : "mr-4")}>
          <div className="relative flex h-full max-h-[calc(100%-4rem)] flex-col rounded-md overflow-hidden after:content-[''] after:absolute after:w-full after:h-6 after:bg-gradient-to-t after:from-white after:bottom-0 after:left-0">
            <nav className="relative min-h-0 flex-1 h-full overflow-y-auto">
              <div className="grid gap-y-2 border-gray-200 w-full max-h-full overflow-y-scroll scroll-smooth pr-2">
                {aside.map((item) => (
                  <React.Fragment key={item._id}>{itemEntry(item)}</React.Fragment>
                ))}
              </div>
            </nav>
          </div>
          <Pagination />
        </div>
        {/* SINGLE ITME */}
        <main className="min-w-0 w-full flex-1 h-full xl:flex">
          <section className="flex min-w-0 flex-1 flex-col pr-1 overflow-auto scroll-smooth">
            <div className="flex min-w-0 flex-col shadow border border-gray-200 rounded-md mb-2">
              <div className="flex h-13 flex-col justify-center border-b border-gray-100">
                <div className="px-4 sm:px-4">
                  <div className="flex justify-between py-3">
                    {/* Left buttons */}
                    <nav
                      aria-label="Pagination"
                      className="flex items-center gap-x-2 sm:gap-x-3">
                      <div className="hidden sm:block p-1">
                        <FullScreenButton onClick={() => setFullScreen(!fullScreen)} />
                      </div>
                      <div className="block sm:hidden p-1">
                        <TopNavigationButton item={{ text: "Back", icon: ArrowSmallLeftIcon, onClick: () => setFullScreen(!fullScreen) }} />
                      </div>

                      <div className="sm:flex gap-x-2 hidden">
                        <TopNavigationButton item={{ text: "Next", icon: ChevronUpIcon, onClick: () => {} }} />
                        <TopNavigationButton item={{ text: "Previous", icon: ChevronDownIcon, onClick: () => {} }} />
                      </div>
                    </nav>
                    {/* Right buttons */}
                    <div>{headerContent()}</div>
                  </div>
                </div>
              </div>

              <div className="relative bg-white rounded-b-md">
                {bodyContentHeader(selectedItem)}
                <Tabs
                  newDesign={true}
                  tabs={tabs}
                />
              </div>
            </div>
          </section>
        </main>
      </div>
      <SlideOver />
    </div>
  );
}
