import { GET_USER_DETAILS, CLEAR_USER_DETAILS, LOG_OUT } from "src/actions/type";

const userDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_USER_DETAILS:
      return { ...action.payload };
    case CLEAR_USER_DETAILS:
      return action.payload;
    case LOG_OUT:
      return {};
    default:
      return state;
  }
};

export default userDetailsReducer;
