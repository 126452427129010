import React from "react";
import { KeyIcon, EnvelopeIcon, DocumentDuplicateIcon, PhotoIcon } from "@heroicons/react/24/outline";
import ImageWithEdit from "src/components/Shared/ImageWithEdit";

const BodyContentHeader = ({ data = null, type }) => {
  return (
    <>
      {type === "user" &&
        <div className="relative main_item-background px-8 pt-8 pb-6">
          <div className="flex items-center space-x-4 mb-4">
            <div className="flex justify-center items-center px-2 rounded border border-amber-500 bg-amber-50 gap-2 text-amber-500 h-8">
              <KeyIcon className="w-5 h-5 stroke-2" /> Admin
            </div>
            {data.status === "Active" ? (
              <div className="flex justify-center items-center px-2 rounded border border-green-500 bg-green-50 gap-2 text-green-500 h-8">{data.status}</div>
            ) : (
              <div className="flex justify-center items-center px-2 rounded border border-red-500 bg-red-50 gap-2 text-red-500 h-8">{data.status}</div>
            )}
          </div>
          <div className="flex items-center space-x-4 mb-5">
            <ImageWithEdit
              image={data.image}
              name={data.name}
              initialsClasses="font-bold"
            />
            <h1 className="text-4xl font-bold text-gray-900">{data.name}</h1>
          </div>
          <div className="flex items-center gap-5">
            <div className="flex items-center gap-2 text-gray-600 font-semibold w-40">
              <EnvelopeIcon className="w-5 h-5 stroke-2" /> Email
            </div>
            <div className="relative w-full flex items-center max-w-md gap-5">
              <input
                type={"email"}
                value={data.email}
                className="border-0 font-semibold w-full !ring-0 bg-gray-200/40 rounded py-0 px-3 pr-10 h-10"
                onChange={() => { }}
              />
              <button
                type="button"
                className="w-8 h-8 absolute top-1 right-1 z-10 bg-white rounded shadow-sm border border-gray-200 text-gray-500 flex items-center justify-center">
                <DocumentDuplicateIcon className="w-5 h-5 stroke-2" />
              </button>
            </div>
            <div className="relative">
              <button
                type="button"
                className="text-gray-500 flex items-center justify-center h-10 py-0 px-2 underline font-semibold">
                Edit
              </button>
            </div>
          </div>
        </div>
      }
      {type === "company" &&
        <div className="relative main_item-background px-8 py-12">
          <div className="relative space-y-4">
            <h1 className="text-4xl font-light text-gray-400">{data.name}</h1>
            <div className="flex items-center">
              <div className="h-10 relative group">
                <div className="w-8 h-8 absolute -top-4 -right-4 rounded-full shadow-sm border border-gray-200 bg-white flex items-center justify-center text-highlightColor transition-all duration-200 opacity-0 group-hover:opacity-100">
                  <input
                    id="photo-edit"
                    type="file"
                    className="absolute top-0 right-0 w-60 h-full opacity-0 z-10 cursor-pointer"
                  />
                  <PhotoIcon className="w-5 h-5 stroke-2" />
                </div>
                <img className="w-full h-full object-contain object-left" src={data.image} alt={data.name} />
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default BodyContentHeader;
