import NoProfile from "src/assets/img/user-default.png";
import NoFavicon from "src/assets/img/image_favicon-default.png";
import ExampleFavi from "src/assets/img/image_favicon-example.png";
import NoLogo from "src/assets/img/image_logo-default.png";
import NoBusiness from "src/assets/img/business-default.png";

/* Digital Ocean */
// export const baseUrl = "https://domo-portal-admin-api-29ypc.ondigitalocean.app";

/* Production */
export const baseUrl = `https://clarity-admin.dynamicsky.com`;

/* Localhost */
// export const baseUrl = `http://localhost:3001`;

export const publicImageStorage = ".s3.us-west-2.amazonaws.com";
export const apiUrl = baseUrl + "/api";
export const filePath = baseUrl + "/uploads";
export const noProfile = NoProfile;
export const noFavicon = NoFavicon;
export const exampleFavi = ExampleFavi;
export const noLogo = NoLogo;
export const noBusiness = NoBusiness;
