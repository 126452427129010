import React from "react";
import { toast } from "react-toastify";
import { convertToBase64 } from "src/helpers/image";

const InputDropBox = ({ label, name, setImage }) => {
  const updateImage = (e) => {
    const allowedExts = ["jpg", "jpeg", "png", "bmp", "gif", "svg", "webp", "avif"];

    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (!allowedExts.includes(ext)) {
        toast.error("Please upload a valid image.");
      } else {
        convertToBase64(e.target.files[0], setImage);
      }
    }
  };
  return (
    <>
      <div className="relative w-full">
        <label
          htmlFor="title"
          className="flex justify-between text-sm font-medium text-gray-700">
          {label}
        </label>
        <div className="relative w-full overflow-hidden flex flex-col items-center justify-center h-40 border-2 border-dashed border-gray-500 bg-gray-100 rounded-md space-y-4">
          <input
            type="file"
            onChange={updateImage}
            className={"absolute top-0 left-0 w-full h-full z-10 opacity-0 cursor-pointer"}
            name={name}
          />
          <div className="text-sm font-medium">Drop Image Here</div>
          <div className="text-sm font-medium rounded border border-gray-700 bg-white px-5 py-2">Browse</div>
        </div>
      </div>
    </>
  );
};

export default InputDropBox;
