import { useEffect, useState } from "react";
import { connect } from "react-redux";
// import { toast } from "react-toastify";
import { manageSiteSettings } from "src/actions/site";
import { apiRequest } from "src/async/apiUtils";
import StylesBuilder from "src/components/Shared/Styles/StylesBuilder";
import EditOnHoverInput from "src/components/Shared/Forms/Inputs/EditOnHoverInput";
import { H3 } from "src/components/Shared/Text/Headers";
import Section from "src/components/Shared/Containers/Section";
import { toast } from "react-toastify";

const SiteThemeAndStyles = ({ ...props }) => {
  const [siteName, setSiteName] = useState(props?.site.site_name || "");
  const [buttonLoader, setButtonLoader] = useState(false);
  const [buttonIsDisabled, setButtonIsDisabled] = useState(true);

  const handleStylesUpdate = async (body) => {
    setButtonLoader(true);
    if (body.image_favicon !== props?.site.image_favicon) {
      await apiRequest("DELETE", `/site-settings/images/image_favicon`);
    }
    if (body.image_logo !== props?.site.image_logo) {
      await apiRequest("DELETE", `/site-settings/images/image_logo`);
    }

    try {
      // await apiRequest("PUT", `/site-settings/styles`, { body: { ...body, site_name: siteName } });
      const message = await props.manageSiteSettings({ ...body, site_name: siteName });
      setTimeout(() => {
        toast.success(message);
        setButtonLoader(false);
      }, 300);
    } catch (error) {
      setTimeout(() => {
        toast.error(error.message);
        setButtonLoader(false);
      }, 300);
    } finally {
      setButtonIsDisabled(true)
    }
  };

  useEffect(() => {
    setSiteName(props?.site.site_name)
  }, [props?.site.site_name])

  return (
    <Section>
      <div className="w-full">
        <div className="mb-6 flex w-full items-center justify-between">
          <H3 caption="White-label the customer experience with customize colors and logos.">Choose your colors</H3>
        </div>
        <StylesBuilder
          data={{ ...props?.site, name: props?.site.site_name }}
          propsButtonOption={
            {
              buttonLoader: buttonLoader,
              buttonIsDisabled: buttonIsDisabled
            }
          }
          setButtonIsDisabled={setButtonIsDisabled}
          onSubmit={handleStylesUpdate}
          handleBrowserTabText={
            <EditOnHoverInput
              className="max-w-[120px] text-gray-700 font-medium"
              value={siteName}
              onChange={(e) => {
                setSiteName(e.target.value)
                setButtonIsDisabled(false)
              }}
            />
          }
          title="Styles & Text"
          secondaryTitle="Select the perfect set of colors and styles along with a logo and favicon."
        />
      </div>
    </Section>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, { manageSiteSettings })(SiteThemeAndStyles);
