import { fetchData } from "src/async/fetch";
import { apiUrl } from "src/config/host";

export const dispatcher =
  (payload, signal = null, url = "", method = "POST", dispatchType = "", onSuccess = null) =>
  async (dispatch) => {
    try {
      let queryString = "";
      if (payload.params) {
        const searchParams = new URLSearchParams(payload.params);
        queryString = "?" + searchParams.toString();
      }
      const res = await fetchData(method, `${apiUrl}/${url}${queryString}`, payload.body, signal);
      const data = await res.json();

      if (data.status === 200) {
        if (dispatchType) {
          dispatch({ type: dispatchType, payload: data.data });
        }
        if (onSuccess) {
          return onSuccess(data);
        }
        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };
