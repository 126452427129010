import { WindowIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { getAuthorizedUser } from "src/actions/auth";
import { getOperators } from "src/actions/operator";
import { getPages } from "src/actions/page";
import { editWorkspaceGroupDetails, manageGroupUser } from "src/actions/workspace";
import { apiRequest } from "src/async/apiUtils";
import { fetchData } from "src/async/fetch";
import DisplayPageForm from "src/components/PageForm/DisplayPageForm";
import Button from "src/components/Shared/Buttons/Button";
import IconsWithPlus from "src/components/Shared/Icons/IconsWithPlus";
import NoData from "src/components/Shared/LoadingAnimations/NoData";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import { apiUrl } from "src/config/host";
import { classNames } from "src/helpers/classNames";

const WorkspaceIndividualPermission = ({ getAuthorizedUser, viewOnly = false, userId = "", workspaceId = "", ...props }) => {
  const [loaded, setLoaded] = useState(false);
  const [permissionState, setPermissionState] = useState([]);
  const [defaultPermissionState, setDefaultPermissionState] = useState([]);
  const [pageList, setPageList] = useState([]);
  const [permissionChanges, setPermissionChanges] = useState(0);
  const permissionJson = {
    _id: null,
    page_id: null,
    filters: [],
  };

  const trustedAttributeArr = [
    {
      _id: "user.fullName",
      value: "user.fullName",
      name: "Full Name",
    },
    {
      _id: "user.email",
      value: "user.email",
      name: "Email Address",
    },
  ];

  const valueType = [
    {
      _id: "value",
      value: "value",
      name: "Text Value",
    },
    {
      _id: "attribute",
      value: "attribute",
      name: "User Attribute",
    },
  ];

  const loadPermissionData = async (ac = {}) => {
    try {
      let workspaceGroupsData = {};

      try {
        let workspaceGroupsResponse = await fetchData("GET", `${apiUrl}/workspaces/${workspaceId}/users/${userId}/permissions`);
        workspaceGroupsData = await workspaceGroupsResponse.json();

        if (workspaceGroupsData.status === 200) {
          setDefaultPermissionState(workspaceGroupsData.data.length > 0 ? workspaceGroupsData.data : [])
          setPermissionState(workspaceGroupsData.data.length > 0 ? workspaceGroupsData.data : []);
          setLoaded(true);
          return workspaceGroupsData.data;
        } else {
          setLoaded(true);
          throw new Error(workspaceGroupsData.message);
        }
      } catch (error) {
        setLoaded(true);
        throw new Error(error.message);
      }
    } catch (error) {
      setLoaded(true);
      // console.dir(error.message);
    }
  };

  useEffect(() => {
    const ac = new AbortController();
    const loadPageAndOperatorData = async () => {
      props
        .getPages({ workspace_id: workspaceId }, ac.signal)
        .then((data) => {
          data = data.filter((page) => page.page_type !== "LANDING_PAGE");
          setPageList(data);
          props
            .getOperators({}, ac.signal)
            .then((oprtrs) => {
              loadPermissionData();
            })
            .catch((error) => console.dir(error));
        })
        .catch((error) => console.dir(error));
    };
    if (workspaceId) {
      loadPageAndOperatorData();
    }
    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId, props.me]);

  const addNewPermission = (e) => {
    e.preventDefault();
    setPermissionState([...permissionState, permissionJson]);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(permissionState, result.source.index, result.destination.index);
    setPermissionState([...items]);
    setPermissionChanges(permissionChanges + 1);
  };

  const onClear = () => {
    setPermissionState(defaultPermissionState);
    setPermissionChanges(0);
  };

  const savePermissionChanges = async () => {
    await apiRequest(
      "put",
      `${apiUrl}/workspaces/${workspaceId}/users/${userId}/permissions`,
      {
        body: {
          permissions: permissionState,
        },
      },
      { showToastMessage: true }
    );
    setPermissionChanges(0);
  };

  return (
    <>
      {loaded ? (
        <div className="relative grid gap-y-4">
          <div className="flex justify-end gap-2">
            <Button
                version="secondary"
                onClick={addNewPermission}>
                <IconsWithPlus
                  strokeColor={"stroke-highlightColor"}
                  item={{ icon: WindowIcon }}
                />
              </Button>
            </div>
          {permissionState?.length > 0 ? (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div className="space-y-4"
                    {...provided.droppableProps}
                    ref={provided.innerRef}>
                    {permissionState.map((permission, index) => {
                      return (
                        <Draggable
                          key={index}
                          draggableId={`${index}`}
                          index={index}>
                          {(provided, snapshot) => (
                            <DisplayPageForm
                              key={`${workspaceId}_${index}`}
                              id={`${workspaceId}_${index}`}
                              permission={permission}
                              setPermission={(e) => {
                                setPermissionChanges(permissionChanges + 1);
                                setPermissionState(
                                  permissionState.map((p, i) => {
                                    if (i === index) {
                                      return e;
                                    } else {
                                      return p;
                                    }
                                  }),
                                );
                              }}
                              pageOptions={pageList}
                              operatorOptions={props.operators}
                              removePermission={(e) => {
                                setPermissionChanges(permissionChanges + 1);
                                setPermissionState(permissionState.filter((p, i) => i !== index));
                              }}
                              trustedAttributeArr={trustedAttributeArr}
                              valueType={valueType}
                              innerRef={provided.innerRef}
                              draggableProps={provided.draggableProps}
                              dragHandleProps={provided.dragHandleProps}
                            />
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <div className="h-[60px]">
              <NoData />
            </div>
          )}

          <div className="w-full flex justify-end gap-x-4 mt-20 sm:mt-4">
            <Button
              styleType="gray"
              onClick={onClear}
              disabled={permissionChanges < 1}>
              Undo changes
            </Button>
            <Button
              onClick={savePermissionChanges}
              disabled={permissionChanges < 1}>
              Update
            </Button>
          </div>

        </div>
      ) : (
        <div className={classNames("h-20 transition-all duration-300")}>
          <Preloader
            className="h-[80px]"
            circleDimension="10"
            size="lg"
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    pages: Object.values(state.pages),
    operators: Object.values(state.operators),
    me: state.auth.user,
  };
};
export default connect(mapStateToProps, {
  editWorkspaceGroupDetails,
  getAuthorizedUser,
  getPages,
  manageGroupUser,
  getOperators,
})(WorkspaceIndividualPermission);
