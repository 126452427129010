import React from 'react';
import Input from "src/components/Shared/Forms/Inputs/Input";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";

const SettingsContent = () => {
  return (
    <>
      <div className="px-4 py-5 pb-4 relative">
        <div className="relative border border-gray-200 bg-white py-4 px-6 rounded-lg divide-y divide-gray-200">
          <div className="flex gap-5 py-5">
            <div className="w-40 min-w-[160px]">
              <div className="text-gray-600 font-semibold text-base">Password</div>
            </div>
            <div className="w-full max-w-md">
              <div className="mb-4">
                <Input
                label={"Input New Password"}
                name={"password"}
                type={"password"}
                placeholder={"Input New Password"}
                />
              </div>
              <div className="mb-4">
                <Input
                label={"Confirm New Password"}
                name={"password"}
                type={"password"}
                placeholder={"Confirm New Password"}
                />
              </div>
            </div>
          </div>
          <div className="flex gap-5 py-5">
            <div className="w-40 min-w-[160px]">
              <div className="text-gray-600 font-semibold text-base">Role</div>
            </div>
            <div className="w-full max-w-md">
              <div className="mb-4">
                <div className="flex items-center gap-4">
                  <div className="w-10 min-w-[40px]">
                    <Toggle checked={true}/>
                  </div>
                  <div className="w-full">
                    <div className="font-semibold text-gray-800 text-base">Assign as Admin</div>
                    <div className="text-[12px] font-medium text-gray-500">Active if you want this user get Admin role</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingsContent;