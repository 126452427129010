import { CogIcon, ShareIcon, TableCellsIcon } from "@heroicons/react/24/outline";
import Tabs from "src/components/Shared/Tabs/Tabs";
import SSOServiceAttribute from "src/components/SSO/SSOServiceAttribute";
import SSOServiceMetadata from "src/components/SSO/SSOServiceMetadata";
import SSOServiceProvider from "src/components/SSO/SSOServiceProvider";
import BackIndicator from "src/components/Navigation/BackIndicator";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getSso } from "src/actions/sso";
import { connect } from "react-redux";
import { H1 } from "src/components/Shared/Text/Headers";

const Add = ({ ...props }) => {
  const { id } = useParams();
  const [providerName, setProviderName] = useState("");

  useEffect(() => {
    const ac = new AbortController();
    const loadSSO = async () => {
      try {
        const data = await props.getSso({ id }, ac.signal);
        setProviderName(data.provider_name);
      } catch (error) {
        // console.dir(error.message);
      }
    };
    if (id) {
      loadSSO();
    }

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabs = [
    { name: "Service Provider Setup", icon: CogIcon, component: <SSOServiceProvider /> },
    { name: "Service Provide Metadata", icon: ShareIcon, component: <SSOServiceMetadata /> },
    { name: "Attribute/Role Mapping", icon: TableCellsIcon, component: <SSOServiceAttribute /> },
  ];

  return (
    <>
      <H1 item={{ icon: BackIndicator }}>
        <p className="text-gray-300 font-light capitalize">SSO /</p>
        {providerName}
      </H1>
      <Tabs tabs={tabs} />
    </>
  );
};

const mapStateToProps = () => {
  return {};
};
export default connect(mapStateToProps, { getSso })(Add);
