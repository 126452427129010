import React from "react";
import { KeyIcon } from "@heroicons/react/24/outline";
import UserProfile from "src/components/Users/UserProfile";

const UserItems = ({ data }) => {
  return (
    <>
      <div className="group overflow-hidden hover:text-highlightColor border rounded-md relative bg-white focus-within:ring-2 focus-within:ring-inset focus-within:ring-highlightColor hover:bg-gray-100 transition-all duration-200">
        <button className="relative flex w-full focus:outline-none gap-x-2 py-4 px-3">
          <div className="h-10 w-10">
            <UserProfile
              user={data}
              className="!bg-highlightColor !text-white"
            />
          </div>
          <div className="max-w-[240px] overflow-hidden items-start">
            <div className="w-full text-left truncate text-base font-semibold text-gray-900">{data.name}</div>
            <div className="w-full text-left truncate text-sm text-gray-500">{data.email}</div>
          </div>
          <div className="absolute top-2 right-1 flex gap-x-2">
            {data.admin && (
              <div className="w-6 h-6 flex items-center justify-center text-sm bg-amber-100 font-semibold rounded group-hover:bg-white transition-all duration-200">
                <KeyIcon className="text-amber-500 w-4 h-4 stroke-2" />
              </div>
            )}
            <time
              dateTime={data.datetime}
              className="whitespace-nowrap text-sm text-gray-400 font-semibold bg-gray-100 px-2 h-6 flex items-center justify-center rounded group-hover:bg-white transition-all duration-200">
              {data.date}
            </time>
          </div>
        </button>
      </div>
    </>
  );
};

export default UserItems;
