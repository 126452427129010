import { apiRequest } from "src/async/apiUtils";
import { GET_ACTIVITY_DATA, DELETE_ACTIVITY_DATA } from "src/actions/type";

export const getActivity =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      const data = await apiRequest("post", "/list-activity", { body });
      if (data.status === 200) {
        dispatch({ type: GET_ACTIVITY_DATA, payload: data.data });
        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const deleteActivityData = (id) => async (dispatch) => {
  try {
    const data = await apiRequest("post", "/remove-activity", { body: { id } });
    if (data.status === 200) {
      dispatch({ type: DELETE_ACTIVITY_DATA, payload: id });
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};
