import React from 'react';
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";

const FullScreenButton = ({ onClick }) => {
  return (
    <>
      <button
        onClick={onClick}
        className="relative h-7 w-7 p-1 flex flex-col justify-between items-stretch rounded-md bg-white text-gray-400 ring-1 ring-inset ring-transparent hover:z-10 hover:bg-gray-50 focus:z-10">
        <ArrowLeftIcon className="stroke-[4px] rotate-45 mr-auto" />
        <ArrowRightIcon className="stroke-[4px] rotate-45 ml-auto" />
      </button>
    </>
  );
};

export default FullScreenButton;