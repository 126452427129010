export const getUrlParams = (window) => {
  let urlRawParams = window.location.pathname.split("/");
  let urlParams = {};
  for (let i = 1; i < urlRawParams.length; i += 2) {
    urlParams[urlRawParams[i]] = urlRawParams[i + 1];
  }

  let urlRawQueries = [];
  let urlQueries = {};
  if (window.location.href.split("?")[1]) {
    urlRawQueries = window.location.href.split("?")[1].split("=");
  }
  for (let i = 0; i < urlRawQueries.length; i += 2) {
    urlQueries[urlRawQueries[i]] = urlRawQueries[i + 1];
  }

  return { params: urlParams, query: urlQueries };
};
