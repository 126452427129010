import React from "react";

const NoData = () => {
  return (
    <div className="bg-white rounded-md flex justify-center items-center gap-x-4 w-full h-[120px]">
      <p className="m-0 text-md font-medium text-gray-300 px-6 py-3 bg-gray-50/30 rounded border border-gray-100/70">No Data Found</p>
    </div>
  );
};

export default NoData;
