import GroupList from "src/components/Groups/GroupList";
import { H1 } from "src/components/Shared/Text/Headers";
import { connect } from "react-redux";
import { getAuthorizedUser } from "src/actions/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

const Groups = (props) => {

  const router = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    getAuthorizedUser();
  }, []);

  const existsPath = props.menus.some(obj => obj.path === router.pathname);

  if (Object.keys(props.menus).length && !existsPath) {
    navigate("/");
  }

  return (
    <>
      {
        Object.keys(props.menus).length > 0 && existsPath && (
          <>
            <H1>Groups</H1>
            <GroupList />
          </>
        )
      }
    </>
  );
};

// export default Groups;

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    menus: Object.values(state.menus),
  };
};

export default connect(mapStateToProps, { getAuthorizedUser })(Groups);

