import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { fetchData } from "src/async/fetch";
import { getPageCredentialOptions } from "src/actions/page";
import TextAccordion from "src/components/Shared/Accordions/TextAccordion";
import FrontLabeledInput from "src/components/Shared/Forms/Inputs/FrontLabeledInput";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import Modal from "src/components/Shared/Modal";
import { H4 } from "src/components/Shared/Text/Headers";
import { apiUrl } from "src/config/host";
import { connect } from "react-redux";
import useFetch from "src/hooks/useFetch";

const WorkspaceAddModal = ({ isOpen, onCancel = () => {}, onSuccess = () => {}, getPageCredentialOptions, credentials = [] }) => {
  const [disabled, setDisabled] = useState(false);
  const [workspaceTypeName, setWorkspaceTypeName] = useState("");
  const [workspaceType, setWorkspaceType] = useState("IFRAME_EMBED");

  const [name, setName] = useState("");
  const [subscriberInstanceURL, setSubscriberInstanceURL] = useState("");
  const [keyAttributeValue, setKeyAttributeValue] = useState("");
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");

  const [, setIsAvailableEditEmbedSettings] = useState(false);

  const {
    response: { data: integrations },
  } = useFetch("/pages/integration");

  const loadPageCreds = async () => {
    // setCredLoading(true);
    const ac = new AbortController();
    try {
      await getPageCredentialOptions({}, ac.signal);
    } catch (error) {
      // console.dir("ERROR:", error);
    }
    return () => ac.abort();
  };

  useEffect(() => {
    loadPageCreds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    for (const credential of credentials) {
      if (credential?.jwt_secret_count > 0 && credential?.embed_url && credential?.key_attribute) {
        setIsAvailableEditEmbedSettings(true);
      } else {
        setIsAvailableEditEmbedSettings(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials]);

  const updateWorkspaceType = () => {
    let type = "IFRAME_EMBED";
    if (workspaceType === "IFRAME_EMBED") {
      type = "JWT_FULL_EMBED";
    }
    setWorkspaceType(type);
  };

  const createWorkspace = async () => {
    try {
      setDisabled(true);

      const res = await fetchData("POST", `${apiUrl}/workspaces/add`, {
        name,
        subscriber_instance_url: subscriberInstanceURL,
        key_attribute_value: keyAttributeValue,
        client_id: clientId,
        client_secret: clientSecret,
        workspace_type: workspaceType,
      });
      const data = await res.json();
      if (data.status === 200) {
        onSuccess();
        setName("");
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }

      setTimeout(() => setDisabled(false), 300);
    } catch (error) {
      toast.error(error.message);
      setTimeout(() => setDisabled(false), 300);
    }
  };

  useEffect(() => {
    if (workspaceType) {
      setWorkspaceTypeName(workspaceType.charAt(0).toUpperCase() + workspaceType.slice(1).toLowerCase().replace("_", " "));
      setName("");
      setSubscriberInstanceURL("");
      setKeyAttributeValue("");
      setClientId("");
      setClientSecret("");
    }
  }, [workspaceType]);

  return (
    <Modal
      title="Workspace"
      secondaryTitle={`Create ${workspaceTypeName}`}
      isOpen={isOpen}
      onCancel={onCancel}
      onSuccess={createWorkspace}
      defaultOptions={{
        onSuccessButtonText: "Save",
        onSuccessLoaderVisible: disabled,
        onSuccessLoaderStart: disabled,
      }}>
      <div className="relative w-full space-y-4">
        <div className="relative w-full mb-4">
          <Input
            name="name"
            label="Workspace name"
            autoComplete="off"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        {integrations?.length > 0 &&
          integrations[0] &&
          integrations[0].embed_url &&
          integrations[0].jwt_secret_count > 0 &&
          integrations[0].key_attribute(
            <>
              {workspaceType === "JWT_FULL_EMBED" && (
                <>
                  <div className="relative w-full mb-4">
                    <Input
                      name="subscriber-url"
                      label="Subscriber Instance URL"
                      autoComplete="off"
                      value={subscriberInstanceURL}
                      onChange={(e) => setSubscriberInstanceURL(e.target.value)}
                    />
                  </div>
                  <div className="relative w-full mb-4">
                    <Input
                      name="attribute"
                      label="Key Attribute Value"
                      autoComplete="off"
                      value={keyAttributeValue}
                      onChange={(e) => setKeyAttributeValue(e.target.value)}
                    />
                  </div>
                  <div className="relative w-full mb-4">
                    <label className="flex justify-between text-sm font-medium text-gray-700 mb-1">Authentication</label>
                    <div className="grid grid-cols-2 gap-4">
                      <FrontLabeledInput
                        label="Client ID"
                        type="password"
                        autoComplete="new-password"
                        value={clientId}
                        onChange={(e) => setClientId(e.target.value)}
                        disabled={false}
                      />
                      <FrontLabeledInput
                        label="Client Secret"
                        type="password"
                        autoComplete="new-password"
                        value={clientSecret}
                        onChange={(e) => setClientSecret(e.target.value)}
                        disabled={false}
                      />
                    </div>
                  </div>
                </>
              )}
              <TextAccordion
                headerTextPosition="left"
                headerText="Advanced details">
                <div className="flex md:flex-row flex-col gap-y-3">
                  <Toggle
                    checked={workspaceType === "JWT_FULL_EMBED"}
                    onChange={updateWorkspaceType}
                  />
                  <H4 caption="Embed a full BI tool for your clients to use.">Full JWT application Embed</H4>
                </div>
              </TextAccordion>
            </>
          )}
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    credentials: state.pageCredentialOptions,
  };
};

export default connect(mapStateToProps, { getPageCredentialOptions })(WorkspaceAddModal);
