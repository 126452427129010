import { CameraIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Button from "src/components/Shared/Buttons/Button";
import { baseUrl } from "src/config/host";
import { formatName } from "src/helpers/formatName";
import { convertToBase64, getS3ImageDetails } from "src/helpers/image";

const ProfileImage = ({ user, setImage, me, newDesign = false, isFormdataValue = false }) => {
  const [isImgAvail, setIsImgAvail] = useState(true);
  const updateImage = (e) => {
    const allowedExts = ["jpg", "jpeg", "png", "bmp", "gif", "svg", "webp", "avif"];

    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (!allowedExts.includes(ext)) {
        toast.error("Please upload a valid image.");
      } else {
        setIsImgAvail(true)
        if (isFormdataValue) {
          setImage(e.target.files[0]);
        } else {
          convertToBase64(e.target.files[0], setImage);
        }
      }
    }
  };

  const [s3Image, setS3Image] = useState({
    loading: false,
    data: null,
  });

  useEffect(() => {
    if (user?.image) {
      if (!isFormdataValue && user.image && !user.image.includes("data:image/")) {
        const loadS3Image = async () => {
          setS3Image((data) => ({ ...data, loading: true }));
          const res = await getS3ImageDetails(user.image);

          if (res.status === 200) {
            setIsImgAvail(true)
            setS3Image({ loading: false, data: res.data });
          } else {
            setS3Image((data) => ({ ...data, loading: false }));
          }
        };

        loadS3Image();
      } else if (isFormdataValue && user.image) {
        const loadS3Image = async () => {
          setS3Image((data) => ({ ...data, loading: true }));
          const res = await getS3ImageDetails(user.image);

          if (res.status === 200) {
            setIsImgAvail(true)
            setS3Image({ loading: false, data: res.data });
          } else {
            setS3Image((data) => ({ ...data, loading: false }));
          }
        };

        loadS3Image();
      } else {
        setS3Image({ loading: false, data: user?.image });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.image]);

  // const onErrorImg = () => {
  //   setIsImgAvail(false);
  // };



  const profileImageInitials = <div className="w-full h-full rounded-full flex justify-center items-center text-gray-900 text-2xl font-medium uppercase bg-gray-200">{formatName(user?.name, user?.email)}</div>;

  return (
    <div className="grid gap-y-1">
      <label className="flex justify-between text-sm font-medium text-gray-700">Photo</label>
      <div className="flex space-x-4 items-center">
        <div className="relative hidden sm:block h-16 w-16 flex-shrink-0">
          <div className="group h-full w-full rounded-full overflow-hidden">
            {!isImgAvail ? profileImageInitials : isImgAvail || (user?.image && s3Image.data && !s3Image.loading) ? (
              <img
                className="h-full w-full object-cover"
                src={user?.image instanceof File ? URL.createObjectURL(user?.image) : s3Image.data || baseUrl + user?.image}
                alt="profile"
                onError={() => setIsImgAvail(false)}
              />
            ) : (
              profileImageInitials
            )}
            {newDesign && (
              <div>
                <label
                  htmlFor="photo-edit"
                  className="h-7 w-7 cursor-pointer group-hover:opacity-80 opacity-0 rounded-full bg-highlightColor transition-all duration-75 absolute -top-2 -right-2 z-20">
                  <CameraIcon className="h-full w-full p-1 text-white" />
                </label>
                <input
                  id="photo-edit"
                  type="file"
                  className="hidden"
                  onChange={updateImage}
                  disabled={
                    (user?.type === 'admin' && me?.type !== 'admin') ? true : false
                  }
                />
              </div>
            )}
          </div>
        </div>
        {
          (user?.type === 'admin' && me?.type !== 'admin') ? <></> :
            <div className="ml-6 flex items-center mt-0">
              <Button
                version="gray"
                className="overflow-hidden relative">
                <span className="text-sm text-gray-600">Upload image</span>
                <input
                  type="file"
                  className="absolute opacity-0 w-96 top-0 right-0 h-full cursor-pointer z-10"
                  onChange={updateImage}
                />
              </Button>
            </div>
        }

      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    me: state.auth.user,
  };
};

export default connect(mapStateToProps, {})(ProfileImage);
