import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import WorkspaceIntegrationItem from "src/components/Workspaces/Workspace/Settings/WorkspaceIntegration/WorkspaceIntegrationItem";
import { urlBuilder } from "src/helpers/urlBuilder";
import useFetch from "src/hooks/useFetch";

const WorkspaceIntegrationsList = ({ workspaceId, workspaceDetails }) => {
  const {
    response: { data: integrations },
    status: { done: integrationsLoaded },
  } = useFetch(urlBuilder(`/workspaces/:workspace_id/integrations`, { workspace_id: workspaceId }));

  return (
    <>
      {integrationsLoaded ? (
        integrations?.length > 0 &&
        integrations.map((integration, index) => {
          return (
            <WorkspaceIntegrationItem
              key={index}
              integration={integration}
              integrationsLoaded={integrationsLoaded}
              workspaceId={workspaceId}
              is_global={false}
              workspaceDetails={workspaceDetails}
            />
          );
        })
      ) : (
        <Preloader />
      )}
    </>
  );
};

export default WorkspaceIntegrationsList;
