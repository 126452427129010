import { GET_SITE_SETTINGS_DATA, GET_AUTO_SITE_SETTINGS_DATA } from "src/actions/type";

const siteSettingsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SITE_SETTINGS_DATA:
      return action.payload;
    case GET_AUTO_SITE_SETTINGS_DATA:
      return { ...state, ...action.payload }
    default:
      return state;
  }
};

export default siteSettingsReducer;
