import { CodeBracketIcon, GlobeAltIcon, LinkIcon, PlusIcon, PresentationChartBarIcon } from "@heroicons/react/24/outline";
import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { managePageData } from "src/actions/page";
import { apiRequest } from "src/async/apiUtils";
import Button from "src/components/Shared/Buttons/Button";
import Checkbox from "src/components/Shared/Forms/Checkbox";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import Members from "src/components/Shared/Members";
import QuickMenu from "src/components/Shared/QuickMenu";
import TableRow from "src/components/Shared/Table/TableRow";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import TRBody from "src/components/Shared/Table/TRBody";

const PageEntry = ({ user, page, setDeleteId, editPage, pagePrev, managePageData, pageType, workspaceId, activeUserAssignModal = () => {}, activeGroupAssignModal = () => {}, pageSelect = () => {}, setDomoPageIdForRefresh, ...props }) => {
  const [items, setItems] = useState([]);
  const [pageDetails, setPageDetails] = useState({});
  const [loader, setLoader] = useState(true);

  const iconTypes = {
    DOMO: {
      icon: <PresentationChartBarIcon />,
      name: "Dashboard",
    },
    CUSTOM_HTML: {
      icon: <CodeBracketIcon />,
      name: "Custom HTML",
    },
    // LANDING_PAGE: {
    //   icon: <WindowIcon />,
    //   name: "Landing Page",
    // },
    EXTERNAL_LINK: {
      icon: <LinkIcon />,
      name: "External Link",
    },
  };

  useEffect(() => {
    let item = [];
    if (!props.is_global) {
      if (page.workspace_id?.page?.is_global_template === true) {
        item.push({ name: "Preview", onClick: () => pagePrev(page), color: "text-grey-500" });
      } else {
        if (authorizeUserComponentAccess(user, workspaceId, "page", ["update"])) {
          item.push({ name: "Edit", onClick: () => editPage(page), color: "text-grey-500" });
        }
        item.push({ name: "Preview", onClick: () => pagePrev(page), color: "text-grey-500" });
        if (authorizeUserComponentAccess(user, workspaceId, "page", ["delete"])) {
          item.push({ name: "Delete", onClick: () => setDeleteId(page._id), color: "text-red-500" });
        }
      }
    } else {
      if (user?.type === "admin" && authorizeUserComponentAccess(user, workspaceId, "page", ["update"])) {
        item.push({ name: "Edit", onClick: () => editPage(page), color: "text-grey-500" });
      }
      item.push({ name: "Preview", onClick: () => pagePrev(page), color: "text-grey-500" });
      if (user?.type === "admin" && authorizeUserComponentAccess(user, workspaceId, "page", ["delete"])) {
        item.push({ name: "Delete", onClick: () => setDeleteId(page._id), color: "text-red-500" });
      }
    }
    setItems(item);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, page, workspaceId]);

  const getPageDetails = useCallback(async () => {
    const res = await apiRequest("get", `/workspaces/${props?.workspaceDetails?._id}/domo-pages/${page?.id}`, { queries: { authToken: props?.workspaceDetails?.auth_token, workspaceId: props?.workspaceDetails?._id } });

    if (res.data.status === 200) {
      setPageDetails(res.data.data || []);
    }
    setLoader(false);
  }, [props?.workspaceDetails, page?.id]);

  useEffect(() => {
    if (pageType === "JWT_FULL_EMBED") {
      getPageDetails();
    }
  }, [getPageDetails, pageType]);

  useEffect(() => {
    if (props?.domoPageIdForRefresh && props?.domoPageIdForRefresh.includes(page?.id)) {
      setLoader(true);
      setDomoPageIdForRefresh(props?.domoPageIdForRefresh.filter((p) => p !== page?.id));
      getPageDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPageDetails, props?.domoPageIdForRefresh, page?.id]);

  return (
    <>
      <TableRow>
        <TRBody className="whitespace-pre-wrap">
          <div className={"flex items-center gap-3 " + (props.depthLevel > 0 ? `pl-${props.depthLevel}` : "")}>
            {pageType === "JWT_FULL_EMBED" && <Checkbox onChange={(e) => pageSelect(e, page.id)} />}
            <div className="relative text-gray-900 font-medium flex flex-col sm:flex-row sm:items-center">
              <div>{page.name}</div>
              {!props.is_global && page.workspace_id && (page.workspace_id?.page?.is_global_template === true || page.workspace_id.length > 0) && (
                <div
                  data-tooltip-id={`${page._id}-is_global`}
                  className="-ml-1 mt-1 sm:m-0 sm:ml-3 bg-slate-50 text-xs w-[60px] sm:w-20 flex items-center rounded py-[2px] px-[3px] sm:bg-transparent gap-x-2 text-gray-300 cursor-default">
                  <GlobeAltIcon className="sm:w-5 sm:h-5 h-4 w-4" />
                  Global
                </div>
              )}
            </div>
            <ReactTooltip
              id={`${page._id}-is_global`}
              delayShow={200}
              positionStrategy="fixed"
              className="opacity-100 bg-gray-700 rounded px-2 py-2">
              <div className="font-normal leading-[10px]">Global pages are managed by admins.</div>
            </ReactTooltip>
          </div>
        </TRBody>
        {pageType === "IFRAME_EMBED" && (
          <>
            <TRBody className="whitespace-pre-wrap">
              <div className="text-gray-900 font-medium">
                <div className="flex flex-col item-center gap-x-4">
                  <div className="flex item-center gap-x-4 relative">
                    <div className="h-5 w-5">{iconTypes[page.page_type]?.icon}</div>
                    <div className="hidden sm:flex">{iconTypes[page.page_type]?.name}</div>
                  </div>
                </div>
              </div>
            </TRBody>
            <td className="bg-white px-5 py-3 text-sm">
              <div className="flex justify-end">
                <QuickMenu items={items} />
              </div>
            </td>
          </>
        )}
        {pageType === "JWT_FULL_EMBED" && (
          <>
            <td className={"bg-white px-5 py-3 text-sm"}>
              <div className="flex items-center gap-2">
                {loader ? (
                  <Preloader
                    className={"!p-0 !w-[200px]"}
                    size="sm"
                    circleDimension="6"
                  />
                ) : (
                  <>
                    <Members
                      className="!justify-start"
                      id={page.id}
                      group={{
                        users: props?.users.filter((item) => pageDetails?.visibility?.userIds.includes(item.id)),
                      }}
                    />
                    <Button
                      version="black"
                      className="!px-2 !h-8 hidden md:flex"
                      onClick={() => {
                        activeUserAssignModal([page.id], pageDetails?.visibility?.userIds);
                      }}>
                      <PlusIcon className="w-5 h-5" /> User
                    </Button>
                  </>
                )}
              </div>
            </td>
            <td className="bg-white px-5 py-3 text-sm">
              <div className="flex items-center gap-2">
                {loader ? (
                  <Preloader
                    className={"!p-0"}
                    size="sm"
                    circleDimension="6"
                  />
                ) : (
                  <>
                    <Members
                      className="!justify-start"
                      id={page.id}
                      group={{
                        users: props?.groups.filter((item) => pageDetails?.visibility?.groupIds.includes(item.id)),
                      }}
                      type="group"
                    />
                    <Button
                      version="black"
                      className="!px-2 !h-8 hidden md:flex"
                      onClick={() => {
                        activeGroupAssignModal([page.id], pageDetails?.visibility?.groupIds);
                      }}>
                      <PlusIcon className="w-5 h-5" /> Group
                    </Button>
                  </>
                )}
              </div>
            </td>
          </>
        )}
      </TableRow>
      {page?.children?.length > 0 &&
        page?.children.map((page, i) => {
          return (
            <PageEntry
              workspaceId={workspaceId}
              key={page.id}
              page={page}
              setDeleteId={setDeleteId}
              editPage={editPage}
              pagePrev={pagePrev}
              pageType={pageType}
              is_global={props.is_global}
              users={props.users}
              groups={props.groups}
              workspaceDetails={props?.workspaceDetails}
              depthLevel={props.depthLevel + 9}
              domoPageIdForRefresh={props?.domoPageIdForRefresh}
              setDomoPageIdForRefresh={setDomoPageIdForRefresh}
              activeUserAssignModal={activeUserAssignModal}
              activeGroupAssignModal={activeGroupAssignModal}
              pageSelect={pageSelect}
            />
          );
        })}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, { managePageData })(PageEntry);
