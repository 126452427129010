import { ClipboardDocumentListIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { getAuthorizedUser } from "src/actions/auth";
import { getPages } from "src/actions/page";
import { editWorkspaceGroupDetails, manageGroupUser } from "src/actions/workspace";
import { apiRequest } from "src/async/apiUtils";
import { fetchData } from "src/async/fetch";
import GroupModalAdd from "src/components/Groups/GroupModalAdd";
import Button from "src/components/Shared/Buttons/Button";
import Section from "src/components/Shared/Containers/Section";
import IconsWithPlus from "src/components/Shared/Icons/IconsWithPlus";
import NoData from "src/components/Shared/LoadingAnimations/NoData";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import Modal from "src/components/Shared/Modal";
import { H3 } from "src/components/Shared/Text/Headers";
import WorkspaceGroupItem from "src/components/Workspaces/Workspace/Groups/WorkspaceGroupItem";
import { apiUrl } from "src/config/host";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import { classNames } from "src/helpers/classNames";
import useFetch from "src/hooks/useFetch";
import { urlBuilder } from "src/helpers/urlBuilder";
import WorkspaceManageUsersModal from "src/components/Workspaces/Workspace/WorkspaceManageUsersModal";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const WorkspaceGroupList = ({ pageName, getAuthorizedUser, viewOnly = false, userId = "", workspaceId = "", workspaceDetails = {}, ...props }) => {
  const trustedAttributeArr = [
    {
      _id: "user.fullName",
      value: "user.fullName",
      name: "Full Name",
    },
    {
      _id: "user.email",
      value: "user.email",
      name: "Email Address",
    },
  ];

  const valueType = [
    {
      _id: "value",
      value: "value",
      name: "Text Value",
    },
    {
      _id: "attribute",
      value: "attribute",
      name: "User Attribute",
    },
  ];

  const [loaded, setLoaded] = useState(false);
  const [groups, setGroups] = useState([]);

  const [groupModalOpen, setGroupModalOpen] = useState(false);

  const [addGroupStatus, setAddGroupStatus] = useState(false);
  const [editGroupStatus, setEditGroupStatus] = useState(false);
  const [editId, setEditId] = useState(null);
  const [deleteGroup, setDeleteGroup] = useState(null);
  const [isDeleteGroupLoading, setIsDeleteGroupLoading] = useState(null);
  const [workspaceUsers, setWorkspaceUsers] = useState([]);

  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [defaultSelectedGroup, setDefaultSelectedGroup] = useState([]);

  const [groupImportUserModalOpen, setGroupImportUserModalOpen] = useState(false);
  const [sendWelcomeEmail, setSendWelcomeEmail] = useState(true);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [successModalStart, setSuccessModalStart] = useState(false);

  const {
    response: { data: pageOptions },
  } = useFetch(urlBuilder(`/workspaces/:workspace_id/pages`, { workspace_id: workspaceId }), { query: { sort: "ASC", page_type: ["DOMO", "CUSTOM_HTML", "EXTERNAL_LINK"] } });

  const {
    response: { data: operatorOptions },
  } = useFetch("/operators/list");

  const {
    response: { data: defaultGroups },
  } = useFetch(!workspaceId ? `/workspaces/${workspaceId}/groups/details` : "");

  useEffect(() => {
    setGroups(defaultGroups);
  }, [defaultGroups]);

  const loadWorkspaceUserData = async (users = [], url = "") => {
    try {
      const res = await apiRequest("get", url || `/domo-users/list`, { queries: { authToken: workspaceDetails?.auth_token, active_status: true } });

      if (res.data) {
        setWorkspaceUsers(res.data.data);
      }
    } catch (error) { }
  };

  const loadGroupsData = async (users = []) => {
    try {
      setLoaded(false);
      let workspaceGroupsData = {};

      try {
        let workspaceGroupsResponse;
        if (pageName === "user") {
          workspaceGroupsResponse = await fetchData("GET", urlBuilder(`${apiUrl}/workspaces/:workspace_id/users/:user_id/groups`, { workspace_id: workspaceId, user_id: userId }));
        } else {
          workspaceGroupsResponse = await fetchData("GET", urlBuilder(`${apiUrl}/workspaces/:workspace_id/domo-groups?auth_token=${workspaceDetails?.auth_token}&userFetch=GET`, { workspace_id: workspaceId }));
        }
        workspaceGroupsData = await workspaceGroupsResponse.json();

        if (workspaceGroupsData.status === 200) {
          setGroups(workspaceGroupsData.data);
          setLoaded(true);
          return workspaceGroupsData.data;
        } else {
          setLoaded(true);
          throw new Error(workspaceGroupsData.message);
        }
      } catch (error) {
        setLoaded(true);
        throw new Error(error.message);
      }
    } catch (error) {
      setLoaded(true);
    }
  };

  useEffect(() => {
    if (workspaceDetails?._id) {
      loadGroupsData();
      loadWorkspaceUserData(workspaceUsers, workspaceDetails?.workspace_type !== "JWT_FULL_EMBED" ? "/users/list" : "/domo-users/list");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceDetails?._id]);

  useEffect(() => {
    if (workspaceDetails?._id && workspaceUsers?.length > 0 && groups.length > 0) {
      setGroups(
        groups.map((group) => ({
          ...group,
          users: !Array.isArray(group.userIds) ? group.users : workspaceUsers.filter((user) => group.userIds.includes(user.id)),
        }))
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceDetails?._id, workspaceUsers?.length, groups?.length]);

  const handleDeleteGroup = async () => {
    try {
      setIsDeleteGroupLoading(true);
      await apiRequest("delete", urlBuilder(`/workspaces/:workspace_id/groups/:group_id?authToken=${workspaceDetails?.auth_token}`, { workspace_id: workspaceId, group_id: deleteGroup._id || deleteGroup.id }));
      setDeleteGroup(null);
      loadGroupsData();
      setSuccessModalStart(false);
    } catch (error) {

    } finally {
      setIsDeleteGroupLoading(false);
    }

  };

  const handleSetGroups = async (groups) => {
    setGroups(groups);
  };

  const userModalOpen = (active, group) => {
    setDefaultSelectedGroup(group);
    setAddUserModalOpen(active);
  };

  const handleGroupUserChanges = async (users, importStatus, fromImportModal) => {
    try {
      if (workspaceDetails?.workspace_type === "JWT_FULL_EMBED" && !fromImportModal) {
        if (workspaceUsers.filter((user) => users.includes(user.id || user._id) && !user.portalUser).length) {
          setAddUserModalOpen(false);
          setGroupImportUserModalOpen(true);
          return;
        }
      }

      setSuccessModalStart(true);

      const { data } = await apiRequest("post", `/workspaces/groups/users/manage`, {
        body: {
          _id: defaultSelectedGroup._id || defaultSelectedGroup.id,
          user_id: users,
          auth_token: workspaceDetails?.auth_token,
          importStatus,
          password,
          confirmPassword,
          sendWelcomeEmail,
          workspace_id: workspaceDetails?._id,
        },
      });
      setGroups([
        ...groups.map((grp) => {
          const cond = grp.id ? grp.id === defaultSelectedGroup.id : grp._id === defaultSelectedGroup._id;
          if (cond) {
            return {
              ...grp,
              users: workspaceUsers.filter((user) => users.includes(user._id || user.id)),
              userIds: users,
            };
          } else {
            return grp;
          }
        }),
      ]);
      setAddUserModalOpen(false);
      setGroupImportUserModalOpen(false);
      setSuccessModalStart(false);

      toast.success(data.message);
    } catch (error) {
      toast.error(error.message);
      setSuccessModalStart(false);
    }
  };

  const updateGroupOrdering = async (newGroups) => {
    try {
      await apiRequest("post", `/workspaces/${workspaceDetails?._id}/groups/ordering/manage`, {
        body: {
          groups: newGroups.map((grp) => grp._id),
          workspace_id: workspaceDetails?._id,
        },
      });
    } catch (error) {
      console.dir("ERROR:", error);
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = async (result) => {
    if (!result?.destination) {
      return;
    }

    const newGroups = reorder(groups, result.source.index, result.destination.index);
    setGroups(newGroups);
    updateGroupOrdering(newGroups);
  };

  return (
    <>
      <Section className="gap-y-8">
        <div className="flex sm:items-center px-2 gap-x-10">
          <H3 caption="Organize your pages into packages and assign users to have access.">Workspace Groups</H3>
          {!viewOnly && authorizeUserComponentAccess(props.me, workspaceId, "group", ["create"]) && (
            <div className="flex justify-end h-10">
              <Button
                version="secondary"
                onClick={() => {
                  setGroupModalOpen(true);
                  setAddGroupStatus(true);
                  setEditGroupStatus(false);
                  setEditId(null);
                }}>
                <IconsWithPlus
                  strokeColor={"stroke-highlightColor"}
                  item={{ icon: ClipboardDocumentListIcon }}
                />
              </Button>
            </div>
          )}
        </div>
        {loaded ? (
          <div className="relative grid gap-y-4">
            {groups?.length > 0 ? (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      className="space-y-4 pl-10"
                      {...provided.droppableProps}
                      ref={provided.innerRef}>
                      {groups.map((group, index) => {
                        return (
                          <Draggable
                            key={`${group._id}_${index}`}
                            draggableId={`${group._id}_${index}`}
                            index={index}>
                            {(provided, snapshot) => (
                              <WorkspaceGroupItem
                                key={group._id}
                                viewOnly={viewOnly}
                                workspaceId={workspaceId}
                                defaultGroup={group}
                                pageOptions={pageOptions}
                                operatorOptions={operatorOptions}
                                setGroups={handleSetGroups}
                                setDeleteGroup={setDeleteGroup}
                                trustedAttributeArr={trustedAttributeArr}
                                valueType={valueType}
                                workspaceDetailsData={workspaceDetails}
                                workspaceUsers={workspaceUsers}
                                importable
                                userModalOpen={userModalOpen}
                                workspaceDetails={workspaceDetails}
                                innerRef={provided.innerRef}
                                draggableProps={provided.draggableProps}
                                dragHandleProps={provided.dragHandleProps}
                              />
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
              <NoData />
            )}
          </div>
        ) : (
          <div className={classNames("h-20 transition-all duration-300", "opacity-100")}>
            <Preloader
              className="h-[80px]"
              circleDimension="10"
              size="base"
            />
          </div>
        )}
      </Section>
      <GroupModalAdd
        isOpen={groupModalOpen}
        defaultWorkspaceId={workspaceId}
        setIsOpen={setGroupModalOpen}
        setRefresh={() => loadGroupsData(workspaceUsers)}
        defaultOptions={{
          onSuccessButtonText: "Save",
        }}
        defaultStyles={{
          overFlowYVisible: false,
        }}
        title="Group"
        secondaryTitle="Add"
        loadUsers={loadWorkspaceUserData}
        addGroupStatus={addGroupStatus}
        setAddGroupStatus={setAddGroupStatus}
        editGroupStatus={editGroupStatus}
        setEditGroupStatus={setEditGroupStatus}
        editId={editId}
        setEditId={setEditId}
        workspaceDetails={workspaceDetails}
        importable
      />
      <Modal
        title="Group"
        secondaryTitle="Delete"
        isOpen={!!deleteGroup}
        onCancel={() => setDeleteGroup(null)}
        onSuccess={handleDeleteGroup}
        isLoading={isDeleteGroupLoading}
        defaultOptions={{
          onSuccessButtonText: "Delete",
          onSuccessLoaderVisible: true,
          onSuccessLoaderStart: successModalStart,
        }}>
        <div className="grid gap-y-8 whitespace-nowrap text-sm text-gray-500">Are you sure you want to delete {deleteGroup?.name}?</div>
      </Modal>
      <WorkspaceManageUsersModal
        title={`${workspaceDetails?.name} / Users`}
        secondaryTitle="Add"
        isOpen={addUserModalOpen}
        defaultSelectedUsers={defaultSelectedGroup?.users?.map((user) => user._id || user.id)}
        onSuccess={(users, importStatus, fromImportModal) => {
          handleGroupUserChanges(users, importStatus, fromImportModal);
        }}
        onCancel={() => setAddUserModalOpen(false)}
        workspaceId={workspaceDetails?._id}
        workspaceDetailsData={workspaceDetails}
        workspaceUsers={workspaceUsers}
        isImportModalOpen={groupImportUserModalOpen}
        setIsImportModalOpen={setGroupImportUserModalOpen}
        sendWelcomeEmail={sendWelcomeEmail}
        setSendWelcomeEmail={setSendWelcomeEmail}
        password={password}
        setPassword={setPassword}
        confirmPassword={confirmPassword}
        setConfirmPassword={setConfirmPassword}
        hideCrossIcon={false}
        disableStatus={successModalStart}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    pages: Object.values(state.pages),
    me: state.auth.user,
  };
};
export default connect(mapStateToProps, {
  editWorkspaceGroupDetails,
  getAuthorizedUser,
  getPages,
  manageGroupUser,
})(WorkspaceGroupList);
