import mapKeys from "lodash/mapKeys";
import omit from "lodash/omit";
import { GET_ACTIVITY_DATA, DELETE_ACTIVITY_DATA } from "src/actions/type";

const activityReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ACTIVITY_DATA:
      return { ...mapKeys(action.payload, "_id") };
    case DELETE_ACTIVITY_DATA:
      return omit(state, action.payload);
    default:
      return state;
  }
};

export default activityReducer;
