import { ClipboardDocumentListIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getOperators } from "src/actions/operator";
import { getPages } from "src/actions/page";
import { deleteGroupData, getGroupData, getUsers } from "src/actions/user";
import GroupEntry from "src/components/Groups/GroupEntry";
import Button from "src/components/Shared/Buttons/Button";
import IconsWithPlus from "src/components/Shared/Icons/IconsWithPlus";
import Modal from "src/components/Shared/Modal";
import PaginationFooter from "src/components/Shared/PaginationFooter";
import Table from "src/components/Shared/Table/Table";
import TRHeader from "src/components/Shared/Table/TRHeader";
import TableOptions from "src/components/Shared/TableOptions";
import GroupModalEdit from "src/components/Groups/GroupModalEdit";
import Search from "src/components/Shared/Search";
import useFetch from "src/hooks/useFetch";
import { baseUrl, noFavicon } from "src/config/host";

const GroupList = ({ ...props }) => {
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState("");

  const [deleteId, setDeleteId] = useState(null);
  const [isDeleteDisable, setIsDeleteDisable] = useState(null);

  const [groupModalOpen, setGroupModalOpen] = useState(false);
  const [addGroupStatus, setAddGroupStatus] = useState(false);
  const [editGroupStatus, setEditGroupStatus] = useState(false);
  const [editId, setEditId] = useState(null);

  const user = useSelector((state) => state.auth.user);


  const {
    response: { data: groups, meta },
    status: { done: groupsLoaded },
    refreshData: refreshGroups,
  } = useFetch("/groups", { query: { limit: limit || 10, offset: offset || 0, keyword: keyword || "" } });

  const handlePaginationChange = ({ limit, offset }) => {
    setLimit(limit);
    setOffset(offset);
  };

  useEffect(() => {
    refreshGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset, keyword]);

  const removeGroup = async (e) => {
    try {
      setIsDeleteDisable(true)
      const message = await props.deleteGroupData(deleteId);
      setDeleteId(null);
      refreshGroups();
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsDeleteDisable(false)
    }
  };

  return (
    <>
      <TableOptions
        leftContent={
          <Search
            keyword={keyword}
            setKeyword={(val) => {
              setOffset(0)
              setKeyword(val)
            }}
          />
        }
        rightContent={user?.type === "admin" ? (
          <Button
            version="secondary"
            onClick={() => {
              setGroupModalOpen(true);
              setAddGroupStatus(true);
              setEditGroupStatus(false);
              setEditId(null);
            }}>
            <IconsWithPlus
              strokeColor={"stroke-highlightColor"}
              item={{ icon: ClipboardDocumentListIcon }}
            />
          </Button>
        ) : <></>

        }
      />

      <Table
        tableHeader={
          <TRHeader>
            <th
              scope="col"
              className="rounded-l-xl overflow-hidden py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
              Name
            </th>
            <th
              scope="col"
              className="rounded-l-xl overflow-hidden py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
              Pages
            </th>
            <th
              scope="col"
              className="rounded-r-xl overflow-hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></th>
          </TRHeader>
        }
        colSpan="5"
        setDeleteId={setDeleteId}
        deleteId={deleteId}
        loaded={groupsLoaded}
        dataExists={groups?.length > 0}>
        {groups.map((group, i) => {
          return (
            <Fragment key={group._id}>
              {group?.workspace_id?.name !== groups[i - 1]?.workspace_id?.name && (
                <tr>
                  <td
                    colSpan={3}
                    className="flex items-center gap-x-4 break-all py-4 px-4 sm:pl-6 sm:py-3 text-sm font-semibold text-gray-500 whitespace-pre-wrap bg-gray-100">
                    <div className="flex gap-4 items-center">
                      <div
                        style={{ backgroundColor: group?.workspace_id.top_bar_color }}
                        className="w-10 h-10 overflow-hidden rounded">
                        <img
                          src={
                            group?.workspace_id.image_favicon?.includes(".s3.") ? group?.workspace_id.image_favicon + "?" + Date.now() : group?.workspace_id.image_favicon?.includes("data:image/") ? group?.workspace_id.image_favicon : baseUrl + group?.workspace_id.image_favicon
                          }
                          alt="Logo"
                          className="w-full h-full object-contain"
                          onError={(e) => (e.target.src = `${noFavicon}`)}
                        />
                      </div>
                      {group?.workspace_id?.name}
                    </div>
                  </td>
                </tr>
              )}
              <GroupEntry
                key={group._id}
                group={group}
                deleteId={deleteId}
                setDeleteId={setDeleteId}
                setEditId={setEditId}
                setGroupModalOpen={setGroupModalOpen}
                setAddGroupStatus={setAddGroupStatus}
                setEditGroupStatus={setEditGroupStatus}
              />
            </Fragment>
          );
        })}
      </Table>
      <PaginationFooter
        itemName="Group record"
        limit={limit}
        offset={offset}
        count={meta?.count}
        onChange={handlePaginationChange}
      />

      <Modal
        title="Group"
        secondaryTitle="Delete"
        isOpen={!!deleteId}
        onCancel={() => setDeleteId(null)}
        onSuccess={removeGroup}
        isLoading={isDeleteDisable}
        defaultOptions={{
          onSuccessButtonText: "Delete",
        }}>
        <div className="grid gap-y-8 whitespace-nowrap text-sm text-gray-500">Are you sure you want to delete {groups?.find((item) => item._id === deleteId)?.name}?</div>
      </Modal>
      <GroupModalEdit
        isOpen={groupModalOpen}
        setIsOpen={setGroupModalOpen}
        setRefresh={() => {
          refreshGroups();
        }}
        defaultOptions={{
          onSuccessButtonText: "Save",
        }}
        secondaryTitle={editGroupStatus ? "Edit" : "Add"}
        title={editGroupStatus ? "Group" : "Group"}
        addGroupStatus={addGroupStatus}
        setAddGroupStatus={setAddGroupStatus}
        editGroupStatus={editGroupStatus}
        setEditGroupStatus={setEditGroupStatus}
        editId={editId}
        setEditId={setEditId}
        groups={groups}
      />
    </>
  );
};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, { getGroupData, deleteGroupData, getPages, getOperators, getUsers })(GroupList);
