import { Dialog, Menu, Transition } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { Bars3BottomLeftIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { Fragment, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { logout } from "src/actions/auth";
import { getMenuData } from "src/actions/menu";
import { getDomoPageList, resetWorkspaceDetails } from "src/actions/page";
import NavItem from "src/components/Navigation/NavItem";
import NavigationPreviewBox from "src/components/Preview/NavigationPreviewBox";
import AdminNavDesktop from "src/components/RoleViews/Admin/AdminNavDesktop";
import Logo from "src/components/Shared/Icons/Logo";
import UserProfile from "src/components/Users/UserProfile";
import { FeatureFlagsProvider } from "src/context/FeatureFlagContext";
import { asyncLocalStorage } from "src/helpers/asyncLocalStorage";
import { classNames } from "src/helpers/classNames";
import useOnClickOutside from "src/hooks/useOnClickOutside";

const UserLayout = ({ user, site, getMenuData, logout, getDomoPageList, resetWorkspaceDetails, workspaceDetails, children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [userNavigation, setUserNavigation] = useState([
    { name: "Your Profile", path: `/users/edit/${user?._id}`, navName: "Users" },
    { name: "Change Password", path: "/users/edit/me/change-password", navName: "Users" },
    { name: "Sign out", path: "/" },
  ]);
  const [currentNavigation, setCurrentNavigation] = useState("");
  const [hasImpersonatedUserId, setHasImpersonatedUserId] = useState(false);
  const [rolePreviewModal, setRolePreviewModal] = useState(false);
  const ref = useRef();

  useOnClickOutside(ref, () => setSidebarOpen(false));

  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [sidebarOpenMobile, setSidebarOpenMobile] = useState(false);
  const [loadedMenu, setLoadedMenu] = useState(false);

  const [pageList, setPageList] = useState([]);

  const updateNavigation = (navItem) => {
    setCurrentNavigation(navItem.key);
    // navigate(`workspaces/${navItem.key}/portal`);
  };

  useEffect(() => {
    if (user?._id) {
      const getList = async () => {
        try {
          const n = location.pathname.split("/");
          const workspaceId = n.length > 2 ? n[2] : "";
          const response = await getDomoPageList({ user_id: user?._id, workspace_id: workspaceId });
          if (response.menu.length > 0 || response.individualMenu.length > 0 || response.data?.workspace_type === "JWT_FULL_EMBED") {
            let list = [];
            for (const indMenu of response.individualMenu) {
              list.push({
                key: indMenu._id,
                name: indMenu?.page_id?.name,
                path: (indMenu?.page_id?.page_type === "EXTERNAL_LINK") ? indMenu?.page_id?.page_link : `workspace/${indMenu.workspace_id}/page/${indMenu._id}`,
                permissions: [],
                isExternal: (indMenu?.page_id?.page_type === "EXTERNAL_LINK") ? true: false,
                page_link_newtab: indMenu?.page_id?.page_link_newtab
              });
            }
            for (const lt of response.menu) {
              let permissions = [];
              if (response.menu.length + response.individualMenu.length > 1) {
                for (const permission of lt.permissions) {
                  permissions.push({
                    key: permission._id,
                    name: permission.page?.name,
                    path: (permission.page?.page_type === "EXTERNAL_LINK") ? permission.page?.page_link : `workspace/${lt.workspace_id}/page/${permission._id}`,
                    isExternal: (permission.page?.page_type === "EXTERNAL_LINK") ? true: false,
                    page_link_newtab: permission.page?.page_link_newtab
                  });
                }
                list.push({
                  key: lt._id,
                  name: lt.name,
                  toggle: response.data && response.data?.layout_type === "TOP_NAVIGATION" ? false : true,
                  permissions: permissions,
                });
              } else {
                for (const permission of lt.permissions) {
                  list.push({
                    key: permission._id,
                    name: permission.page.name,
                    path: (permission.page?.page_type === "EXTERNAL_LINK") ? permission.page?.page_link: `workspace/${lt.workspace_id}/page/${permission._id}`,
                    permissions: [],
                    isExternal: (permission.page?.page_type === "EXTERNAL_LINK") ? true: false,
                    page_link_newtab: permission.page?.page_link_newtab
                  });
                }
              }
            }
            setPageList(list);
          } else {
            navigate("/workspaces/menu");
          }
        } catch (error) {
          navigate("/workspaces/menu");
        }
      };

      const resetWorkspace = async () => {
        try {
          await resetWorkspaceDetails({});
        } catch (error) {}
      };

      if (location.pathname.includes("workspaces/menu")) {
        resetWorkspace();
        setPageList([]);
      } else {
        getList();
      }
      if (user?.creation_type === "SSO_LOGIN") {
        if (location.pathname.includes("workspaces/menu")) {
          setUserNavigation([
            { name: "Your Profile", path: `/update-user/${user?._id}`, navName: "Users" },
            { name: "Sign out", path: "/" },
          ]);
        } else {
          setUserNavigation([
            { name: "Your Profile", path: `/update-user/${user?._id}`, navName: "Users" },
            { name: "Switch Workspace", path: `/workspaces/menu`, navName: "Workspace" },
            { name: "Sign out", path: "/" },
          ]);
        }
      } else {
        if (location.pathname.includes("workspaces/menu")) {
          setUserNavigation([
            { name: "Your Profile", path: `/update-user/${user?._id}`, navName: "Users" },
            { name: "Change Password", path: "/users/edit/me/change-password", navName: "Users" },
            { name: "Sign out", path: "/" },
          ]);
        } else {
          setUserNavigation([
            { name: "Your Profile", path: `/update-user/${user?._id}`, navName: "Users" },
            { name: "Change Password", path: "/users/edit/me/change-password", navName: "Users" },
            { name: "Switch Workspace", path: `/workspaces/menu`, navName: "Workspace" },
            { name: "Sign out", path: "/" },
          ]);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?._id, location.pathname]);

  const removePreviewUser = () => {
    asyncLocalStorage.removeItem("impersonatedUserId");
    navigate("/users");
  };

  useEffect(() => {
    let impersonatedUserId = localStorage.getItem("impersonatedUserId");
    if (impersonatedUserId) {
      setHasImpersonatedUserId(true);
    } else {
      setHasImpersonatedUserId(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("impersonatedUserId")]);

  useEffect(() => {
    const ac = new AbortController();

    const loadMenuData = async () => {
      try {
        await getMenuData({}, ac.signal);
        setLoadedMenu(true);
      } catch (error) {
        // console.dir(error.message);
      }
    };

    loadMenuData();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("impersonatedUserId")]);

  useEffect(() => {
    if (loadedMenu && !user) {
      navigate("/login");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      <FeatureFlagsProvider>
        <div className="flex h-full flex-col">
          <Transition.Root
            show={sidebarOpenMobile}
            as={Fragment}>
            <Dialog
              as="div"
              className="relative z-40 lg:hidden"
              onClose={setSidebarOpenMobile}>
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
              </Transition.Child>

              <div className="fixed inset-0 z-40 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full">
                  <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-leftNavColor pb-4">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0">
                      <div className="absolute top-0 right-0 -mr-12 pt-2">
                        <button
                          type="button"
                          className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:border-highlightColor focus:ring-0"
                          onClick={() => setSidebarOpenMobile(false)}>
                          <span className="sr-only">Close Side Bar</span>
                          <XMarkIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="bg-headerColor pl-4">
                      <Logo />
                    </div>
                    <div className="mt-5 h-0 flex-1 overflow-y-auto">
                      <nav className="space-y-1 px-2">
                        {pageList.map((item) => (
                          <Fragment key={item.key}>
                            <NavItem
                              item={item}
                              activeItem={currentNavigation}
                              onClick={updateNavigation}
                            />
                          </Fragment>
                        ))}
                      </nav>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
                <div
                  className="w-14 flex-shrink-0"
                  aria-hidden="true">
                  {/* Dummy element to force leftNavColor to shrink to fit close icon */}
                </div>
              </div>
            </Dialog>
          </Transition.Root>
          <div className={classNames("flex w-full transition-all duration-150", sidebarOpen ? "lg:pl-0" : "")}>
            <div className="fixed top-0 z-30 flex h-16 w-full flex-shrink-0 bg-headerColor shadow-md">
              <Logo>
                <button
                  type="button"
                  className="px-4 text-headerTextColor focus:border-highlightColor focus:ring-0 lg:hidden"
                  onClick={() => setSidebarOpenMobile(true)}>
                  <span className="sr-only">Open left navigation</span>
                  <Bars3BottomLeftIcon
                    className="h-6 w-6 text-headerTextColor"
                    aria-hidden="true"
                  />
                </button>
                <button
                  type="button"
                  className="hidden border-0 px-4 text-gray-500 focus:border-headerTextColor focus:ring-0 lg:flex"
                  onClick={() => setSidebarOpen(!sidebarOpen)}>
                  <span className="sr-only">Open leftNavColor</span>
                  <Bars3BottomLeftIcon
                    className="h-6 w-6 text-headerTextColor"
                    aria-hidden="true"
                  />
                </button>
              </Logo>
              <div className="flex w-full items-center justify-end pr-6">
                <div className="ml-4 flex items-center lg:ml-6">
                  <div className="hidden sm:flex w-22 m-0 h-6 items-center text-sm font-medium text-headerTextColor">{user?.name ? user?.name : null}</div>

                  {/* Profile dropdown */}
                  <Menu
                    as="div"
                    className="flex items-center">
                    <Menu.Button className="ml-3 h-8 w-8 overflow-hidden rounded-full">
                      <span className="sr-only">Open user menu</span>
                      <UserProfile user={user || {}} />
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95">
                      <Menu.Items className="absolute top-12 right-7 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <AdminNavDesktop userNavigation={userNavigation} />
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </div>
          {!Object.keys(workspaceDetails).length ? (
            <div className="relative flex h-full w-full">
              {/* Static leftNav for desktop */}
              <div className={classNames("fixed left-0 top-16 z-20 hidden h-[calc(100%-56px)] transition-all duration-150 lg:flex lg:w-64 lg:flex-col", sidebarOpen ? "left-0" : "-left-64")}>
                <nav className="flex h-full flex-col justify-between bg-leftNavColor px-2 py-4 shadow-md">
                  <div className="grid gap-y-1">
                    {pageList.map((item) => (
                      <Fragment key={item.key}>
                        <NavItem
                          item={item}
                          activeItem={currentNavigation}
                          onClick={updateNavigation}
                        />
                      </Fragment>
                    ))}
                  </div>
                  <NavigationPreviewBox
                    workspaceDetails={workspaceDetails}
                    rolePreviewModal={rolePreviewModal}
                    removePreviewUser={removePreviewUser}
                    user={user}
                    hasImpersonatedUserId={hasImpersonatedUserId}
                    setRolePreviewModal={setRolePreviewModal}
                  />
                </nav>
              </div>
              <div className={classNames("relative z-10 w-full pt-16 transition-all duration-100", sidebarOpen ? "lg:pl-64" : "lg:pl-0")}>
                <div className="w-full h-full">{children}</div>
              </div>
            </div>
          ) : (
            <>
              {workspaceDetails && workspaceDetails.layout_type === "LEFT_NAVIGATION" && (
                <div className="relative flex h-full w-full">
                  {/* Static leftNav for desktop */}
                  <div className={classNames("fixed left-0 top-16 z-20 hidden h-[calc(100%-56px)] transition-all duration-150 lg:flex lg:w-64 lg:flex-col", sidebarOpen ? "left-0" : "-left-64")}>
                    <nav className="flex h-full flex-col justify-between bg-leftNavColor px-2 py-4 shadow-md">
                      <div className="grid gap-y-1">
                        {pageList.map((item) => (
                          <Fragment key={item.key}>
                            {item.permissions.length > 0 ? (
                              <div className="relative">
                                <button
                                  onClick={() => {
                                    setPageList(
                                      pageList.map((page) => {
                                        if (page.key === item.key) {
                                          return { ...page, toggle: !item.toggle };
                                        } else {
                                          return page;
                                        }
                                      })
                                    );
                                  }}
                                  type="button"
                                  className="w-full flex items-center rounded-md px-2 py-2 transition-all duration-300">
                                  <div className="w-full text-left font-medium text-leftNavTextColor transition-all duration-300">{item.name}</div>
                                  <div className="w-4 h-8 min-w-[16px] flex justify-center items-center text-sm">
                                    <ChevronRightIcon className={classNames(!item.toggle ? "" : "rotate-90")} />
                                  </div>
                                </button>
                                <div className={"pl-3 max-h-[400px] overflow-auto scrollbar transition-all duration-300 space-y-2 " + (!item.toggle ? "h-0" : "")}>
                                  {item.permissions.map((permission) => (
                                    <Fragment key={permission.key}>
                                      <NavItem
                                        item={permission}
                                        activeItem={currentNavigation}
                                        onClick={updateNavigation}
                                      />
                                    </Fragment>
                                  ))}
                                </div>
                              </div>
                            ) : (
                              <NavItem
                                item={item}
                                activeItem={currentNavigation}
                                onClick={updateNavigation}
                              />
                            )}
                          </Fragment>
                        ))}
                      </div>
                      <NavigationPreviewBox
                        workspaceDetails={workspaceDetails}
                        rolePreviewModal={rolePreviewModal}
                        removePreviewUser={removePreviewUser}
                        user={user}
                        hasImpersonatedUserId={hasImpersonatedUserId}
                        setRolePreviewModal={setRolePreviewModal}
                      />
                    </nav>
                  </div>
                  <div className={classNames("relative z-10 w-full pt-16 transition-all duration-100", sidebarOpen ? "lg:pl-64" : "lg:pl-0")}>
                    <div className="w-full h-full bg-backgroundColor">{children}</div>
                  </div>
                </div>
              )}
              {workspaceDetails && workspaceDetails.layout_type === "TOP_NAVIGATION" && (
                <div className="relative z-40 pt-16 h-screen w-full">
                  {/* Static leftNav for desktop */}
                  <div className={classNames("z-20 relative transition-all duration-150 flex ", sidebarOpen ? "" : "")}>
                    <nav className="flex w-full items-center h-16 min-h-[64px] justify-between bg-leftNavColor px-2 py-4 shadow-md border-t-[2px] border-slate-100">
                      <div className="flex gap-x-1">
                        {pageList.map((item) => (
                          <Fragment key={item.key}>
                            {item.permissions.length > 0 ? (
                              <div className="relative">
                                <button
                                  onClick={() => {
                                    setPageList(
                                      pageList.map((page) => {
                                        if (page.key === item.key) {
                                          return { ...page, toggle: !item.toggle };
                                        } else {
                                          return { ...page, toggle: false };
                                        }
                                      })
                                    );
                                  }}
                                  type="button"
                                  className="w-full flex items-center gap-x-2 rounded-md px-2 py-2 transition-all duration-300">
                                  <div className="w-full text-left font-medium text-leftNavTextColor transition-all duration-300">{item.name}</div>
                                  <div className="ml-1 w-4 h-8 min-w-[16px] flex justify-center items-center text-sm">
                                    <ChevronRightIcon className="rotate-90 stroke-2" />
                                  </div>
                                </button>
                                <div
                                  className={
                                    "px-3 max-h-[400px] absolute top-100 left-0 z-10 bg-leftNavColor border-slate-100 rounded shadow-lg transition-all duration-200 min-w-[200px] space-y-2 " + (!item.toggle ? "h-0 overflow-hidden border-none" : "border-[1px] py-2 overflow-auto")
                                  }>
                                  {item.permissions.map((permission) => (
                                    <Fragment key={permission.key}>
                                      <NavItem
                                        item={permission}
                                        activeItem={currentNavigation}
                                        onClick={updateNavigation}
                                      />
                                    </Fragment>
                                  ))}
                                </div>
                              </div>
                            ) : (
                              <NavItem
                                item={item}
                                activeItem={currentNavigation}
                                onClick={updateNavigation}
                              />
                            )}
                          </Fragment>
                        ))}
                      </div>
                      {/* <NavItem item={{ name: "page data", path: "/pagedata" }} /> */}
                      <NavigationPreviewBox
                        workspaceDetails={workspaceDetails}
                        rolePreviewModal={rolePreviewModal}
                        removePreviewUser={removePreviewUser}
                        user={user}
                        hasImpersonatedUserId={hasImpersonatedUserId}
                        setRolePreviewModal={setRolePreviewModal}
                      />
                    </nav>
                  </div>
                  <div className={classNames("relative z-10 w-full h-screen max-h-[calc(100vh-120px)] transition-all duration-100", sidebarOpen ? "" : "")}>
                    <div
                      className="w-full h-full relative pl-0 py-2 flex justify-center transition-all bg-white"
                      id="content">
                      <div className="w-full lg:w-[1250px] lg:rounded-lg lg:border lg:border-slate-100 lg:overflow-hidden lg:shadow-lg bg-backgroundColor">{children}</div>
                    </div>
                  </div>
                </div>
              )}
              {workspaceDetails && workspaceDetails.layout_type === "CUSTOM_NAVIGATION" && (
                <div className="relative pt-16 h-screen w-full">
                  {/* Static leftNav for desktop */}
                  <div className={classNames("relative z-10 w-full h-screen max-h-[calc(100vh-12px)] transition-all duration-100", sidebarOpen ? "" : "")}>
                    <div className="w-full h-full bg-backgroundColor">{children}</div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </FeatureFlagsProvider>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    collapse: state.collapse,
    site: state.site,
    user: state.auth.user,
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, { getMenuData, logout, getDomoPageList, resetWorkspaceDetails })(UserLayout);
