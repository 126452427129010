import { fetchData } from "src/async/fetch";
import { apiUrl } from "src/config/host";
import { GET_SITE_SETTINGS_DATA, GET_AUTO_SITE_SETTINGS_DATA } from "src/actions/type";
import { objectToFormData } from "src/helpers";

export const handleUpdateDomoData = async (type = "pull") => {
  try {
    const res = await fetchData("POST", `${apiUrl}/update-domo-dataset/${type}`);
    const resData = await res.json();

    if (resData.status === 200) {
      return resData.message;
    } else {
      throw new Error(resData.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const manageAutoSiteSettings = (body) => (dispatch) => {
  dispatch({ type: GET_AUTO_SITE_SETTINGS_DATA, payload: body });
};

export const manageDevToDomo = async (body) => {
  try {
    const res = await fetchData("POST", `${apiUrl}/create-portal`, body);
    const data = await res.json();

    if (data.status === 200) {
      return data;
    } else {
      throw new Error(res.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const manageSiteSettings = (body) => async (dispatch) => {
  try {
    const siteSettingsDataResponse = await fetchData("PUT", `${apiUrl}/site-settings`, objectToFormData(body), null, {}, "multipart/form-data");
    const { data } = siteSettingsDataResponse;
    // const data = await siteSettingsDataResponse.json();

    if (data.status === 200) {
      dispatch({ type: GET_SITE_SETTINGS_DATA, payload: data.data });
      localStorage.setItem("siteSettings", JSON.stringify(data.data));
      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getSiteSettingsCache = () => (dispatch) => {
  const checkData = localStorage.getItem("siteSettings");

  if (checkData) {
    const cacheData = JSON.parse(localStorage.getItem("siteSettings"));
    dispatch({ type: GET_SITE_SETTINGS_DATA, payload: cacheData });
  }
};

export const getOpenSiteSettings =
  (body, signal = null) =>
    async (dispatch) => {
      try {
        const res = await fetchData("GET", `${apiUrl}/site-settings/unprotected`, body, signal);
        const data = await res.json();

        if (data.status === 200) {
          dispatch({ type: GET_SITE_SETTINGS_DATA, payload: data.data });
          return data.data;
        } else {
          throw new Error(data.message);
        }
      } catch (error) {
        throw new Error(error.message);
      }
    };

export const getSiteSettings =
  (body, signal = null) =>
    async (dispatch) => {
      try {
        const res = await fetchData("GET", `${apiUrl}/site-settings`, body, signal);

        const data = await res.json();

        if (data.status === 200) {
          dispatch({ type: GET_SITE_SETTINGS_DATA, payload: data.data });
          localStorage.setItem("siteSettings", JSON.stringify(data.data));
          return data.data;
        } else {
          throw new Error(data.message);
        }
      } catch (error) {
        throw new Error(error.message);
      }
    };

export const pushDataPotal = async (body = null, signal, headers, url) => {
  try {
    const res = await fetchData("PUT", url, body, signal, headers);
    const data = await res.json();

    if (!data?.access_token) {
      throw new Error(JSON.stringify(data));
    } else {
      return data;
    }
  } catch (error) {
    return { status: 400, message: error.message };
  }
};

export const createDataset = async (body = null, signal, headers, url) => {
  try {
    const res = await fetchData("POST", url, body, signal, headers);
    const data = await res.json();

    if (!data?.access_token) {
      throw new Error(JSON.stringify(data));
    } else {
      return data;
    }
  } catch (error) {
    return error.message;
  }
};

export const getDatasetQuery = async (body, signal, headers, url) => {
  try {
    const res = await fetchData("POST", url, body, signal, headers);
    const data = await res.json();

    if (!data?.datasource) {
      throw new Error(`Error: ${JSON.stringify(data)}`);
    } else {
      // dispatch({ type: GET_DATASET_QUERY, payload: JSON.stringify(data) });
      return data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getClientData = async (body, signal) => {
  try {
    const res = await fetchData("POST", `${apiUrl}/site-settings/decrypt`, body, signal);
    const resData = await res.json();

    if (resData.status !== 200) {
      throw new Error(resData.message);
    } else {
      return resData.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};
