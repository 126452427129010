import { useEffect, useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { motion, useIsPresent } from "framer-motion";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Select from "src/components/Shared/Forms/Selects/Select";
import { classNames } from "src/helpers/classNames";

export default function FilterRow({ viewOnly = false, trustedAttributeArr, valueType, disabled = false, usesTrustedValue = false, operators = [], pageType = "EMBED_PAGE", count, i, state, index, removeFilter = () => {}, setState = () => {} }) {
  let isPresent = useIsPresent();
  const [valueTypeOption, setValueTypeOption] = useState(valueType);
  const [operatorId, setOperatorId] = useState({ _id: -1, name: "" });
  const [valueTypeId, setValueTypeId] = useState({ _id: "value", name: "Text Value" });
  const [trustedAttributeId, setTrustedAttributeId] = useState({ _id: -1, name: "" });

  useEffect(() => {
    const index = valueType.findIndex((item) => item._id === count.value_type);
    if (index !== -1) {
      setValueTypeId(valueType[index]);
      setValueTypeOption(valueType.filter((v) => v._id !== count.value_type));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count.value_type]);

  useEffect(() => {
    const index = operators.findIndex((item) => item._id === count.operator_id);
    if (index !== -1) {
      setOperatorId(operators[index]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count.operator_id]);

  useEffect(() => {
    const index = trustedAttributeArr.findIndex((item) => item._id === count.trusted_attribute);
    if (index !== -1) {
      setTrustedAttributeId(trustedAttributeArr[index]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count.trusted_attribute]);

  let generateStyle = () => {
    if (isPresent) {
      return {
        position: "relative",
      };
    } else {
      return {
        position: "absolute",
        display: "flex",
        alignItems: "center",
      };
    }
  };

  return (
    <motion.div
      key={i}
      className="relative w-full flex gap-x-3 mr-8 sm:pl-24 pr-6"
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{
        opacity: 0,
      }}
      transition={{ opacity: { duration: 0.2 } }}
      style={generateStyle}>
      {pageType === "CUSTOM_HTML" && <div className="absolute w-full h-full top-0 left-0 z-10 cursor-not-allowed opacity-60 bg-white"></div>}
      {/* Column name */}
      <div className="w-1/2">
        <Input
          disabled={disabled || viewOnly}
          name="column-name"
          label="Column"
          value={count.column_name || ""}
          onChange={(e) => {
            setState(
              state.map((st, k) => {
                if (k === index) {
                  return {
                    ...state[k],
                    filters: state[index].filters.map((filter, j) => {
                      if (j === i) {
                        return { ...filter, column_name: e.target.value };
                      } else {
                        return filter;
                      }
                    }),
                  };
                } else {
                  return st;
                }
              })
            );
          }}
        />
      </div>
      {/* Operator */}
      <div className="w-[110px]">
        <Select
          disabled={disabled || viewOnly}
          label="Operator"
          options={operators}
          selected={operatorId}
          setSelected={(e) => {
            setState(
              state.map((st, k) => {
                if (k === index) {
                  return {
                    ...state[k],
                    filters: state[index].filters.map((filter, j) => {
                      if (j === i) {
                        return { ...filter, operator_id: e._id };
                      } else {
                        return filter;
                      }
                    }),
                  };
                } else {
                  return st;
                }
              })
            );
          }}
        />
      </div>

      <div className="w-1/2">
        <Select
          options={valueTypeOption}
          selected={valueTypeId}
          selectType={"label"}
          setSelected={(e) => {
            setState(
              state.map((st, k) => {
                if (k === index) {
                  return {
                    ...state[k],
                    filters: state[index].filters.map((filter, j) => {
                      if (j === i) {
                        return { ...filter, value_type: e._id, column_value: "", trusted_attribute: "" };
                      } else {
                        return filter;
                      }
                    }),
                  };
                } else {
                  return st;
                }
              })
            );
            setTrustedAttributeId({ _id: -1, name: "" });
          }}
        />
        {count?.value_type === "attribute" ? (
          <Select
            disabled={disabled || viewOnly}
            options={trustedAttributeArr}
            selected={trustedAttributeId}
            setSelected={(e) => {
              setState(
                state.map((st, k) => {
                  if (k === index) {
                    return {
                      ...state[k],
                      filters: state[index].filters.map((filter, j) => {
                        if (j === i) {
                          return { ...filter, trusted_attribute: e._id };
                        } else {
                          return filter;
                        }
                      }),
                    };
                  } else {
                    return st;
                  }
                })
              );
            }}
          />
        ) : (
          <Input
            disabled={disabled || viewOnly}
            name="column-value"
            // label={index === 0 ? "Column value" : ""}
            label=""
            value={count.column_value || ""}
            onChange={(e) => {
              setState(
                state.map((st, k) => {
                  if (k === index) {
                    return {
                      ...state[k],
                      filters: state[index].filters.map((filter, j) => {
                        if (j === i) {
                          return { ...filter, column_value: e.target.value };
                        } else {
                          return filter;
                        }
                      }),
                    };
                  } else {
                    return st;
                  }
                })
              );
            }}
          />
        )}
      </div>

      <div className="flex items-end">
        <button
          type="button"
          className={classNames("h-[34px] rounded-md text-gray-400 hover:text-gray-500 focus:outline-none", i === 0 || viewOnly ? "opacity-0" : "")}
          disabled={i === 0 || viewOnly}
          onClick={(e) => i > removeFilter(i)}>
          <span className="sr-only">Close</span>
          <XMarkIcon
            className="h-6 w-6"
            aria-hidden="true"
          />
        </button>
      </div>
    </motion.div>
  );
}
