import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { classNames } from "src/helpers/classNames";

const Accordion = ({ children, headerContent, startOpen = false, isCollapsible = true }) => {
  const [show, setShow] = useState(startOpen);

  return (
    <div className={classNames("w-full relative rounded-xl shadow-sm border transition-all duration-200", show ? "border-gray-200" : "border-gray-100")}>
      <div className={classNames("flex items-center rounded-t-xl py-2.5 px-3 gap-4 transition-all duration-200 rounded-xl", show ? "bg-white" : "bg-white")}>
        {isCollapsible?(
          <button
          type="button"
          onClick={() => setShow(!show)}
          className="h-10 w-10 flex items-center justify-center py-1 px-1 rounded-md border-0 text-gray-700 outline-none ring-0 ring-transparent shadow-none bg-transparent hover:bg-slate-100">
          <ChevronDownIcon
            className={classNames("h-5 w-5 stroke-2 transition-all duration-200 ", show ? "" : "-rotate-90")}
            aria-hidden="true"
          />
        </button>
        ):(
          <div className="h-10 w-10"></div>
        )}
        
        {headerContent()}
      </div>
      <div className={classNames("relative transition-all ease-in-out duration-200", show ? "max-h-[1000px]" : "max-h-0 overflow-hidden")}>{children}</div>
    </div>
  );
};

export default Accordion;
