import React from 'react';
import { DocumentDuplicateIcon, ArrowTopRightOnSquareIcon, ArrowPathIcon } from "@heroicons/react/24/outline";

const DnsConfigItem = ({data}) => {
  return (
    <>
      <div className="relative border border-gray-200 rounded-xl px-4 bg-white divide-y divide-gray-200">
        <div className="flex items-center gap-4 py-5">
          <div className="flex items-center gap-2 text-gray-400 font-medium w-28">{data.urlLabel}</div>
          <div className="relative w-full flex items-center max-w-md gap-5">
            <input
              type={"text"}
              value={data.url}
              className="border-0 font-semibold w-full !ring-0 bg-gray-200/40 rounded py-0 px-3 pr-20 h-10"
              onChange={() => { }}
            />
            <button
              type="button"
              className="w-8 h-8 absolute top-1 right-10 z-10 bg-white rounded shadow-sm border border-gray-200 text-gray-500 flex items-center justify-center">
              <ArrowTopRightOnSquareIcon className="w-5 h-5 stroke-2" />
            </button>
            <button
              type="button"
              className="w-8 h-8 absolute top-1 right-1 z-10 bg-white rounded shadow-sm border border-gray-200 text-gray-500 flex items-center justify-center">
              <DocumentDuplicateIcon className="w-5 h-5 stroke-2" />
            </button>
          </div>
          <div className="relative">
            <button
              type="button"
              className="text-gray-500 flex items-center justify-center h-10 py-0 px-2 underline font-semibold">
              Edit
            </button>
          </div>
        </div>
        <div className="flex items-center gap-4 py-5">
          <div className="flex items-center gap-2 text-gray-400 font-medium w-28">{data.dnsLabel}</div>
          <div className="relative w-full flex items-center max-w-md gap-5">
            <input
              type={"text"}
              value={data.dns}
              className="border-0 font-semibold w-full !ring-0 bg-gray-200/40 rounded py-0 px-3 pr-20 h-10"
              onChange={() => { }}
            />
            <button
              type="button"
              className="w-8 h-8 absolute top-1 right-1 z-10 bg-white rounded shadow-sm border border-gray-200 text-gray-500 flex items-center justify-center">
              <DocumentDuplicateIcon className="w-5 h-5 stroke-2" />
            </button>
          </div>
          <div className="relative">
            <button
              type="button"
              className="text-gray-400 flex items-center justify-center h-10 py-0 px-2 underline font-semibold">
              <ArrowPathIcon className="w-5 h-5 stroke-2" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DnsConfigItem;