import { IdentificationIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteSso } from "src/actions/sso";
import Button from "src/components/Shared/Buttons/Button";
import IconsWithPlus from "src/components/Shared/Icons/IconsWithPlus";
import Modal from "src/components/Shared/Modal";
import PaginationFooter from "src/components/Shared/PaginationFooter";
import Table from "src/components/Shared/Table/Table";
import TRHeader from "src/components/Shared/Table/TRHeader";
import TableOptions from "src/components/Shared/TableOptions";
import SSOEntry from "src/components/SSO/SSOEntry";
import Search from "src/components/Shared/Search";
import useFetch from "src/hooks/useFetch";

const SSOList = ({ sso, ...props }) => {
  const [deleteId, setDeleteId] = useState(null);
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [isDeleteDisable, setIsDeleteDisable] = useState(false);

  const navigate = useNavigate();

  const {
    response: { data: ssos, meta },
    status: { done: ssosLoaded },
    refreshData: refreshSsos,
  } = useFetch("/sso/list", { query: { limit: limit, offset: offset, keyword: keyword } });

  const handlePaginationChange = ({ limit, offset }) => {
    setLimit(limit);
    setOffset(offset);
  };

  useEffect(() => {
    refreshSsos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset]);

  useEffect(() => {
    handlePaginationChange(10, 0);
    refreshSsos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  const removeSSO = async (e) => {
    e.preventDefault();

    try {
      setIsDeleteDisable(true);
      const message = await props.deleteSso(deleteId);
      setDeleteId(null);
      refreshSsos();
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsDeleteDisable(false);
    }
  };

  return (
    <>
      <TableOptions
        leftContent={
          <Search
            keyword={keyword}
            setKeyword={(val) => {
              setOffset(0)
              setKeyword(val)
            }}
          />
        }
        rightContent={
          <Button
            version="secondary"
            onClick={() => navigate("/sso/add")}>
            <IconsWithPlus
              strokeColor={"stroke-highlightColor"}
              item={{ icon: IdentificationIcon }}
            />
          </Button>
        }></TableOptions>
      <Modal
        title="SSO"
        secondaryTitle="Delete"
        isOpen={!!deleteId}
        onCancel={() => setDeleteId(null)}
        onSuccess={removeSSO}
        isLoading={isDeleteDisable}
        defaultOptions={{
          onSuccessButtonText: "Delete",
        }}>
        <div className="grid gap-y-8 whitespace-nowrap text-sm text-gray-500">Are you sure you want to delete {ssos?.find((item) => item._id === deleteId)?.provider_name}?</div>
      </Modal>
      <Table
        tableHeader={
          <TRHeader>
            <th
              scope="col"
              className="rounded-l-xl py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
              Identity Provider
            </th>
            <th
              scope="col"
              className="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              IDP Entity ID OR Issuer
            </th>
            <th
              scope="col"
              className="hidden lg:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              SAML login URL
            </th>
            <th
              scope="col"
              className="hidden lg:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Status
            </th>
            <th
              scope="col"
              className="rounded-r-xl relative py-3.5 pl-3 pr-4 sm:pr-6">
              <span className="sr-only">Edit</span>
            </th>
          </TRHeader>
        }
        colSpan="5"
        setDeleteId={setDeleteId}
        deleteId={deleteId}
        loaded={ssosLoaded}
        dataExists={ssos?.length > 0}>
        {ssos.map((item) => (
          <SSOEntry
            key={item._id}
            sso={item}
            deleteId={deleteId}
            setDeleteId={setDeleteId}
            onChange={refreshSsos}
          />
        ))}
      </Table>
      <PaginationFooter
        itemName="SSO provider"
        limit={limit}
        offset={offset}
        count={meta?.count}
        onChange={handlePaginationChange}
      />
    </>
  );
};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, { deleteSso })(SSOList);
