import React, { useEffect, useState } from "react";
import UserProfile from "src/components/Users/UserProfile";

const Members = ({ group, type, className = "" }) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (group.users?.length > 0) {
      setUsers(group.users.slice(0, 3));
    } else {
      setUsers([]);
    }
  }, [group?.users]);

  return (
    <>
      <div className={`w-36 flex items-center justify-center ${className}`}>
        <div className="flex items-center -space-x-2">
          {users &&
            users.map((user, index) => {
              return (
                <div
                  key={"" + group?._id + index + user?._id}
                  className="w-6 h-6 overflow-hidden rounded-full z-20 ring-2 ring-white ring-offset-0">
                  <UserProfile
                    user={user}
                    size="sm"
                  />
                </div>
              );
            })}
        </div>
        <div className="text-sm text-slate-400 font-medium flex items-center justify-center">
          {group.users?.length > 0 ? (
            group.users?.length > 3 ? (
              <div className="ml-2">
                +{group.users.length - 3} other{group.users.length - 3 > 1 ? "s" : ""}
              </div>
            ) : (
              <div className="ml-2">{group.users?.length + ` ${type || "user"}${group.users?.length > 1 ? "s" : ""}`}</div>
            )
          ) : (
            `No ${type || "user"}s`
          )}
        </div>
      </div>
    </>
  );
};

export default Members;
