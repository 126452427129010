import React from "react";
import { classNames } from "src/helpers/classNames";

export default function ToggleHeader({ title = "", subtitle = "", children, position = "right" }) {
  return (
    <div className="font-medium text-gray-600 mb-4 flex">
      <div className={classNames("flex gap-x-5 items-center", position === "right" ? "" : "flex-row-reverse")}>
        <div>
          <p>{title}</p>
          <p className="text-gray-400 text-sm">{subtitle}</p>
        </div>
        {children}
      </div>
    </div>
  );
}
