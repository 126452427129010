import React, { useState } from "react";
import { utils, writeFile } from "xlsx";
import ButtonWithDropdown from "src/components/Shared/Buttons/ButtonWithDropdown";

const DataExport = ({ title = "Report", headings = [], prepData = () => {} }) => {
  const [exportType, setExportType] = useState("xls");

  const exportData = async () => {
    let data = await prepData();
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, [headings]);
    utils.sheet_add_json(ws, data, { origin: "A2", skipHeader: true });
    utils.book_append_sheet(wb, ws, "Report");
    writeFile(wb, title + (exportType === "xls" ? ".xls" : ".csv"));
  };

  return (
    <div className="relative flex justify-end mt-5">
      <ButtonWithDropdown
        onSubmit={exportData}
        buttonText="Export"
        options={[
          { key: ".xls", value: "xls" },
          { key: ".csv", value: "csv" },
        ]}
        setOption={(option) => {
          setExportType(option.value);
        }}
      />
    </div>
  );
};

export default DataExport;
