import "flatpickr/dist/themes/light.css";
import { useEffect, useRef, useState } from "react";
import Flatpickr from "react-flatpickr";
function DateSearch({ setStateDate, setEndDate }) {
  const [flatdate, setDate] = useState(null);

  const fp = useRef(null);
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  const enddate = new Date();
  enddate.setHours(23);
  enddate.setMinutes(59);

  useEffect(() => {
    if (flatdate?.e && flatdate?.e[0] && flatdate?.e[1]) {
      let first = flatdate?.e[0] ? new Date(flatdate?.e[0]).toISOString() : "";
      let last = flatdate?.e[1] ? new Date(flatdate?.e[1]).toISOString() : "";

      setStateDate(first);
      setEndDate(last);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flatdate?.e[0] && flatdate?.e[1]]);

  return (
      <div className="hidden gap-x-1 md:flex">
        <div className="flex w-full sm:w-[290px] xl:w-[420px]">
          <label
            htmlFor="search"
            className="sr-only">
            Search
          </label>
          <div className="relative flex w-full rounded-md shadow-sm">
            <Flatpickr
              ref={fp}
              className="h-10 w-full rounded-md border-gray-300 text-sm focus:border-highlightColor focus:ring-0"
              placeholder="Select dates"
              onChange={(e) => {
                setDate({ e });
              }}
              options={{
                altInput: true,
                altFormat: "F j, Y",
                mode: "range",
                dateFormat: "d.m.y",
              }}
            />
          </div>
        </div>
      </div>
  );
}

export default DateSearch;
