import Section from "src/components/Shared/Containers/Section";
import { H3 } from "src/components/Shared/Text/Headers";
import UserImportDataset from "src/components/Site/UserImportDataset";
import BulkUpload from "src/components/Users/Add/ImportUsers/BulkUpload";

const ImportUsers = () => {
  return (
    <div className="w-full grid gap-y-6">
      <Section>
        <div className="grid gap-y-10">
          <div className="w-full">
            <H3 caption="Upload users's from a locally stored CSV file.">Bulk Upload</H3>
            <BulkUpload />
          </div>
          <hr className="w-full" />
          <div className="w-full relative">
            <H3 caption="Manage all users from one DOMO dataset in the cloud.">DOMO Dataset Upload</H3>
            <UserImportDataset />
          </div>
        </div>
      </Section>
    </div>
  );
};

export default ImportUsers;
