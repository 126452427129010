import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import React from "react";

export default function InformationHoverIcon({ location = "-top-3.5 left-0", children }) {
  return (
    <div className={`text-gray-600 group absolute ${location} cursor-pointer`}>
      <QuestionMarkCircleIcon className="h-5 w-5" />
      <div className="py-3 px-3.5 font-light hidden absolute -top-3.5 left-6 opacity-20 group-hover:opacity-100 group-hover:block w-[300px] z-40 border-gray-100 border bg-white rounded-md shadow-md">{children}</div>
    </div>
  );
}
