import { ArrowPathIcon, ChevronDownIcon, EllipsisVerticalIcon, PlusIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import Button from "src/components/Shared/Buttons/Button";
import Select from "src/components/Shared/Forms/Selects/Select";
import { classNames } from "src/helpers/classNames";
import { operatorNameConverter } from "src/helpers/operators/operatorNameConverter";
import useFetch from "src/hooks/useFetch";
import FilterRow from "./FilterRow";

const AddPermissionForm = ({ viewOnly = false, isGroup, pages, isDefault, disabled = false, updateDefaultKey, state, setState = () => { }, index, draggableProps, dragHandleProps, innerRef, onRemove = () => {}, ...props }) => {
  const {
    response: { data: operators },
    status: { done: operatorsLoaded },
  } = useFetch("/operators/list");

  const filterJson = {
    column_name: "",
    operator_id: null,
    value_type: "value",
    column_value: "",
    trusted_attribute: "",
  };
  const permissionJson = {
    dashboard_id: null,
    filters: [filterJson],
  };
  const [pageId, setPageId] = useState({ _id: -1, name: "" });
  const [pageType, setPageType] = useState("");
  const [isHideFilter, setIsHideFilter] = useState(true);
  const trustedAttributeArr = [
    {
      _id: "user.fullName",
      value: "user.fullName",
      name: "Full Name",
    },
    {
      _id: "user.email",
      value: "user.email",
      name: "Email Address",
    },
  ];

  const valueType = [
    {
      _id: "value",
      value: "value",
      name: "Text Value",
    },
    {
      _id: "attribute",
      value: "attribute",
      name: "User Attribute",
    },
  ];

  const embedTypes = {
    DOMO: "DOMO Page",
    EMBED_CARD: "DOMO Card",
    CUSTOM_HTML: "Custom HTML",
    // LANDING_PAGE: "Landing Page",
  };

  useEffect(() => {
    if (state[index] && state[index].page_id) {
      const pageIndex = pages.findIndex((page) => page._id === state[index].page_id);
      if (pageIndex !== -1) {
        updateForm(pages[pageIndex], "page_id", setPageId);
      }
    } else {
      setPageId({});
      setPageType("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeForm = (e) => {
    setState(state.filter((flt, i) => i !== index));
    onRemove(index);
  };

  const clearForm = (e) => {
    setState(
      state.map((st, i) => {
        if (i === index) {
          return permissionJson;
        } else {
          return st;
        }
      })
    );
  };

  const updateForm = (e, name, next) => {
    if (name === "page_id") {
      setPageType(e.page_type);
    }
    // eslint-disable-next-line eqeqeq
    next({ _id: e._id, name: e.name });
    if (e._id !== -1) {
      handleState(name, e._id || e._id);
    } else {
      handleState(name, "");
    }
  };

  const handleState = (name, value) => {
    setState(
      state.map((st, i) => {
        if (i === index) {
          return { ...state[i], [name]: value };
        } else {
          return st;
        }
      })
    );
  };

  const addNewFilter = (e) => {
    e.preventDefault();
    setState(
      state.map((st, i) => {
        if (i === index) {
          return { ...state[i], filters: [...state[i].filters, filterJson] };
        } else {
          return st;
        }
      })
    );
  };

  const removeFilter = (e) => {
    setState(
      state.map((st, i) => {
        if (i === index) {
          return { ...state[i], filters: state[i].filters.filter((flt, j) => j !== e) };
        } else {
          return st;
        }
      })
    );
  };

  return (
    <div
      ref={innerRef}
      {...draggableProps}
      className={"relative px-4 rounded-md border-invisible transition-colors duration-300 group bg-white/90" + (isGroup ? " !top-auto !left-auto" : "")}>
      {!disabled && !viewOnly && (
        <p
          onMouseDownCapture={() => setIsHideFilter(true)}
          {...dragHandleProps}
          className="h-[35px] w-[12px] mt-1 flex items-center justify-center sm:group-hover:flex hover:bg-gray-100 transition-colors duration-300 hover:text-gray-900 absolute z-30 left-0 top-5 rounded-sm text-gray-700">
          <EllipsisVerticalIcon className="absolute text-gray-400 h-5 w-5" />
        </p>
      )}
      <div className="flex w-full items-end relative gap-y-4 rounded-md px-2 py-0">
        <div className="w-full flex gap-y-4 lg:mr-[8px] gap-x-4 items-end justify-between">
          <div className="flex w-2/3 items-end gap-x-3">
            <div className="flex w-full md:w-2/3">
              {/* Page */}
              <div className="w-full">
                <Select
                  disabled={disabled || viewOnly}
                  secondaryLabel={embedTypes[pageType] || "Nothing selected"}
                  options={pages}
                  selected={pageId}
                  setSelected={(e) => updateForm(e, "page_id", setPageId)}
                />
              </div>
            </div>
            {!disabled && !viewOnly && (
              <div className="h-full items-end gap-x-3 hidden sm:flex">
                <button
                  type="button"
                  className="h-[34px] rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                  onClick={clearForm}>
                  <span className="sr-only">Clear</span>
                  <ArrowPathIcon
                    className="h-6 w-6"
                    aria-hidden="true"
                  />
                </button>
                <button
                  type="button"
                  className="h-[34px] rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                  onClick={removeForm}>
                  <span className="sr-only">Close</span>
                  <XMarkIcon
                    className="h-6 w-6"
                    aria-hidden="true"
                  />
                </button>
              </div>
            )}
          </div>
          {pageType !== "CUSTOM_HTML" &&
            pageType !== "LANDING_PAGE" &&
            (state[index]?.filters && state[index]?.filters.length > 0 ? (
              <div className="h-[34px]">
                <button
                  onClick={() => setIsHideFilter(!isHideFilter)}
                  type="button"
                  className="h-[34px] inline-flex items-center justify-center rounded-md px-4 py-2 text-sm font-medium focus:ring-0 text-gray-500 border-gray-300 hover:text-highlightColor transition-all duration-75 min-w-[140px] gap-x-2">
                  <div className="flex items-center justify-center">
                    {isHideFilter ? "Show" : "Hide"} Filters ({state[index]?.filters.length})
                  </div>
                  <ChevronDownIcon
                    className={classNames("h-4 w-4 transition-all duration-150", isHideFilter ? "-rotate-90" : "")}
                    aria-hidden="true"
                  />
                </button>
              </div>
            ) : (
              !disabled &&
              !viewOnly && (
                <Button
                  version="gray"
                  onClick={addNewFilter}>
                  <PlusIcon className="h-4 w-4" />
                  Add condition
                </Button>
              )
            ))}
        </div>
      </div>
      <div>
        <div className={classNames("px-2 lg:max-w-[1200px] duration-300", !isHideFilter ? "max-h-[2000px]" : "max-h-[0px]")}>
          <div className="mt-4 flex flex-col gap-y-2 relative">
            <AnimatePresence>
              {!isHideFilter && pageType !== "CUSTOM_HTML" && pageType !== "LANDING_PAGE" && state[index]?.filters && state[index]?.filters.length > 0 ? (
                <>
                  {state[index]?.filters.map((count, i) => (
                    <FilterRow
                      disabled={disabled}
                      viewOnly={viewOnly}
                      key={"filter_" + i + "_" + props.key}
                      pageType={pageType}
                      trustedAttributeArr={trustedAttributeArr}
                      valueType={valueType}
                      operators={
                        operatorsLoaded
                          ? operators?.map((operator) => {
                            return { ...operator, name: operatorNameConverter(operator.name), id: operator._id };
                          })
                          : []
                      }
                      count={count}
                      i={i}
                      state={state}
                      index={index}
                      removeFilter={removeFilter}
                      setState={setState}
                    />
                  ))}
                  <div className="mt-4 mb-5 flex items-center sm:pl-24 gap-x-3">
                    {!disabled && !viewOnly && (
                      <Button
                        version="gray"
                        onClick={addNewFilter}>
                        <PlusIcon className="h-4 w-4" />
                        Add condition
                      </Button>
                    )}
                    <div></div>
                  </div>
                </>
              ) : (
                ""
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPermissionForm;
