import userImage from "src/assets/img/user-default.png";
import Input from "src/components/Shared/Forms/Inputs/Input";

const ManualInvite = ({ userData }) => {
  return (
    <div className="relative py-4 px-4 bg-white border border-gray-200 rounded-xl">
      <div className="flex gap-5 py-5">
        <div className="w-32 min-w-[128px]">
          <div className="text-gray-600 font-semibold text-base">General Information</div>
        </div>
        <div className="w-full max-w-md">
          <div className="mb-4">
            <div className="relative">
              <label className="flex justify-between text-sm font-medium text-gray-700">Profile Picture</label>
              <div className="flex items-center gap-5">
                <div className="w-14 h-14 min-w-[56px] min-h-[56px] overflow-hidden rounded-full">
                  <img
                    className="w-full h-full object-cover"
                    src={userImage}
                    alt=""
                  />
                </div>
                <div className="relative overflow-hidden rounded-md shadow-sm">
                  <input
                    type={"file"}
                    className="absolute top-0 right-0 w-96 h-full opacity-0 z-10 cursor-pointer"
                  />
                  <div className="overflow-hidden rounded-md h-10 px-4 border border-gray-200 bg-white text-gray-500 font-medium flex items-center justify-center ">Upload</div>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-4">
            <Input
              label={"Full Name"}
              name={"fname"}
              type={"text"}
              placeholder={"Enter Full Name"}
            />
          </div>
          <div className="mb-4">
            <Input
              label={"Email"}
              name={"email"}
              type={"text"}
              placeholder={"Enter Email Address"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManualInvite;
