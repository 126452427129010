import React from "react";
import Tabs from "src/components/Shared/Tabs/Tabs";
import Accordion from "src/components/Shared/Accordions/Accordion";
import GroupContent from "src/components/Shared/MultiColumnLayout/TabContent/GroupContent";
import EditorPermission from "src/components/Shared/MultiColumnLayout/TabContent/EditorPermission";

const WorkspaceItem = ({ workspace = {} }) => {
  let tabs = [
    { name: "Groups", component: <GroupContent /> },
    { name: "Editor Permissions", component: <EditorPermission /> },
  ];
  return (
    <Accordion
      headerContent={() => (
        <div className="flex items-center gap-10">
          <div className="flex items-center gap-4">
            <div className="w-10 h-8 relative overflow-hidden rounded">
              <img
                className="w-full h-full object-cover"
                src="https://blog.logomyway.com/wp-content/uploads/2017/01/mcdonalds-logo-1.jpg"
                alt=""
              />
            </div>
            <div className="font-semibold text-gray-800 text-lg">{workspace.name}</div>
          </div>
          <div className="flex flex-wrap gap-2">
            {workspace.tags.map((tag, index) => (
              <div
                key={index}
                className="text-sm font-medium h-6 flex items-center justify-center px-2 bg-gray-200/50 rounded text-gray-500">
                {tag}
              </div>
            ))}
          </div>
        </div>
      )}>
      <div className="relative py-2">
        <Tabs
          newDesign={true}
          tabs={tabs}
        />
      </div>
    </Accordion>
  );
};

export default WorkspaceItem;
