export const COLLAPSE_ON = "COLLAPSE_ON";
export const COLLAPSE_OFF = "COLLAPSE_OFF";

export const LOGGED_IN = "LOGGED_IN";
export const LOG_OUT = "LOG_OUT";

export const GET_FLAG_DATA = "GET_FLAG_DATA";
export const EDIT_FLAG = "EDIT_FLAG";

export const GET_MENU_DATA = "GET_MENU_DATA";

export const GET_SITE_SETTINGS_DATA = "GET_SITE_SETTINGS_DATA";
export const GET_AUTO_SITE_SETTINGS_DATA = "GET_AUTO_SITE_SETTINGS_DATA";
export const GET_USERS = "GET_USERS";
export const GET_USER = "GET_USER";
export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const CLEAR_USER_DETAILS = "CLEAR_USER_DETAILS";
export const REMOVE_USER = "REMOVE_USER";

export const GET_PAGE_DATA = "GET_PAGE_DATA";
export const REMOVE_PAGE = "REMOVE_PAGE";
export const GET_OPERATOR_DATA = "GET_OPERATOR_DATA";

export const GET_GROUP_DATA = "GET_GROUP_DATA";
export const ADD_GROUP = "ADD_GROUP";
export const EDIT_GROUP = "EDIT_GROUP";
export const REMOVE_GROUP = "REMOVE_GROUP";
export const GET_SSO_DATA = "GET_SSO_DATA";
export const DELETE_SSO_DATA = "DELETE_SSO_DATA";

export const ADD_ACTIVITY_DATA = "ADD_ACTIVITY_DATA";
export const GET_ACTIVITY_DATA = "GET_ACTIVITY_DATA";
export const DELETE_ACTIVITY_DATA = "DELETE_ACTIVITY_DATA";

export const GET_PAGE_CREDENTIAL_OPTIONS = "GET_PAGE_CREDENTIAL_OPTIONS";

export const GET_EMAILER_DATA = "GET_EMAILER_DATA";
export const REMOVE_EMAILER = "REMOVE_EMAILER";

export const GET_WORKSPACE_DATA = "GET_WORKSPACE_DATA";
export const ADD_WORKSPACE = "ADD_WORKSPACE";
export const EDIT_WORKSPACE = "EDIT_WORKSPACE";
export const GET_WORKSPACE_DETAILS = "GET_WORKSPACE_DETAILS";
export const CLEAR_WORKSPACE_DETAILS = "CLEAR_WORKSPACE_DETAILS";
export const REMOVE_WORKSPACE = "REMOVE_WORKSPACE";
