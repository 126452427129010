import { FunnelIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { useEffect, useRef, useState } from "react";
import { classNames } from "src/helpers/classNames";
import useOnClickOutside from "src/hooks/useOnClickOutside";

// EXAMPLE OPTION:
// const option = {
//   key: 1,
//   value: "text",
//   selected: false,
//   object: {
//     _id: 'ObjectId("HDJNC14HDB792")',
//     name: "John Snow",
//     age: 26
//   },
// };

const MultiSelectObject = ({ defaultOptions = [], useSelectAll = true, searchableFields = [], placeholder = "Search", onChange = () => {}, createP = true, customSelector = null }) => {
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [panelOpen, setPanelOpen] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);

  const ref = useRef();

  useOnClickOutside(ref, () => setPanelOpen(false));

  useEffect(() => {
    if (!selectedOptions.length) {
      setSelectedOptions(defaultOptions);
      setFilteredOptions(defaultOptions);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOptions]);

  const setAllOptionsClickValues = (value) => {
    let updatedOptions = selectedOptions.map((option) => {
      option.selected = value;
      return option;
    });
    setSelectedOptions(updatedOptions);
    onChange(updatedOptions);
  };

  const handleSelectAll = () => {
    let isAllSelected = selectedOptions.filter((option) => {
      return option.selected === true;
    }).length;

    if (selectedOptions.length === isAllSelected) {
      setAllOptionsClickValues(false);
    } else {
      setAllOptionsClickValues(true);
    }
  };

  const optionClicked = (key) => {
    let updatedOptions = selectedOptions.map((option) => {
      if (option.key === key) {
        if (option.selected) option.selected = false;
        else option.selected = true;
      }
      return option;
    });
    setSelectedOptions(updatedOptions);
    onChange(updatedOptions);
  };

  useEffect(() => {
    if (searchableFields.length > 0) {
      let updatedFilteredOptions = selectedOptions.filter((option) => {
        let keywordMatched = false;
        searchableFields.forEach((searchableField) => {
          if (option?.object[searchableField]?.toLowerCase().includes(keyword.toLowerCase())) {
            keywordMatched = true;
          }
        });
        return keywordMatched;
      });
      setFilteredOptions(updatedFilteredOptions);
    }
  }, [keyword, selectedOptions, searchableFields]);

  return (
    <div
      className="relative"
      ref={ref}>
      {createP && (
        <>
          {filteredOptions.reduce((count, option) => (option.selected ? count + 1 : count), 0) > 0 && (
            <div className="absolute z-10 -top-[10px] left-[15px] pr-6 h-5 px-1 flex gap-x-1 items-center text-xs font-light rounded-full bg-highlightColor text-white">
              <div className="flex gap-x-1 pl-1">
                <div>{filteredOptions.reduce((count, option) => (option.selected ? count + 1 : count), 0)}</div>
                <div>Selected</div>
              </div>
              <XMarkIcon
                onClick={() => setAllOptionsClickValues(false)}
                className="absolute cursor-pointer top-0 right-0 p-[2px] rounded-full h-5 w-5 hover:bg-white/20 transition-all duration-100"
              />
            </div>
          )}
          <div onClick={() => setPanelOpen(true)}>
            {customSelector ? (
              customSelector
            ) : (
              <div className={classNames("relative cursor-pointer text-slate-400 rounded hover:bg-gray-200/30 transition-all duration-100", panelOpen ? "bg-gray-200/30" : "bg-gray-200/0")}>
                <FunnelIcon className="relative z-20 px-[2px] py-.5 h-6 w-6" />
              </div>
            )}
          </div>
          <div className="absolute z-50 top-5 left-2 mt-3 w-64 flex justify-between rounded-md">
            <div className={classNames("bg-white max-h-64 overflow-auto rounded-md min-w-full shadow-lg z-30 absolute", panelOpen ? "flex flex-col" : "hidden")}>
              {searchableFields.length > 0 && (
                <div className="sticky z-20 p-1.5 top-0 left-0 bg-white mb-2 w-full flex">
                  <div
                    className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                    aria-hidden="true">
                    <MagnifyingGlassIcon
                      className="mr-3 h-4 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    type="text"
                    autoComplete="off"
                    name="search"
                    id="search"
                    value={keyword}
                    className="block w-full pl-9 border-gray-300 text-sm rounded-md focus:ring-0 focus:border-highlightColor"
                    placeholder={placeholder}
                    onChange={(e) => setKeyword(e.target.value)}
                  />
                </div>
              )}
              {useSelectAll && !keyword && (
                <div
                  key="SELECT_ALL"
                  className="group bg-gray-50 cursor-pointer w-full text-slate-400 font-normal flex items-center px-4 py-2 gap-x-3 transition-all duration-100 border-white hover:bg-gray-100 hover:text-slate-600"
                  onClick={handleSelectAll}>
                  <input
                    type="checkbox"
                    checked={
                      selectedOptions.filter((option) => {
                        return option.selected === true;
                      }).length === selectedOptions.length
                    }
                    className="cursor-pointer border-slate-400 rounded text-highlightColor bg-slate-50 group-hover:border-slate-500 focus:ring-highlightColor"
                    readOnly
                  />
                  Select All
                </div>
              )}
              {filteredOptions.map((option) => {
                return (
                  <div
                    key={option.key}
                    className="cursor-pointer w-full flex items-center font-normal px-4 py-2 gap-x-3 text-slate-800 transition-all duration-100 border-white hover:bg-gray-100 hover:text-slate-900"
                    onClick={() => optionClicked(option.key)}>
                    <input
                      type="checkbox"
                      checked={option.selected}
                      className="cursor-pointer rounded text-highlightColor focus:ring-highlightColor"
                      readOnly
                    />
                    {option.value}
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MultiSelectObject;
