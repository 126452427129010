import React from "react";

const TopNavigationButton = ({ item = null }) => {
  return (
    <button className="h-7 w-7 shadow-sm -ml-px inline-flex justify-center items-center rounded-md bg-white text-gray-400 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10">
      <span className="sr-only">{item.text}</span>
      <item.icon
        className="h-5 w-5 stroke-2"
        aria-hidden="true"
      />
    </button>
  );
};

export default TopNavigationButton;
