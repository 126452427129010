import WorkspaceItem from "src/components/Shared/MultiColumnLayout/TabContent/WorkspaceItem";

const WorkspaceContent = ({ workspaces = [] }) => {
  return (
    <div className="px-4 pb-4 relative space-y-4 overflow-auto scroll-smooth scrollbar">
      {workspaces.length > 0 && workspaces.map((workspace, index) => <WorkspaceItem key={index} workspace={workspace} />)}
    </div>
  );
};

export default WorkspaceContent;
