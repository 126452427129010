import React from "react";

export default function RouteNotFound404() {
  return (
    <div className="h-full w-full flex justify-center items-center bg-black">
      <div className="text-white flex items-center gap-x-4">
        <span className="text-3xl font-medium">404</span>
        <div className="h-14 w-[1px] bg-gray-500"></div> This page could not be found.
      </div>
    </div>
  );
}
