import moment from "moment";
import QuickMenu from "src/components/Shared/QuickMenu";
import TableRow from "src/components/Shared/Table/TableRow";

function ActivityEntry({ activity, setDeleteId }) {
  const formatMemberDate = (date, length = "long") => {
    if (!date) return "";
    if (length === "long") {
      return [moment(new Date(date)).format("LLLL")];
    } else {
      return [moment(new Date(date)).format("MM/DD/YYYY"), moment(new Date(date)).format("h:mm:ssA")];
    }
  };

  let activityTypes = {
    LOGIN: "Login",
    PAGE_VIEW: "Page View",
  };

  return (
    <TableRow keyId={activity?._id}>
      <td className="w-1/3 whitespace-pre-wrap break-all py-0.5 px-3 pl-4 sm:pl-6 text-xs text-gray-500 sm:table-cell md:w-1/5 sm:py-3 sm:text-sm">
        {activity?.user_id ? (
          <>
            <div className="text-gray-900">{activity?.user_id?.name ? activity?.user_id?.name : "N/A"}</div>
            <div className="text-gray-400">{activity?.user_id?.email ? activity?.user_id?.email : "No email listed"}</div>
          </>
        ) : (
          <div className="text-gray-400">*User Deleted*</div>
        )}
      </td>
      <td className="w-1/5 hidden whitespace-pre-wrap break-all py-0.5 px-3 text-xs text-gray-500 sm:py-3 md:text-sm lg:table-cell">
        <div className="text-gray-900">{activityTypes[activity?.type]}</div>
      </td>
      <td className="w-1/3 hidden whitespace-pre-wrap break-word py-0.5 px-3 text-xs text-gray-500 lg:table-cell md:w-1/5 sm:py-3 sm:text-sm">
        {activity?.data?.page_id?.name ? (
          <div className="text-gray-900 flex flex-col">
            {activity?.data?.page_id?.name}
            <span className="text-gray-400">{activity?.data?.workspace_id?.name}</span>
          </div>
        ) : (
          <span className="text-gray-200">Authentication</span>
        )}
      </td>
      <td className="w-1/4 whitespace-pre-wrap break-all py-0.5 px-3 text-xs text-gray-500 sm:table-cell lg:w-1/5 sm:py-3 sm:text-sm">
        <div className="text-gray-900 2xl:block hidden">{formatMemberDate(activity?.created_at)[0]}</div>
        <div className="text-gray-900 flex flex-col 2xl:hidden">
          {formatMemberDate(activity?.created_at, "short")[0]} <span className="text-gray-400">{formatMemberDate(activity?.created_at, "short")[1]}</span>
        </div>
      </td>
      <td className="w-1/6 table-cell whitespace-nowrap py-3 pl-3 pr-4 text-xs font-medium sm:text-sm">
        <div className="flex justify-end">
          <QuickMenu
            items={[
              {
                name: "Delete",
                onClick: () => {
                  setDeleteId(activity._id);
                },
                color: "text-red-500",
              },
            ]}
          />
        </div>
      </td>
    </TableRow>
  );
}

export default ActivityEntry;
