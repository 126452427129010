import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getUserDetails, updateUser } from "src/actions/user";
import Button from "src/components/Shared/Buttons/Button";
import EditUserForm from "src/components/Users/Edit/ViewProfile/EditUserForm";
import { objectToFormData } from "src/helpers";
import { useLoadData } from "src/hooks/useLoadData";

const UserProfileDetailsEdit = ({ workspaces = [], groups = [], setWorkspaces = () => { }, setGroups = () => { }, me, ...props }) => {
  const [user, setUser] = useState({});
  const [userLoaded, setUserLoaded] = useState(false);
  const [successButtonStart, setSuccessButtonStart] = useState(false)

  const [isAdmin, setIsAdmin] = useState();
  const { id } = useParams();

  useLoadData("post", "/users/details", { body: { id } }, (res) => setUser(res.data ? res.data : {}), userLoaded, setUserLoaded);

  useEffect(() => {
    if (user?.type === "admin") {
      setIsAdmin(true);
    }
  }, [user?.type]);

  const onSubmit = async () => {
    setSuccessButtonStart(true)

    const updateData = {
      ...user,
      type: !isAdmin ? (user.type === "editor" ? "editor" : "user") : "admin",
      workspaceIds: workspaces.filter((item) => item.selected).map((item) => item._id),
    };

    const convertedPayload = objectToFormData(updateData);

    try {
      const message = await props.updateUser(convertedPayload, true);
      // const message = await props.updateUser(convertedPayload);
      setSuccessButtonStart(false)
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
      setSuccessButtonStart(false)
    }
  };

  const handleSetUser = (value, key) => {
    // const passRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W_])[^\s]{8,}$/;
    setUser({ ...user, [key]: value });
  };

  return (
    <>
      <EditUserForm
        isAdmin={isAdmin}
        setIsAdmin={setIsAdmin}
        name={user.name}
        active_status={user.active_status}
        password={user.password}
        confirm_password={user.confirm_password}
        email={user.email}
        username={user.username}
        image={user.image}
        notifications={user.notifications}
        user={user}
        setUser={handleSetUser}
        workspaces={workspaces}
        groups={groups}
        setWorkspaces={setWorkspaces}
        setGroups={setGroups}
      />
      {
        (user?.type === 'admin' && me?.type !== 'admin') ? <></> :
          <div className="mt-5 flex w-full justify-end gap-x-3 pt-3">
            <Button
              version="gray"
              onClick={() => setUserLoaded(false)}>
              Clear all
            </Button>
            <Button
              type="button"
              onClick={onSubmit}
              disabled={successButtonStart}
              loading={successButtonStart}
            >
              Save
            </Button>
          </div>
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    me: state.auth.user,
  };
};

export default connect(mapStateToProps, { getUserDetails, updateUser })(UserProfileDetailsEdit);
