import { useState, useEffect, useRef } from "react";

function useDebounce(callback, delay, options = { resetTimer: 2000 }) {
  const [status, setStatus] = useState("IDLE");
  const timerRef = useRef(null);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    let timeoutId;
    if (status === "DONE") {
      timeoutId = setTimeout(() => {
        setStatus("IDLE");
      }, options.resetTimer);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [status, options.resetTimer]);

  function debouncedCallback(...args) {
    setStatus("WAITING");
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      callback(...args);
      setStatus("DONE");
    }, delay);
  }

  return [debouncedCallback, status];
}

export default useDebounce;
