import React from "react";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";

const EditorPermissionItem = ({ data }) => {
  return (
    <>
      <div className="flex items-center py-4">
        <div className="w-full flex items-center gap-3 px-8 text-gray-600 font-semibold">
          <data.icon className="w-6 h-6 stroke-2" />
          {data.name}
        </div>
        <div className="w-24 min-w-[96px]">
          <Toggle checked={data.create} />
        </div>
        <div className="w-24 min-w-[96px]">
          <Toggle checked={data.update} />
        </div>
        <div className="w-24 min-w-[96px]">
          <Toggle checked={data.delete} />
        </div>
      </div>
    </>
  );
};

export default EditorPermissionItem;
