import { useParams } from "react-router";
import { Link } from "react-router-dom";
import Accordion from "src/components/Shared/Accordions/Accordion";
import WorkspaceIntegrationClients from "src/components/Workspaces/Workspace/Settings/WorkspaceIntegration/WorkspaceIntegrationClients";
import WorkspaceIntegrationFilters from "src/components/Workspaces/Workspace/Settings/WorkspaceIntegration/WorkspaceIntegrationFilters";
import { baseUrl, noFavicon } from "src/config/host";

const WorkspaceIntegrationCredentials = ({ startOpen = false, viewOnly = true, workspace = {}, integration = {} }) => {
  const { id } = useParams();
  return (
    <Accordion
      startOpen={startOpen}
      headerContent={() => (
        <div className="w-full flex gap-x-3 items-center relative">
        <Link
          to={`/workspaces/${workspace._id}`}
          target="_blank"
          className="hover:shadow border border-transparent hover:border-gray-100 p-2 pr-5 rounded-md text-gray-900 transition-all duration-200 font-medium cursor-pointer group">
          <div className="flex gap-4 items-center">
            <div
              style={{ backgroundColor: workspace.top_bar_color }}
              className="w-12 h-12 overflow-hidden rounded">
              <img
                src={workspace.image_favicon?.includes(".s3.") ? workspace.image_favicon + "?" + Date.now() : workspace.image_favicon?.includes("data:image/") ? workspace.image_favicon : baseUrl + workspace.image_favicon}
                alt="Logo"
                className="w-full h-full object-contain"
                onError={(e) => (e.target.src = `${noFavicon}`)}
              />
            </div>
            <p className="w-[100px] sm:w-auto truncate sm:overflow-visible sm:max-w-auto h-full flex items-center gap-x-4">{workspace?.name}</p>
          </div>
        </Link>
        {workspace?.workspace_type === "JWT_FULL_EMBED" && (
          <div className="absolute -top-2 left-10 flex items-center gap-x-3 rounded-md border border-gray-200 bg-white p-[2px] text-gray-500 text-xs font-medium">
            <div className="w-6 h-6 overflow-hidden">
              <img
                className="w-full h-full object-cover"
                src="https://www.domo.com/assets/images/logo@2x.png"
                alt=""
              />
            </div>
          </div>
        )}
      </div>
      )}>
      <div className="relative p-4 space-y-6">
        <WorkspaceIntegrationClients
          integration={{ ...integration, name: workspace.name }}
          workspaceId={workspace._id}
          viewOnly={viewOnly}
          workspaceDetails={workspace}
        />
        {id && workspace?.workspace_type === "IFRAME_EMBED" && (
          <WorkspaceIntegrationFilters
            integration={integration}
            workspaceId={workspace.id}
          />
        )}
      </div>
    </Accordion>
  );
};

export default WorkspaceIntegrationCredentials;
