import { classNames } from "src/helpers/classNames";

export default function IconsWithPlus({ item, size = "h-6", className = "", strokeColor = "stroke-gray-600" }) {
  return (
    <div className="relative ml-[-2px]">
      <item.icon className={`${size}`} />
      <svg
        className={classNames("absolute top-0 -right-2", strokeColor, className)}
        width="24"
        height="24"
        viewBox="0 0 28 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M22.5 2.75V8.25M25.25 5.5L19.75 5.5"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
