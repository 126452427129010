import { apiRequest } from "src/async/apiUtils";
import { fetchData } from "src/async/fetch";
import { apiUrl } from "src/config/host";
import { GET_WORKSPACE_DATA, REMOVE_WORKSPACE, GET_WORKSPACE_DETAILS } from "src/actions/type";
import { objectToFormData } from "src/helpers";

export const getWorkspaceData =
  (payload, signal = null) =>
    async (dispatch) => {
      try {
        const { status, data } = await apiRequest("post", `/workspaces/list?include=["groups","users","pages"]`, { body: payload });
        if (status === 200) {
          dispatch({ type: GET_WORKSPACE_DATA, payload: data.data });
          return data;
        } else {
          throw new Error(data.meta.message);
        }
      } catch (error) {
        throw new Error(error.data.meta.message);
      }
    };

export const editWorkspaceGroupDetails =
  (payload, signal = null) =>
    async (dispatch) => {
      try {
        let workspace_id = payload.workspaceId;
        const res = await fetchData("PUT", `${apiUrl}/workspaces/${workspace_id}/groups`, payload, signal);
        const data = await res.json();

        if (data.status === 200) {
          return data.message;
        } else {
          throw new Error(data.message);
        }
      } catch (error) {
        throw new Error(error.message);
      }
    };

export const manageGroupUser =
  (payload, signal = null) =>
    async (dispatch) => {
      try {
        const res = await fetchData("POST", `${apiUrl}/workspaces/groups/users/manage`, payload, signal);
        const data = await res.json();

        if (data.status === 200) {
          return data.message;
        } else {
          throw new Error(data.message);
        }
      } catch (error) {
        throw new Error(error.message);
      }
    };

export const userPermissionList =
  (payload, signal = null) =>
    async (dispatch) => {
      try {
        const res = await fetchData("GET", `${apiUrl}/workspaces/${payload.workspace_id}/users/permissions/list`, payload, signal);
        const data = await res.json();

        if (data.status === 200) {
          return data.data;
        } else {
          throw new Error(data.message);
        }
      } catch (error) {
        throw new Error(error.message);
      }
    };
export const userWorkspacesAndAssociatedPermissions =
  (payload, signal = null) =>
    async (dispatch) => {
      try {
        const res = await fetchData("GET", `${apiUrl}/users/${payload.user_id}/workspaces/permissions`, payload, signal);
        const data = await res.json();

        if (data.status === 200) {
          return data.data;
        } else {
          throw new Error(data.message);
        }
      } catch (error) {
        throw new Error(error.message);
      }
    };

export const userListOfWorkspacesAndPermissions =
  (payload, signal = null) =>
    async (dispatch) => {
      try {
        const res = await fetchData("POST", `${apiUrl}/workspaces/${payload.workspace_id}/permission/list`, payload, signal);
        const data = await res.json();

        if (data.status === 200) {
          return data.data;
        } else {
          throw new Error(data.message);
        }
      } catch (error) {
        throw new Error(error.message);
      }
    };

export const usersPermissionManage =
  (payload, signal = null) =>
    async (dispatch) => {
      try {
        const res = await fetchData("PUT", `${apiUrl}/workspaces/${payload.workspace_id}/users/permissions/manage`, payload, signal);
        const data = await res.json();

        if (data.status === 200) {
          return data.message;
        } else {
          throw new Error(data.message);
        }
      } catch (error) {
        throw new Error(error.message);
      }
    };

export const userPermissionCheckboxManage =
  (payload, signal = null) =>
    async (dispatch) => {
      try {
        const res = await fetchData("POST", `${apiUrl}/workspaces/${payload.workspace_id}/users/permissions/checkbox/manage`, payload, signal);
        const data = await res.json();

        if (data.status === 200) {
          return data.message;
        } else {
          throw new Error(data.message);
        }
      } catch (error) {
        throw new Error(error.message);
      }
    };

export const manageWorkspaceSettings = (payload) => async (dispatch) => {
  try {
    const updatedPayload = objectToFormData(payload);
    const res = await fetchData("PUT", `${apiUrl}/workspaces/${payload.workspace_id}/settings`, updatedPayload, null, {}, "multipart/form-data");
    const { data } = res;

    if (data.status === 200) {
      dispatch({ type: GET_WORKSPACE_DETAILS, payload: data.data });
      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getWorkspaceDetails = (payload) => async (dispatch) => {
  try {
    const res = await fetchData("GET", `${apiUrl}/workspaces/${payload.workspace_id}`, payload);
    const data = await res.json();

    if (data.status === 200) {
      dispatch({ type: GET_WORKSPACE_DETAILS, payload: data.data });
      return data.data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getUserWorkspaceData =
  (payload, signal = null) =>
    async (dispatch) => {
      try {
        const res = await fetchData("GET", `${apiUrl}/users/${payload.user_id}/workspaces`, signal);
        const data = await res.json();
        if (data.status === 200) {
          return data.data;
        } else {
          throw new Error(data.message);
        }
      } catch (error) {
        throw new Error(error.message);
      }
    };

export const workspaceIntegrationList =
  (payload, signal = null) =>
    async (dispatch) => {
      try {
        const res = await fetchData("GET", `${apiUrl}/workspaces/${payload.workspace_id}/integrations`, signal);
        const data = await res.json();

        if (data.status === 200) {
          return data.data;
        } else {
          throw new Error(data.message);
        }
      } catch (error) {
        throw new Error(error.message);
      }
    };

export const workspaceIntegrationFilterCreate =
  (payload, signal = null) =>
    async (dispatch) => {
      try {
        const res = await fetchData("POST", `${apiUrl}/workspaces/${payload.workspace_id}/integrations/${payload.integration_id}/filter`, payload, signal);
        const data = await res.json();

        if (data.status === 200) {
          return data.data;
        } else {
          throw new Error(data.message);
        }
      } catch (error) {
        throw new Error(error.message);
      }
    };

export const workspaceIntegrationFilterManage =
  (payload, signal = null) =>
    async (dispatch) => {
      try {
        const res = await fetchData("PUT", `${apiUrl}/workspaces/${payload.workspace_id}/integrations/${payload.integration_id}/filter`, payload, signal);
        const data = await res.json();

        if (data.status === 200) {
          return data.data;
        } else {
          throw new Error(data.message);
        }
      } catch (error) {
        throw new Error(error.message);
      }
    };

export const workspaceIntegrationFilterDelete =
  (payload, signal = null) =>
    async (dispatch) => {
      try {
        const res = await fetchData("DELETE", `${apiUrl}/workspaces/${payload.workspace_id}/integrations/${payload.integration_id}/filter/${payload.id}`, signal);
        const data = await res.json();

        if (data.status === 200) {
          return data.data;
        } else {
          throw new Error(data.message);
        }
      } catch (error) {
        throw new Error(error.message);
      }
    };

export const workspaceIntegrationCredentialCreate =
  (payload, signal = null) =>
    async (dispatch) => {
      try {
        const res = await fetchData("POST", `${apiUrl}/workspaces/${payload.workspace_id}/integrations/${payload.integration_id}/credentials`, payload, signal);
        const data = await res.json();

        if (data.status === 200) {
          return data.data;
        } else {
          throw new Error(data.message);
        }
      } catch (error) {
        throw new Error(error.message);
      }
    };

export const workspaceIntegrationCredentialManage =
  (payload, signal = null) =>
    async (dispatch) => {
      try {
        const res = await fetchData("PUT", `${apiUrl}/workspaces/${payload.workspace_id}/integrations/${payload.integration_id}/credentials/manage`, payload, signal);
        const data = await res.json();

        if (data.status === 200) {
          return data.data;
        } else {
          throw new Error(data.message);
        }
      } catch (error) {
        throw new Error(error.message);
      }
    };

export const deleteWorkspace = (id) => async (dispatch) => {
  try {
    const res = await fetchData("DELETE", `${apiUrl}/workspaces/${id}`);
    const data = await res.json();

    if (data.status === 200) {
      dispatch({ type: REMOVE_WORKSPACE, payload: id });
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};
