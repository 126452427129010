import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { Form, Formik } from "formik";
import { useState } from "react";
import { apiRequest } from "src/async/apiUtils";
import Button from "src/components/Shared/Buttons/Button";
import Input from "src/components/Shared/Forms/FormikForms/Input";
import { randomHashString } from "src/helpers/randomHashString";
import useFetch from "src/hooks/useFetch";
import * as Yup from "yup";
import Section from "src/components/Shared/Containers/Section";
import { H3 } from "src/components/Shared/Text/Headers";

const SiteEmailManager = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const {
    response: { data: smtpServer = {} },
    refreshData: refreshSite,
  } = useFetch("/site-settings/smtp-server");

  const onSubmit = async (values) => {
    setIsLoading(true);
    setIsDisabled(true);

    let body = {
      site_email: values.siteEmail,
      smtp_email: values.smtpEmail,
      smtp_host: values.smtpHost,
      smtp_user: values.smtpUser,
      smtp_port: values.smtpPort,
      smtp_secure: values.smtpSecure,
    };
    if (values.smtpPassword) {
      body = { ...body, smtp_password: values.smtpPassword };
    }
    try {
      await apiRequest("PUT", "/site-settings", { body }, { showToastMessage: true });
      refreshSite();
    } catch (error) { } finally {
      setIsDisabled(true);
      setIsLoading(false);
    }

  };

  const onClear = () => { 
    setIsDisabled(true);
  };

  return (
    <>
      <Section>
        <div className="grid gap-y-8">
          <H3 caption="Adjust settings to allow for your clients to recieve emails from your domain.">SMTP Server Settings</H3>
          <Formik
            enableReinitialize={true}
            initialValues={{
              siteEmail: smtpServer?.site_email || "",
              smtpEmail: smtpServer?.smtp_email || "",
              smtpHost: smtpServer?.smtp_host || "",
              smtpUser: smtpServer?.smtp_user || "",
              smtpPassword: "",
              smtpPort: smtpServer?.smtp_port || "",
              smtpSecure: smtpServer?.smtp_secure || true,
            }}
            validationSchema={Yup.object({
              siteEmail: Yup.string().required("Required"),
              smtpEmail: Yup.string().required("Required"),
              smtpHost: Yup.string().required("Required"),
              smtpUser: Yup.string().required("Required"),
              smtpPort: Yup.number().required("Required"),
              smtpSecure: Yup.boolean().required("Required"),
            })}
            onSubmit={async (values) => {
              onSubmit(values);
            }}>
            {({ values, errors, touched }) => {
              return (
                <Form onChange={() => setIsDisabled(false) }>
                  <div className="grid space-y-8">
                    <div className="flex flex-wrap justify-between w-full">
                      <div className="flex flex-wrap w-full gap-y-6">
                        {/* Contact email */}
                        <div className="w-full flex flex-col sm:flex-row gap-3">
                          <div className="sm:w-1/2 w-full">
                            <Input
                              name="smtpEmail"
                              label="SMTP email"
                            />
                          </div>

                          <div className="sm:w-1/2 w-full">
                            <Input
                              name="siteEmail"
                              label={
                                <p>
                                  Support contact <span className="pl-2 text-gray-300">*optional email</span>
                                </p>
                              }
                            />
                          </div>
                        </div>
                        <div className="w-full flex flex-col sm:flex-row gap-3">
                          {/* SMTP user */}
                          <div className="sm:w-1/2 w-full">
                            <Input
                              name="smtpUser"
                              label="User"
                            />
                          </div>
                          {/* SMTP password */}
                          <div className="sm:w-1/2 w-full">
                            <Input
                              placeholder={randomHashString(smtpServer.smtp_password)}
                              name="smtpPassword"
                              label="Password"
                              type="password"
                              autoComplete="new-password"
                            />
                          </div>
                        </div>
                        <div className="w-full flex flex-col sm:flex-row gap-3">
                          {/* SMTP host */}
                          <div className="sm:w-1/2 w-full">
                            <Input
                              name="smtpHost"
                              label="Host"
                            />
                          </div>
                          {/* SMTP port */}
                          <div className="relative sm:w-1/2 w-full">
                            {/* SMTP secure */}
                            <div className="absolute z-30 flex items-center gap-x-2 ml-2 top-0 right-4 text-gray-700 opacity-70 text-xs cursor-pointer">
                              <input
                                className="rounded text-gray-300 focus:ring-highlightColor cursor-pointer"
                                type="checkbox"
                                checked={values.smtpSecure}
                                name="smtpSecure"
                              />
                              <label className="cursor-pointer">SMTP Secure</label>
                            </div>
                            <div className="w-full">
                              <Input
                                name="smtpPort"
                                label="SMTP Port"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="w-full flex justify-end gap-x-4">
                      <Button
                        version="gray"
                        disabled={isDisabled}
                        onClick={onClear}>
                        Undo changes
                      </Button>
                      <Button disabled={isDisabled} type="submit">Update{isLoading ? <ArrowPathIcon className="h-5 w-5 ml-1 animate-spin" /> : <></>}</Button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Section>
    </>
  );
};

export default SiteEmailManager;
