import axios from "axios";

export const fetchData = async (method, host, body, signal = null, customHeaders = {}, contentType = "application/json") => {
  let finalBody = body;

  const token = localStorage.getItem("access_token") || body?.token || customHeaders?.Authorization || null;

  if (contentType === "application/json") {
    if (!Array.isArray(body)) {
      finalBody = { ...body, token };
    }
    let signalParam = signal ? { signal } : {};
    return await fetch(host, {
      method,
      headers: new Headers({
        "Content-Type": contentType,
        Accept: "application/json",
        Authorization: finalBody.token,
        ...customHeaders,
      }),
      body: method !== "GET" && body ? contentType.includes("form-data") ? body : JSON.stringify(finalBody) : null,
      ...signalParam,
    });
  } else {
    const request = await axios.request({
      url: host,
      method,
      data: finalBody,
      headers: { ...customHeaders, "Content-Type": contentType, Authorization: token, Accept: "application/json" },
    });
    return request;
  }
};
