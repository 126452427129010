import React from 'react';
import { UsersIcon, Square3Stack3DIcon, WindowIcon, LockClosedIcon, RectangleGroupIcon, ServerStackIcon } from "@heroicons/react/24/outline";
import EditorPermissionItem from "src/components/Shared/MultiColumnLayout/TabContent/EditorPermissionItem";

const EditorPermission = () => {

  const data = [
    {_id:1, name:"Users", icon: UsersIcon, create: true, update: true, delete: true },
    {_id:2, name:"Groups", icon: Square3Stack3DIcon, create: true, update: true, delete: true },
    {_id:3, name:"Pages", icon: WindowIcon, create: true, update: true, delete: true },
    {_id:4, name:"Permissions", icon: LockClosedIcon, create: true, update: true, delete: true },
    {_id:5, name:"Layout & Styles", icon: RectangleGroupIcon, create: true, update: true, delete: true },
    {_id:6, name:"Integration", icon: ServerStackIcon, create: true, update: true, delete: true },
  ]

  return (
    <>
      <div className="flex items-center py-4">
        <div className="w-full"></div>
        <div className="w-24 min-w-[96px] text-[14px] font-bold text-gray-500">Create</div>
        <div className="w-24 min-w-[96px] text-[14px] font-bold text-gray-500">Update</div>
        <div className="w-24 min-w-[96px] text-[14px] font-bold text-gray-500">Delete</div>
      </div>
      <div className="divide-y divide-gray-200">
      {data.map((item) => (
        <EditorPermissionItem key={item._id} data={item}/>
      ))}
      </div>
    </>
  );
};

export default EditorPermission;