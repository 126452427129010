import { Fragment } from "react"
import { baseUrl, noFavicon } from "src/config/host"

const PageListItem = ({
  pages = [], 
  page = {},
  workspaceId = '',
  i = 0,
  ...props
}) => {
  return (
    <Fragment>
      {!workspaceId && page?.workspace_id?._id !== pages[i - 1]?.workspace_id?._id && (
        <tr>
          <td
            colSpan={3}
            className="break-all py-4 px-4 sm:pl-6 sm:py-3 text-sm font-semibold text-gray-500 whitespace-pre-wrap bg-gray-100">
            <div className="flex gap-4 items-center">
              <div
                style={{ backgroundColor: page?.workspace_id?.top_bar_color }}
                className="w-10 h-10 overflow-hidden rounded">
                <img
                  src={page?.workspace_id.image_favicon?.includes(".s3.") ? page?.workspace_id?.image_favicon + "?" + Date.now() : page?.workspace_id?.image_favicon?.includes("data:image/") ? page?.workspace_id?.image_favicon : baseUrl + page?.workspace_id?.image_favicon}
                  alt="Logo"
                  className="w-full h-full object-contain"
                  onError={(e) => (e.target.src = `${noFavicon}`)}
                />
              </div>
              {page?.workspace_id?.name}
            </div>
          </td>
        </tr>
      )}
      { props.children }
    </Fragment>
  )
}

export default PageListItem