import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { classNames } from "src/helpers/classNames";

const Tabs = ({ onClick = () => {}, tabs = [], newDesign = false, changeParams = true }) => {
  const [selectedTab, setSelectedTab] = useState({});

  const location = useLocation();
  const navigate = useNavigate();

  function useQuery() {
    return new URLSearchParams(window.location.search);
  }
  const tabParam = useQuery().get("tab");

  const handleTabSelection = (tab = {}) => {
    onClick();
    if(changeParams){
      const params = new URLSearchParams({ tab: tab?.name });
      navigate({ pathname: location.pathname, search: params.toString() });
    }
    setSelectedTab(tab);
  };

  useEffect(() => {
    if (tabs.length) {
      if (!tabParam) {
        let defaultTab = tabs.filter((tab) => {
          return !tab.hidden;
        })[0];

        setSelectedTab(defaultTab);
      } else {
        let filteredTab = tabs.filter((tab) => tabParam === tab.name)[0];
        if (filteredTab) setSelectedTab(filteredTab);
        else {
          setSelectedTab(tabs[0]);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs, window.location.search]);

  return (
    <>
      <div className={classNames("overflow-x-auto overflow-y-hidden hide-scrollbar", tabs.length ? "border-b border-gray-200" : "", newDesign ? "pl-4 lg:pl-8" : "")}>
        <nav
          className="-mb-px flex space-x-8 h-10 overflow-y-hidden hide-scrollbar"
          aria-label="Tabs">
          {tabs.length > 0 &&
            tabs.map((tab) =>
              tab.hidden ? null : (
                <div
                  key={tab.name}
                  onClick={() => handleTabSelection(tab)}
                  className={classNames(
                    tab.name === selectedTab?.name ? "border-highlightColor text-highlightColor" : "border-transparent text-gray-400 hover:border-gray-400 hover:text-gray-500",
                    "group inline-flex cursor-pointer select-none items-center border-b-2 px-1 pb-3 text-sm font-medium"
                  )}
                  aria-current={tab.name === selectedTab ? "page" : undefined}
                >
                  {tab.icon && (
                    <tab.icon
                      className={classNames(tab.name === selectedTab?.name ? "text-highlightColor" : "text-gray-400 group-hover:text-gray-400", "stroke-[2px] -ml-0.5 mr-2 h-5 w-5")}
                      aria-hidden="true"
                    />
                  )}
                  <span className="block whitespace-nowrap">{tab.name}</span>
                </div>
              )
            )}
        </nav>
      </div>
      <div className="mt-3 w-full">{selectedTab?.component}</div>
    </>
  );
};

export default Tabs;
