import React from "react";
import { noLogo } from "src/config/host";

const AuthContainer = ({ children, site }) => {
  return (
    <div className="mt-12 sm:mx-auto sm:w-full sm:max-w-lg">
      <div className="bg-white shadow-md rounded-lg min-h-[183px] overflow-hidden">
        <div className="bg-headerColor p-2">
          <a href="#!">
            {site?.image_logo && (
              <img
                className="mx-auto h-auto max-h-[80px] py-2 w-auto max-w-[80%]"
                src={`${site?.image_logo}?${Date.now()}`}
                alt="Logo"
                onError={(e) => (e.target.src = `${noLogo}`)}
              />
            )}
          </a>
        </div>
        <div className="px-4 py-8 sm:px-10">{children}</div>
      </div>
    </div>
  );
};

export default AuthContainer;
