import { GET_PAGE_CREDENTIAL_OPTIONS } from "src/actions/type";

const siteCredentialReducer = (state = [], action) => {
  switch (action.type) {
    case GET_PAGE_CREDENTIAL_OPTIONS:
      return action.payload;
    default:
      return state;
  }
};

export default siteCredentialReducer;
