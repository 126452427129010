import { TrashIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Select from "src/components/Shared/Forms/Selects/Select";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";

const WorkspaceIntegrationFiltersItem = ({ user, workspaceId, state, setState = () => {}, removeFilter, operators, trustedAttributeArr, valueType }) => {
  const [valueTypeOption, setValueTypeOption] = useState(valueType);
  const [operatorId, setOperatorId] = useState({ _id: -1, name: "" });
  const [valueTypeId, setValueTypeId] = useState({ _id: "value", name: "Text Value" });
  const [trustedAttributeId, setTrustedAttributeId] = useState({ _id: -1, name: "" });

  useEffect(() => {
    const index = valueType.findIndex((item) => item._id === state?.value_type);
    if (index !== -1) {
      setValueTypeId(valueType[index]);
      setValueTypeOption(valueType.filter((v) => v._id !== state?.value_type));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.value_type]);

  useEffect(() => {
    const index = operators.findIndex((item) => item._id === state?.operator_id);
    if (index !== -1) {
      setOperatorId(operators[index]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.operator_id, operators?.length]);

  useEffect(() => {
    const index = trustedAttributeArr.findIndex((item) => item._id === state?.trusted_attribute);
    if (index !== -1) {
      setTrustedAttributeId(trustedAttributeArr[index]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.trusted_attribute]);

  return (
    <div className="w-full h-full relative border border-gray-50 shadow pb-3 pt-1 px-4 rounded-md">
      <div className="w-full h-full flex justify-between items-center">
        <div className="relative w-full items-end flex flex-col sm:flex-row gap-x-3 max-w-4xl">
          {authorizeUserComponentAccess(user, workspaceId, "integration", ["update"]) ? (
            <>
              <div className="grow">
                <Input
                  name="column-name"
                  label="Column name"
                  value={state?.column_name || ""}
                  onChange={(e) => {
                    setState({ ...state, column_name: e.target.value });
                  }}
                />
              </div>
              <div className="w-[110px]">
                <Select
                  label="Operator"
                  options={operators}
                  selected={operatorId}
                  setSelected={(e) => {
                    setState({ ...state, operator_id: e._id });
                  }}
                />
              </div>
              <div className="w-1/2">
                <Select
                  options={valueTypeOption}
                  selected={valueTypeId}
                  selectType={"label"}
                  setSelected={(e) => {
                    setState({ ...state, value_type: e._id, column_value: "", trusted_attribute: "" });
                    setTrustedAttributeId({ _id: -1, name: "" });
                  }}
                />
                {state?.value_type === "attribute" ? (
                  <Select
                    options={trustedAttributeArr}
                    selected={trustedAttributeId}
                    setSelected={(e) => {
                      setState({ ...state, trusted_attribute: e._id });
                    }}
                  />
                ) : (
                  <Input
                    name="column-value"
                    value={state?.column_value || ""}
                    onChange={(e) => {
                      setState({ ...state, column_value: e.target.value });
                    }}
                  />
                )}
              </div>
            </>
          ) : (
            <>
              <div className="grow">
                <Input
                  name="column-name"
                  label="Column name"
                  value={state?.column_name || ""}
                  disabled={true}
                  onChange={(e) => {}}
                />
              </div>
              <div className="w-[110px]">
                <Select
                  label="Operator"
                  options={operators}
                  selected={operatorId}
                  disabled={true}
                  setSelected={(e) => {}}
                />
              </div>
              <div className="w-1/2">
                <Select
                  options={valueTypeOption}
                  selected={valueTypeId}
                  selectType={"label"}
                  setSelected={(e) => {}}
                />
                {state?.value_type === "attribute" ? (
                  <Select
                    options={trustedAttributeArr}
                    selected={trustedAttributeId}
                    setSelected={(e) => {}}
                  />
                ) : (
                  <Input
                    name="column-value"
                    value={state?.column_value || ""}
                    onChange={(e) => {}}
                  />
                )}
              </div>
            </>
          )}
        </div>
        {authorizeUserComponentAccess(user, workspaceId, "integration", ["delete"]) && (
          <button
            type="button"
            className="p-1 mt-2 hover:bg-gray-100/70 transition-all duration-300 rounded-md text-gray-400 hover:text-gray-500 border-transparent focus:outline-none border-[2px] hover:border-gray-200"
            onClick={() => removeFilter(state._id)}>
            <span className="sr-only">Delete</span>
            <TrashIcon className="h-5 w-5" />
          </button>
        )}
      </div>
    </div>
  );
};

export default WorkspaceIntegrationFiltersItem;
