import { ClipboardDocumentListIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { getAuthorizedUser } from "src/actions/auth";
import { getOperators } from "src/actions/operator";
import { getPages } from "src/actions/page";
import { editWorkspaceGroupDetails, manageGroupUser } from "src/actions/workspace";
import { fetchData } from "src/async/fetch";
import Button from "src/components/Shared/Buttons/Button";
import IconsWithPlus from "src/components/Shared/Icons/IconsWithPlus";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import GroupModal from "src/components/Groups/GroupModalEdit";
import WorkspaceGroupItemOldVersion from "src/components/Workspaces/Workspace/Groups/WorkspaceGroupItemOldVersion";
import WorkspaceManageUsersModal from "src/components/Workspaces/Workspace/WorkspaceManageUsersModal";
import { apiUrl } from "src/config/host";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import { classNames } from "src/helpers/classNames";
import NoData from "src/components/Shared/LoadingAnimations/NoData";
import { urlBuilder } from "src/helpers/urlBuilder";
import GroupAssignToUserModal from "src/components/Groups/GroupAssignToUserModal";
/* import { useLoadData } from "src/hooks/useLoadData"; */

const WorkspaceGroupListOldVersion = ({ pageName, getAuthorizedUser, viewOnly = false, userId = "", workspaceId = "", workspaceUsers, ...props }) => {
  const [loaded, setLoaded] = useState(false);
  const [groups, setGroups] = useState([]);

  const [groupModalOpen, setGroupModalOpen] = useState(false);
  const [addGroupStatus, setAddGroupStatus] = useState(false);
  const [editGroupStatus, setEditGroupStatus] = useState(false);
  const [editId, setEditId] = useState(null);
  const [isAddUserModal, setIsAddUserModal] = useState(false);
  const [currentGroupId, setCurrentGroupId] = useState("");
  const [currentGroupUserId, setCurrentGroupUserId] = useState([]);
  const [pageList, setPageList] = useState([]);

  const [groupAssignToUserModalOpen, setGroupAssignToUserModalOpen] = useState(false);

  // useLoadData("get", `/pages/credentials`, {}, (res) => setEditPageCredentialOptions(res.data));

  const loadGroupsData = async (ac = {}) => {
    try {
      let workspaceGroupsData = {};

      try {
        let workspaceGroupsResponse;
        if (pageName === "user") {
          workspaceGroupsResponse = await fetchData("GET", urlBuilder(`${apiUrl}/workspaces/:workspace_id/users/:user_id/groups`, { workspace_id: workspaceId, user_id: userId }));
        } else {
          workspaceGroupsResponse = await fetchData("GET", urlBuilder(`${apiUrl}/workspaces/:workspace_id/groups`, { workspace_id: workspaceId }));
        }
        workspaceGroupsData = await workspaceGroupsResponse.json();

        if (workspaceGroupsData.status === 200) {
          setGroups(workspaceGroupsData.data);
          setLoaded(true);
          return workspaceGroupsData.data;
        } else {
          setLoaded(true);
          throw new Error(workspaceGroupsData.message);
        }
      } catch (error) {
        setLoaded(true);
        throw new Error(error.message);
      }
    } catch (error) {
      setLoaded(true);
      // console.dir(error.message);
    }
  };

  useEffect(() => {
    const ac = new AbortController();
    const loadPageAndOperatorData = async () => {
      props
        .getPages({ workspace_id: workspaceId }, ac.signal)
        .then((data) => {
          data = data.filter((page) => page.page_type !== "LANDING_PAGE");
          setPageList(data);
          props
            .getOperators({}, ac.signal)
            .then((oprtrs) => {
              loadGroupsData(ac);
            })
            .catch((error) => console.dir(error));
        })
        .catch((error) => console.dir(error));
    };
    if (workspaceId) {
      loadPageAndOperatorData();
    }
    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId, props.me]);

  const editWorkspaceGroup = async () => {
    try {
      const data = await props.editWorkspaceGroupDetails({ groups, workspaceId });
      toast.success(data);
    } catch (error) {
      // toast.success(error.message);
    }
  };

  const assignUserToGroup = async (userIds) => {
    try {
      const data = await props.manageGroupUser({
        _id: currentGroupId,
        user_id: userIds,
        workspace_id: props.workspaceDetails?._id
      });
      await loadGroupsData();
      toast.success(data.message);
    } catch (error) {
      toast.error(error.message);
    }
    setIsAddUserModal(false);
    setCurrentGroupId("");
    setCurrentGroupUserId([]);
  };

  const handleSetGroups = async (groups) => {
    setGroups(groups);
  };

  return (
    <>
      {loaded ? (
        <div className="relative grid gap-y-4">
          <div className="flex justify-end gap-2">
            {!viewOnly && authorizeUserComponentAccess(props.me, workspaceId, "group", ["create"]) && (
              <Button
                version="secondary"
                onClick={() => {
                  setGroupModalOpen(true);
                  setAddGroupStatus(true);
                  setEditGroupStatus(false);
                  setEditId(null);
                }}>
                <IconsWithPlus
                  strokeColor={"stroke-highlightColor"}
                  item={{ icon: ClipboardDocumentListIcon }}
                />
              </Button>
            )}
            <Button
                version="secondary"
                onClick={() => {
                  setGroupAssignToUserModalOpen(true);
                }}>
                <IconsWithPlus
                  strokeColor={"stroke-highlightColor"}
                  item={{ icon: ClipboardDocumentListIcon }}
                />
              </Button>
          </div>
          {groups?.length > 0 ? (
            groups.map((group, index) => {
              return (
                <WorkspaceGroupItemOldVersion
                  viewOnly={viewOnly}
                  workspaceId={workspaceId}
                  key={group._id}
                  groups={groups}
                  group={group}
                  users={[]}
                  pages={pageList}
                  setGroups={handleSetGroups}
                  index={index}
                  refresh={loadGroupsData}
                  isAddUserModal={isAddUserModal}
                  setIsAddUserModal={setIsAddUserModal}
                  setCurrentGroupId={setCurrentGroupId}
                  setCurrentGroupUserId={setCurrentGroupUserId}
                />
              );
            })
          ) : (
            <NoData />
          )}

          {groups?.length > 0 && !viewOnly && authorizeUserComponentAccess(props.me, workspaceId, "group", ["update"]) && (
            <div className="flex justify-end">
              <Button
                version="primary"
                onClick={editWorkspaceGroup}>
                Save
              </Button>
            </div>
          )}
        </div>
      ) : (
        <div className={classNames("h-20 transition-all duration-300")}>
          <Preloader
            className="h-[80px]"
            circleDimension="10"
            size="lg"
          />
        </div>
      )}

      <GroupModal
        isOpen={groupModalOpen}
        defaultWorkspaceId={workspaceId}
        setIsOpen={setGroupModalOpen}
        setRefresh={loadGroupsData}
        defaultOptions={{
          onSuccessButtonText: "Save",
        }}
        title="Group"
        secondaryTitle={editGroupStatus ? "Edit" : "Add"}
        addGroupStatus={addGroupStatus}
        setAddGroupStatus={setAddGroupStatus}
        editGroupStatus={editGroupStatus}
        setEditGroupStatus={setEditGroupStatus}
        editId={editId}
        setEditId={setEditId}
      />
      <GroupAssignToUserModal
        isOpen={groupAssignToUserModalOpen}
        defaultWorkspaceId={workspaceId}
        setIsOpen={setGroupAssignToUserModalOpen}
        setRefresh={loadGroupsData}
        title={`${props?.userDetails?.name}/${props?.workspaceDetails?.name}/Group Assign`}
        groups={props?.workspace?.groups || []}
        defaultGroups={groups.map((grp) => grp?._id) || []}
      />
      <WorkspaceManageUsersModal
        title="Users"
        secondaryTitle="Add"
        isOpen={isAddUserModal}
        onCancel={() => setIsAddUserModal(false)}
        defaultSelectedUsers={currentGroupUserId}
        setSelectedUser={setCurrentGroupUserId}
        onSuccess={assignUserToGroup}
        workspaceDetailsData={props.workspaceDetails}
        workspaceUsers={workspaceUsers}
      />

    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    pages: Object.values(state.pages),
    me: state.auth.user,
  };
};
export default connect(mapStateToProps, {
  editWorkspaceGroupDetails,
  getAuthorizedUser,
  getPages,
  getOperators,
  manageGroupUser,
})(WorkspaceGroupListOldVersion);
