import { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { changePasswordFirstTime } from "src/actions/auth";
import AuthContainer from "src/components/Auth/AuthContainer";
import AuthHeader from "src/components/Auth/AuthHeader";
import Button from "src/components/Shared/Buttons/Button";
import Input from "src/components/Shared/Forms/Inputs/Input";

const WelcomePasswordContent = ({ token, changePasswordFirstTime, site, seoTitle }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const resetPassword = async () => {
    try {
      const message = await changePasswordFirstTime({
        password,
        confirm_password: confirmPassword,
        token,
        firstTime: true
      });

      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <AuthHeader
        seoTitle={seoTitle}
        title="Welcome!"
        switchTo={{
          startText: "Enter and confirm your new password",
          location: site?.site_domain || "/",
        }}
      />
      <AuthContainer>
        <div className="grid gap-y-3">
          <Input
            name="password"
            type="password"
            label="Password"
            error={"error" === "'Password' field is required"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Input
            name="confirm-password"
            type="password"
            label="Confirm password"
            error={"error" === "'Confirm password' field is required"}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                resetPassword();
              }
            }}
          />
          <div className="w-full mt-4">
            <Button
              onClick={resetPassword}
              width="w-full">
              Create your account
            </Button>
          </div>
        </div>
      </AuthContainer>
      <p className="mt-4 text-center text-regular font-md text-gray-600">
        Return to
        <Link
          to="/login"
          className="underline pl-1 text-highlightColor">
          login page
        </Link>
      </p>
    </>
  );
};

export default connect(null, { changePasswordFirstTime })(WelcomePasswordContent);
